import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import TabsMui from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import ComponentType from "../componentType";
import FormRegisterInfo from "../context/form-context";
import { useFormContext } from "react-hook-form";
import { toLowerCaseFirstLetter } from "../../helpers"

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        {children}
      </Box>
    </div>
  );
}

const Tabs = props => {
  const {
    name,
    label,
    parentName,
    title,
    children,
    watchFor,
    watchForProp,
    watchForLaterality,
    errorMessage,
    watchForCanBeEmpty,
    customValidation,
    customValidationStepWatch,
    customValidationSource,
    customValidationSourceField,
    ...rest
  } = props;
  const [active, setActive] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [error, setError] = useState(false);
  const { state: globalFormState } = useContext(FormRegisterInfo);
  const { setValue } = useFormContext();

  useEffect(() => {
    for (let index = 0; index < watchFor.length; index++) {
      const stepWatched = watchFor[index];
      
      const watchForState = globalFormState[stepWatched];
      const arrayOfDataIsEmpty = !watchForState?.data?.data?.length;

      if(!watchForCanBeEmpty && (!watchForState || arrayOfDataIsEmpty)) {
        setError(true);
        return;
      }

      const watchForLowerCase = toLowerCaseFirstLetter(stepWatched);
      const tabsLabel = watchForState?.data?.data?.filter(item => !!item).reduce((acc, cur) => {
        
        const data = cur[watchForLowerCase];
        const element = data[watchForProp];
        const laterality = data[watchForLaterality];
        const isAorta = element === 'aorta';

        const elementIsInvalid = !element;
        const lateralityIsInvalid = !isAorta && (!laterality || laterality === 'nao-se-aplica');
        if(elementIsInvalid || lateralityIsInvalid) return acc;
        
        const label =  isAorta ? element : `${element.replace('-', ' ')}${laterality ? '-' + laterality : ''}`

        if (customValidation && (stepWatched === customValidationStepWatch)) {
          const arteryHasComplications = globalFormState[customValidationSource]?.data?.[label]?.[customValidationSourceField];
          if (!arteryHasComplications) return acc;
        }

        return [...acc, label]
      }, []).filter(tab => tab !== '');

      const isInvalid = !tabsLabel?.length;
      if(!watchForCanBeEmpty && isInvalid) {
        setError(true);
        return;
      }
      const tabsLabelWithoutDuplicates = [...new Set(tabsLabel)];
      setTabs(prev => [...prev, ...tabsLabelWithoutDuplicates]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const formState = globalFormState[parentName];
    if(!formState) return;
    // eslint-disable-next-line
    Object.entries(formState.data).map(tab => {
      const groupName = tab[0];
      const arrayOfObjects = Object.entries(tab[1]).reduce((acc, [key, value], index) => {
        return [...acc, {[`${groupName}[${key}]`]: value}]
      }, []);

      setValue(arrayOfObjects, true);
    });
    // eslint-disable-next-line
  }, [tabs]);

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  return (
    <>
      {error || !tabs.length ? <p data-testid='errorMessageTabs'>{errorMessage}</p> : (
        <>
          <Paper square>
            <TabsMui
              value={active}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs && tabs.map((tab,index) => <Tab key={tab+index} label={tab}/>)}
            </TabsMui>
          </Paper>

          {tabs && tabs.map((tab, tabIndex) => {
            return (
              <TabPanel value={active} index={tabIndex} key={tabIndex}>
                {children.map(({type, props}, index) => {
                  const groupName = tab;
                  return (
                    <ComponentType
                      key={index}
                      type={type}
                      {...props}
                      {...rest}
                      name={`${groupName}[${props.name}]`}
                      watchFor={
                        props.watchFor
                          ? `${groupName}[${props.watchFor}]`
                          : null
                      }
                    />
                  )
                })}
              </TabPanel>
            );
          })}
        </>
      )}
    </>
  );
}

export default Tabs;