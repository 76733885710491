import React from 'react';

import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

import useStyles from './style';

const FieldList = ({ title, data = [], formState, onInputChange }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Typography variant='subtitle2' classes={{ root: classes.title }}>
          {title}
        </Typography>
        <Grid item className={classes.wrapper}>
          {data.map(({ name, label }, index) => (
            <FormControlLabel
              key={name + index}
              classes={{
                label: classes.label,
                root: classes.formControlLabel
              }}
              control={
                <Checkbox
                  size="small"
                  color="default"
                  classes={{ root: classes.checkbox }}
                  name={name}
                  checked={formState[name]?.checked ? true : false}
                  onChange={({ target }) => onInputChange(name, label, target.checked)}
                />
              }
              label={label}
            />
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default FieldList;