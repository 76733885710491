import React from "react";
import TextField from '@material-ui/core/TextField';
import AutocompleteMui from '@material-ui/lab/Autocomplete';
import { FormControl, FormHelperText } from "@material-ui/core";

export const Autocomplete = props => {
  return (
    <FormControl fullWidth error={!!props.error} variant="outlined">
    <AutocompleteMui
      id={`${props.name}-autocomplete`}
      options={props.data}
      getOptionLabel={props.getOptionLabel}
      getOptionSelected={props.getOptionSelected}
      fullWidth={true}
      renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" error={!!props.error} />}
      disabled={!props.data.length || props.disabled}
      value={props.value}
      onChange={props.handleChange}
      inputValue={props.inputValue}
      onInputChange={props.handleInputChange}
      data-testid={props.name}
    />
    {!!props.error && <FormHelperText>{props.error.message}</FormHelperText>}
    </FormControl>
  )
}

