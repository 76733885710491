import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})

export default api;

const source = axios.CancelToken.source();

export const cancelRequest = {
  token: source.token,
  cancel:source.cancel
}

export const instance = axios;

export const swrFetcher = url => api.get(url).then(res => res.data);
