import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText
} from "@material-ui/core";

import * as Styled from "./style";

const useStyles = makeStyles({
  FormControlLabelRoot: {
    margin: "-1px 0 0 -1px",
    overflow: "hidden",

    "&:hover .MuiFormControlLabel-label": {
      color: "inherit"
    },

    "&$FormControlLabelDisabled:hover .MuiFormControlLabel-label": {
      color: "rgba(0,0,0,0.38)"
    },

    border: "1px solid #d9d9d9",
    "&:first-child": {
      borderRadius: "4px 0 0 4px",
    },

    "&:last-child": {
      borderRadius: "0 4px 4px 0",
    },

  },
  FormControlLabelDisabled: {},
  InputLabel: {
    color: "rgba(0,0,0,0.65)",
    padding:"13.5px 14px",
  },
  Input: {
    display: "none"
  },
  InputChecked: {
    "& ~ span": {
      color: "#fff",
      backgroundColor: "#f51757",
      border: "1px solid #f51757",
      position: "relative",
      "&::after": {
        content: '""',
        color: "transparent",
        position: "absolute",
        right: "-1px",
        width: "1px",
        backgroundColor: "#f51757",
        display: "inline-block"
      }
    }
  },
  FormControl: {
    margin: props => props.FormControl?.margin
  },
  FormLabel: {
    margin: props => props.FormLabel?.margin
  }
});

const RadioButtonGroup = props => {
  const {
    label,
    data,
    formHelperText,
    error,
    className,
    style,
    renderPopover = false,
    disabled,
    ...rest
  } = props;
  const classes = useStyles(style);

  return (
    <FormControl
      component="fieldset"
      className={classes.FormControl}
      error={error}
      disabled={disabled}
    >
      <Styled.LabelWrapper>
        <FormLabel component="legend" className={classes.FormLabel}>
          {label}
        </FormLabel>
        {renderPopover && renderPopover()}
      </Styled.LabelWrapper>
      <RadioGroup aria-label={label} name={label} {...rest} row>
        {data.map(({ value, label }) => (
          <FormControlLabel
            classes={{
              label: classes.InputLabel,
              root: classes.FormControlLabelRoot
            }}
            value={value}
            control={
              <Radio
                classes={{
                  root: classes.Input,
                  checked: classes.InputChecked
                }}
              />
            }
            label={label}
            key={label}
          />
        ))}
      </RadioGroup>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioButtonGroup;
