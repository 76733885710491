import React, { useState, useEffect } from "react";

import { Grid, Paper, Typography, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useSWR from 'swr';

import SideBar from "components/sideBar"
import MainContainer from "components/mainContainer";
import ChartLine from "components/Chart/Line/ChartLine";
import ChartPie from "components/Chart/Pie/ChartPie";
import { swrFetcher } from 'services';
import { COLORS } from './constants';
import { useIsDemoUser } from 'hooks/useIsDemoUser';
import { DASHBOARD_MOCK_DATA } from 'mocks';

import useStyle from './style';

export const Dashboard = () => {
  const [data, setData] = useState({
    proceduresByMonth: [],
    proceduresByTerritory: [],
    treatmentByTerritoriesAoil: [],
    treatmentByTerritoriesFePo: [],
    treatmentByTerritoriesPoDis: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyle();
  const isResponsive = useMediaQuery('(max-width:1024px)');
  const { data: requestData, error } = useSWR('/dashboard', swrFetcher);
  const isDemoUser = useIsDemoUser();

  useEffect(() => {
    if (isDemoUser) return;

    if (error || requestData) {
      setIsLoading(false);
    }
    if (requestData) setData(requestData);
    if (error) console.error(error);
  }, [ requestData, error]);

  useEffect(() => {
    if (isDemoUser) {
      setData(DASHBOARD_MOCK_DATA);
      setIsLoading(false);
    }
  }, [ isDemoUser] );

  const hideProceduresByTerritory = !isLoading && data['proceduresByTerritory'].length === 0;
  const hideTreatmentByTerritoriesAoil = !isLoading
    && data['treatmentByTerritoriesAoil'].reduce((total, { value }) => total += value, 0) === 0;
  const hideTreatmentByTerritoriesFePo = !isLoading
    && data['treatmentByTerritoriesFePo'].reduce((total, { value }) => total += value, 0) === 0;
  const hideTreatmentByTerritoriesPoDis = !isLoading
    && data['treatmentByTerritoriesPoDis'].reduce((total, { value }) => total += value, 0) === 0;

  const dataColorized = Object.keys(data)
    .filter(key => key !== 'proceduresByMonth')
    .reduce((finalObj, key) => ({
      ...finalObj,
      [key]: data[key].map((obj) => ({
        ...obj,
        color: COLORS[key][obj.id]
      }))
    }), {});
  
  return (
    <MainContainer>
      <SideBar title="Dashboard" />
      
      <Box className={classes.container}>
        <Grid container spacing={2} className={classes.row}>
          <Grid item lg={6} xs={12} className={classes.chield}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom align='center'>
                Procedimentos Realizados
              </Typography>
              <ChartLine 
                data={data['proceduresByMonth']} 
                legendBottom='Meses' 
                legendRight='Procedimentos' 
                margin={{
                  top: 10,
                  right: isResponsive ? 20 : 90,
                  bottom: isResponsive ? 130 : 90,
                  left: 60
                }} 
                responsive={isResponsive}
              />
            </Paper>
          </Grid>
          <Grid item lg={6} xs={12} className={classes.chield}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom align='center'>
                Procedimento Total por Território
              </Typography>
              {hideProceduresByTerritory ? (
                <EmptyDataMessage />
              ): (
                <ChartPie 
                  data={dataColorized['proceduresByTerritory']} 
                  legendBottom='Meses' 
                  legendRight='Procedimentos' 
                  margin={{ top: 40, right: 80, bottom: 110, left: 80 }}
                  sliceLabel={(item) => `${item.value}%`}
                  fill={[
                    {
                        match: {
                            id: 'Fe-Po'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'Po-Dis'
                        },
                        id: 'lines'
                    }
                  ]}
                  legendItemWidth={100}
                  legendAnchor='bottom'
                  legendDirection='row'
                  legendTranslateY={56}
                  legendTranslateX={0}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                />
              )}
              
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.rowBottom}>
        <Grid item lg={4} xs={12} className={classes.chield}>
          <Paper className={`${classes.paper} ${classes.paddingPaper}`}>
            <Typography variant="subtitle1" gutterBottom align='center'>
              Tipo de Tratamento por Território Ao-il
            </Typography>
            {hideTreatmentByTerritoriesAoil ? (
              <EmptyDataMessage />
            ) : (
              <ChartPie 
                data={dataColorized['treatmentByTerritoriesAoil']} 
                legendBottom='Meses' 
                legendRight='Procedimentos' 
                margin={{ top: 20, right: 140, bottom: 70, left: 0 }}
                sliceLabel={(item) => `${item.value}%`}
                fill={[
                  {
                      match: {
                          id: 'Fe-Po'
                      },
                      id: 'dots'
                  },
                  {
                      match: {
                          id: 'Po-Dis'
                      },
                      id: 'lines'
                  }
                ]}
                legendItemWidth={70}
                legendAnchor='right'
                legendDirection='column'
                legendTranslateY={0}
                legendTranslateX={80}
                enableRadialLabels={false}
                legendItemsSpacing={5}
                enableArcLabels={false}
                enableArcLinkLabels={false}
              />
            )}
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.chield}>
          <Paper className={`${classes.paper} ${classes.paddingPaper}`}>
            <Typography variant="subtitle1" gutterBottom align='center'>
              Tipo de Tratamento por Território FePo
            </Typography>
            {hideTreatmentByTerritoriesFePo ? (
              <EmptyDataMessage />
            ) : (
              <ChartPie 
                colors={{"scheme":"category10"}}
                data={dataColorized['treatmentByTerritoriesFePo']} 
                legendBottom='Meses' 
                legendRight='Procedimentos' 
                margin={{ top: 20, right: 140, bottom: 70, left: 0 }}
                sliceLabel={(item) => `${item.value}%`}
                fill={[
                  {
                      match: {
                          id: 'Fe-Po'
                      },
                      id: 'dots'
                  },
                  {
                      match: {
                          id: 'Po-Dis'
                      },
                      id: 'lines'
                  }
                ]}
                legendItemWidth={70}
                legendAnchor='right'
                legendDirection='column'
                legendTranslateY={0}
                legendTranslateX={80}
                enableRadialLabels={false}
                legendItemsSpacing={5}
                enableArcLabels={false}
                enableArcLinkLabels={false}
              />
            )}
            
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.chield}>
          <Paper className={`${classes.paper} ${classes.paddingPaper}`}>
            <Typography variant="subtitle1" gutterBottom align='center'>
              Tipo de Tratamento por Território PoDis
            </Typography>
            {hideTreatmentByTerritoriesPoDis ? (
              <EmptyDataMessage />
            ) : (
              <ChartPie 
                colors={{"scheme":"dark2"}}
                data={dataColorized['treatmentByTerritoriesPoDis']} 
                legendBottom='Meses' 
                legendRight='Procedimentos' 
                margin={{ top: 20, right: 140, bottom: 70, left: 0 }}
                sliceLabel={(item) => `${item.value}%`}
                fill={[
                  {
                      match: {
                          id: 'Fe-Po'
                      },
                      id: 'dots'
                  },
                  {
                      match: {
                          id: 'Po-Dis'
                      },
                      id: 'lines'
                  }
                ]}
                legendItemWidth={70}
                legendAnchor='right'
                legendDirection='column'
                legendTranslateY={0}
                legendTranslateX={80}
                enableRadialLabels={false}
                legendItemsSpacing={5}
                enableArcLabels={false}
                enableArcLinkLabels={false}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
      </Box>


    </MainContainer>
  )
}

const EmptyDataMessage = () => (
  <Box width='100%' flexGrow='1' display='flex' alignItems='center' justifyContent='center'>
    <Typography>Não há dados suficientes</Typography>
  </Box>
);