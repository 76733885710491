import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    height: 205,
    padding: theme.spacing(1, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  wrapper: {
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  button: {
    float: 'right',
  },
  inputsContainer: {
    margin: '0 0 30px',
  }
}));