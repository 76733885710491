import React, { useState, useEffect, useRef, useContext } from "react";
import ComponentType from "../componentType";
import FormRegisterInfo from "../context/form-context";
import { useFormContext } from "react-hook-form";
import * as Styled from "./FieldArray.style";
import { v4 as uuidv4 } from 'uuid';
import { getInputData, getObjectByStringPath, timeout } from "../../helpers"

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  
}));

const useStyles2 = makeStyles({
  root: {
    padding: "0 16px 0 8px"
  },
  content: {
    margin: "0",
    alignItems: "center",

    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {}
});

const FieldArray = (props) => {
  const { name, title, children, parentName, buttonTitle, formSchemaVersion, formSchemaType, ...rest } = props;

  const { state: globalFormState } = useContext(FormRegisterInfo);
  const { setValue } = useFormContext();

  const [data, setData] = useState([]);
  const [state, setState] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);

  const isFirstRun = useRef(true);
  const counter = useRef(0);
  
  const classes = useStyles();
  const classesPanelSummary = useStyles2();

  // Modal de escolha de dispositivo
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = async (value) => {
    setIsModalOpen(false);

    if (value === undefined) return;

    const index = data.length;

    const label = deviceTypes.find(device => device.value === value).label;

    duplicate(undefined, index, label);

    await timeout(1000);

    setValue([
      {
        [`data[${index}][${name}][dispositivo]`]: value
      },
      {
        [`data[${index}][${name}][card-label]`]: label,
      }
    ]);
  };

  useEffect(() => {
    const formState = globalFormState[parentName];

    if (formState) {
      const { data: {data} } = formState;
      if(!data) return;
      // eslint-disable-next-line
      data.map( (obj, index) => {
        const sourceObj = getObjectByStringPath(obj, name);
        // eslint-disable-next-line
        if(!obj) return;
        // eslint-disable-next-line
        if(!sourceObj) return;

        const [newObj, label] = Object.entries(sourceObj).reduce((acc, [key, value]) => (
          [
            [...acc[0], { [`data[${index}][${name}][${key}]`]: value }],
            key === 'card-label' ? value : acc[1]
          ]
        ), [[], '']);

        duplicate(undefined, index, label);
        setState((prev) => [...prev, newObj]);
        counter.current = index + 1;
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInputData({name: 'dispositivo', formSchemaType, formSchemaVersion, callback: (data) => {
      setDeviceTypes(data.filter(({ value }) => value !== 'introdutor'))
    }});
  }, [formSchemaType, formSchemaVersion]);

  const duplicate = (id = uuidv4(), key = counter.current, label) => {
    setData((prev) => [...prev, {id: id, children, label, key: key}])
    counter.current++;
  };

  const remove = (event, index) => {
    event.stopPropagation();
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
  }


  useEffect(() => {
    state.map((obj) => setValue(obj, true));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setExpanded(`panel${data.length - 1}`);
  }, [data]);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Styled.Container>
      <Button
        variant="contained"
        color="primary"
        data-testid={"btn-duplicate"}
        type="button"
        onClick={handleClickOpen}
        style={{
          alignSelf: "flex-end",
          margin: "0 0 12px",
        }}
        disabled={props.disabled}
      >
        { buttonTitle ?? `Adicionar ${title}`}
      </Button>
      
      {data.map(({id, children: group, key, label}, index) => {
        const groupName = `data[${key}][${name}]`;

        return (
          <ExpansionPanel
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={`panel-${id}-key`}
            square={false}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              classes={classesPanelSummary}
            >
              <IconButton
                variant="contained"
                color="secondary"
                onClick={(event) => remove(event, index)}
                onFocus={(event) => event.stopPropagation()}
                aria-label="remove"
              >
                {<DeleteIcon fontSize="small"/>}
              </IconButton>
              <Typography>{`${index + 1} - ${label}`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={classes}>
              {group.map(({ type, props }) => {
                return (
                  <Styled.ListItem key={`${props.name}-${id}`}>
                    <ComponentType
                      type={type}
                      {...rest}
                      {...props}
                      name={`${groupName}[${props.name}]`}
                      watchFor={
                        props.watchFor
                          ? `${groupName}[${props.watchFor}]`
                          : null
                      }
                      isFieldArrayChild={true}
                      fieldArrayName={groupName}
                      fieldArrayChildName={props.name}
                      formSchemaVersion={formSchemaVersion} 
                      formSchemaType={formSchemaType}
                    />
                  </Styled.ListItem>
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
      <SimpleDialog open={isModalOpen} onClose={handleClose} devices={deviceTypes} />
    </Styled.Container>
  );
};

export default FieldArray;


function SimpleDialog(props) {
  const { onClose, selectedValue, open, devices } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Selecione o dispositivo</DialogTitle>
      <List>
        {devices.map((device) => (
          <ListItem button onClick={() => handleListItemClick(device.value)} key={device.value}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="button"
              disabled={props.disabled}
            >
              {device.label}
            </Button>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
