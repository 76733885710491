import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 210mm;
  height: 297mm;
  border: 1px solid red;
  padding: 2rem 3rem;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1.25rem
`

export const Section = styled.section`
  margin-bottom: 1.25rem
`

export const Line = styled.div`
  display: flex;
  gap: 0.25rem;
`

export const MultiColumn = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CapitalizeTypography = styled(Typography)`
  text-transform: capitalize;
`