import React, { useState, useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";
import Select from "../index";
import Popover from "../../popover";

import * as Styled from "./style";

import { getObjectByStringPath, getInputData } from "../../../helpers";

const SelectControlled = (props) => {
  const {
    label,
    name,
    conditionalSetValue = () => {},
    popover,
    classes,
    apiCallId,
    apiCustomUrl,
    disabled,
    readOnly,
    formSchemaVersion, 
    formSchemaType, 
    inputData,
    ...rest
  } = props;
  
  const { control, errors } = useFormContext();

  const [data, setData] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    getInputData({ 
      name: apiCallId, 
      formSchemaType, 
      formSchemaVersion, 
      inputData, 
      isCancelled, 
      callback: data => setData(data),
      customUrl: apiCustomUrl
    });

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    conditionalSetValue();
    // eslint-disable-next-line
  }, []);

  const errorObject = getObjectByStringPath(errors, name);

  return (
    <Styled.SelectWrapper>
      <Controller
        as={<Select />}
        label={label}
        data={data}
        formHelperText={errorObject?.message}
        error={!!errorObject}
        name={name}
        control={control}
        defaultValue=""
        SelectDisplayProps={{
          'data-testid':name
        }}
        disabled={disabled || readOnly}
      />

      <Styled.PopoverWrapper error={errorObject}>
        {popover && <Popover name={apiCallId || name} classes={classes} formSchemaVersion={formSchemaVersion} formSchemaType={formSchemaType} {...rest} />}
      </Styled.PopoverWrapper>
    </Styled.SelectWrapper>
  );
};

export default SelectControlled;
