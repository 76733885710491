import styled from 'styled-components';

export const AutoCompleteWrapper = styled.div`
  width: 100%;
  /* padding-right: 47.5px; */
  margin: 0 0 15px;
  display: flex;
`

export const PopoverWrapper = styled.div`
  align-self: center;
  width: 47.5px;
  text-align: center;
  margin-bottom: ${({error}) => error ? '18px' : '-4px'};
`;
