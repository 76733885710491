import React, { useState, useEffect } from 'react';

import { Box, Typography, Grid } from '@material-ui/core';

import ChartBar from "components/Chart/Bar/ChartBar";

import { ListItem } from './style';

function ProceduresByHospital({ data, limitDate }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(
      data.map(({ hospital, count }) => ({
        id: hospital,
        value: count,
        color: hospital === 'Centro' ? 'hsl(209, 58%, 76%)' : 'hsl(218, 52%, 52%)',
      }))
    )
  },[ data ]);

  return (
    <Grid container direction='column' alignItems='center'>
      <Box>
        <Typography align='center' style={{ color: '#424242' }} variant='h6' gutterBottom>
          Volume de Procedimentos por centro
        </Typography>
        <Box width='500px' height='300px'>
          <ChartBar
            data={chartData}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Procedimentos',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            axisBottom={null}
            isInteractive={false}
            colors={{ datum: 'data.color' }}
            borderRadius={8}
          />
        </Box>
      </Box>
      <Box
        position='relative'
        top='-25px'
        display='flex'
        alignItems='center'
        flexDirection='column'
      >
        <Box display='flex' alignItems='center'>
          <div style={{
            width: '15px',
            height: '15px',
            background: 'hsl(209, 58%, 76%)',
            margin: '0 8px 0 0',
            borderRadius: '4px'
          }}/>
          <Typography variant='caption' style={{ lineHeight: 1 }}>Centro</Typography>
        </Box>
        <Typography variant='caption' style={{ lineHeight: 1, marginTop: '16px' }}>
          *Centros com menos de 10 casos não são mostrados
        </Typography>
      </Box>
      <Box maxWidth={715} width='100%'>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
          <small>
            <p><strong>Notas</strong></p>
            <p>
              <ol>
                <ListItem>
                  Em todos os relatórios, os dados regionais não são mostrados se a região tem menos de 3 centros com ao menos 10 casos alcançando os critérios de inclusão para cada desfecho no registro aplicável.
                </ListItem>
                <ListItem>
                  {'Em “por Centro” nos gráficos de barra, dados não são mostrados para centros com < de 10 casos e para regiões com < 3 centros com < 10 casos.'}
                </ListItem>
                <ListItem>
                  {'Em todos os gráficos, “*” indica um valor de p<0,05.'}
                </ListItem>
                <ListItem>
                  Este relatório inclui todos os dados que entraram no registro até {limitDate}.
                </ListItem>
              </ol>
            </p>
          </small>
        </Typography>
      </Box>
    </Grid>
  );
}

export default ProceduresByHospital;
