import React from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';

import { Fields } from './Fields/';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '8px 0 0',
  },
  button: {
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: 'calc(100% + 8px)',
      left: 0,
      zIndex: 1,
    },
  }
}));

const Filters = ({
  fields = {},
  fieldsTypes = {},
  operators = {},
  filterFields,
  onAdd,
  onChange,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.container }}>
      <Grid item lg={10} xs={12}>
        {[...filterFields.entries()].map(( [id, formState] ) => {
          return <Fields 
            key={id}
            fields={fields}
            fieldsTypes={fieldsTypes}
            operators={operators}
            onDelete={() => onDelete(id)}
            onChange={(value) => onChange(id, value)}
            formState={formState}
          />
        })}
      </Grid>
      <Box ml='auto' className={classes.button}>
        <Button
          onClick={onAdd}
          color="default"
          variant="contained"
          size="medium"
        >
          + Adicionar Filtro
        </Button>
      </Box>
    </Grid>
  );
}

export default Filters