import React, { useState, useEffect } from 'react';

import { Box, Typography, Grid } from '@material-ui/core';

import ChartBar from "components/Chart/Bar/ChartBar";

function CriticalIschemic({ data }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(
      data.map(({ hospital, percentage }) => ({
        id: hospital,
        value: percentage,
        color: hospital === 'Centro' ? 'hsl(218, 52%, 52%)' : 'hsl(209, 58%, 76%)',
      }))
    )
  },[ data ]);

  const format = v => `${v}%`

  return (
    <Grid container direction='column' alignItems='center'>
      <Box>
        <Typography align='center' style={{ color: '#424242' }} variant='h6' gutterBottom>
          {'% Isquemia Crítica realizadas com < 5 dias de internação (data)'}
        </Typography>
        <Box width='500px' height='300px'>
          <ChartBar
            valueFormat={format}
            data={chartData}
            axisLeft={{
              format,
            }}
            axisBottom={null}
            isInteractive={false}
            colors={{ datum: 'data.color' }}
            borderRadius={8}
          />
        </Box>
      </Box>
      <Box
        position='relative'
        top='-25px'
        display='flex'
        alignItems='center'
        flexDirection='column'
      >
        <Box display='flex' alignItems='center'>
          <div style={{
            width: '15px',
            height: '15px',
            background: 'hsl(218, 52%, 52%)',
            margin: '0 8px 0 0',
            borderRadius: '4px'
          }}/>
          <Typography variant='caption' style={{ lineHeight: 1 }}>Centro</Typography>
        </Box>
        <Typography variant='caption' style={{ lineHeight: 1, marginTop: '16px' }}>
          *Centros com menos de 10 casos não são mostrados
        </Typography>
      </Box>
    </Grid>
  );
}

export default CriticalIschemic;
