import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"

import { Copyright } from "./Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidder',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    marginBottom: '50px'
  }
}));

const Form = (props) => {
  const { handleSubmit, inputsRefs, inputsErrors, formError, teamList, handleChange, selectedTeam } = props;
  const { emailRef, passwordRef } = inputsRefs;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">

        <CssBaseline />
        <div className={classes.paper}>
          <img src={require('../../../logo-rebrave.png')} alt="Logo" className={classes.logo}/>

          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              inputRef={emailRef}
              error={inputsErrors["username"]}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              type="password"
              id="senha"
              inputRef={passwordRef}
              error={inputsErrors["password"]}
            />
            {teamList && (
              
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                error={inputsErrors["teamId"]}
              >
                <InputLabel id="select-team-label">Selecione uma equipe</InputLabel>
                <Select
                  labelId="select-team-label"
                  id="select-team"
                  value={selectedTeam}
                  onChange={handleChange}
                  label="Selecione uma equipe"
                >
                  {teamList.map(({name, team_id}) => <MenuItem key={team_id} value={team_id}>{name}</MenuItem>)}
                </Select>
              </FormControl>
              
            )}
            {formError && <FormHelperText error>{formError}</FormHelperText>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default Form;
