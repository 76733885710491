import React, { useState } from 'react';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { format, endOfQuarter, formatISO } from "date-fns";

import api from 'services';
import { timeout } from 'helpers';
import MainContainer from 'components/mainContainer';
import SideBar from 'components/sideBar';
import { Description, Container } from 'domain/MedicalCenterProfile';
import { DatePicker } from 'components/DatePicker';
import { useIsDemoUser } from 'hooks/useIsDemoUser';
import { MEDICAL_CENTER_PROFILE_MOCK_DATA } from 'mocks';

const formatDateISO = (date) => formatISO(date, { representation: 'date' })

const MedicalCenterReport = props => {
  const [reportData, setReportData] = useState([]); 
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dates, setDates] = useState({});
  const isDemoUser = useIsDemoUser();

  const handleButtonClick = () => {
    if (!date) return;

    const startDate = date;
    const startDateFormatted = formatDateISO(date);
    const endDate = endOfQuarter(date);
    const endDateFormatted = formatDateISO(endOfQuarter(date));

    setDates({
      startDate: format(startDate, 'dd/MM/yyyy'),
      endDate: format(endDate, 'dd/MM/yyyy'),
    });
    setIsLoading(true);
    setReportData([]);

    timeout(3000);

    if (isDemoUser) {
      setIsLoading(false);
      setReportData(MEDICAL_CENTER_PROFILE_MOCK_DATA);
      return;
    }

    Promise.all([
      api.get(`/reports/medical-center-profile?start=${startDateFormatted}&end=${endDateFormatted}`),
      timeout(1500),
    ]).then(([{ data }]) => setReportData(data))
    .then(() => setIsLoading(false));
  };

  return (
    <MainContainer overflow='auto'>
      <SideBar title="Perfil Centro" />
      <Box mb={2}>
        <Paper>
          <Box padding={2}>
            <Typography variant='h6' gutterBottom>Relátorio Perfil Centro</Typography>
            <Typography variant='caption'>Selecione o período</Typography>
            <Box>
              <DatePicker
                date={date}
                onChange={setDate}
                dateFormat="yyyy, QQQQ"
                showQuarterYearPicker
              />
            </Box>
            <Box marginY={2}>
              <Button variant="contained" onClick={handleButtonClick} disabled={isLoading}>
                {isLoading? 'Gerando...' : 'Gerar Relatório'}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      {reportData.length > 0 && (
        <Paper>
          <Box padding={2}>
            <Description startDate={dates?.startDate} endDate={dates?.endDate}/>
            <Box pb={1}>
              {reportData.map(({ title, rows }) => (
                  <Container key={title} title={title} tableRows={rows}/>
                ))}
            </Box>
          </Box>
        </Paper>
      )}
    </MainContainer>
  )
}

export default MedicalCenterReport;