import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

const DoubleInput = ({ Input,  onChange, ...rest }) => {
  const [state, setState] = useState({ first: '', second: '' });

  const handleChange = ({ target: { name, value } }) => {
    setState(prev => ({
      ...prev,
      [name]: [value],
    }))
  };

  useEffect(() => {
    onChange({
      target: {
        name: 'values',
        value: [ ...state.first, ...state.second ],
      },
    });
  // eslint-disable-next-line
  }, [state]);

  return (
    <Box display='flex' alignItems='center'>
      <Input
        {...rest}
        name='first'
        onChange={handleChange}
        state={state.first}
        maxDate={state.second}
      />
      <Box marginX={1}>
        <Typography>e</Typography>
      </Box>
      <Input
        {...rest}
        name='second'
        onChange={handleChange}
        state={state.second}
        minDate={state.first}
      />
    </Box>
  );
};

export default DoubleInput;