import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, Input, Chip, MenuItem, FormHelperText } from "@material-ui/core"
// import api from "../../../../services";
import { useFormContext } from "react-hook-form";
import Popover from "../../../popover";
import { getObjectByStringPath, getInputData } from "../../../../helpers";
import * as Styled from "./style";
import { useIsFirstRender } from "../../../../hooks/useIsFirstRender"
import FormRegisterInfo from "../../../context/form-context";;

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  select: {
    height: 'auto'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(value, state, theme) {
  return {
    fontWeight:
      state.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const InputSelectMultiple = props => {
  const { name, label, apiCallId, popover, disabled, formSchemaVersion, formSchemaType, inputData, ...rest } = props;
  const [state, setState] = useState([]);
  const [data, setData] = useState([]);
  const [labelLookUp, setLabelLookUp] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const { register, setValue, unregister, errors } = useFormContext();
  const { state: globalFormState } = useContext(FormRegisterInfo);
  const isFirstRender = useIsFirstRender();

  const handleChange = event => {
    setState(event.target.value);
  }
  

  useEffect(() => {
    register({ name: [name] });

    return () => {
      unregister(name);
    };
    // eslint-disable-next-line 
  }, [register]);

  useEffect(() => {
    setValue(name, state, !isFirstRender);
    // eslint-disable-next-line
  }, [state, name, setValue]);

  useEffect(() => {
    let isCancelled = false;

    getInputData({ 
      name: apiCallId, 
      formSchemaType, 
      formSchemaVersion, 
      inputData, 
      isCancelled, 
      callback: data => setData(data),
    });

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    const labelObject = data.reduce((obj, {value, label}) => {
      return {...obj, [value]: label}
    }, {});
    
    setLabelLookUp(labelObject);
  }, [data]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];
    const inputState = formState?.data && getObjectByStringPath(formState?.data, name);
    inputState && setState(inputState);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  const errorObject = getObjectByStringPath(errors, name);

  return (
    <Styled.SelectWrapper>
      <FormControl className={classes.formControl} error={!!errorObject} disabled={disabled}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          id={name}
          multiple
          value={state}
          onChange={handleChange}
          input={<Input id={`${name}-input`} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={labelLookUp[value]} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
          classes={{root: classes.select}}
        >
          {data.map(({label, value}) => (
            <MenuItem key={value} value={value} style={getStyles(value, state, theme)}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorObject?.message}</FormHelperText>
      </FormControl>
      <Styled.PopoverWrapper error={errors[name]}>
        {popover && <Popover name={apiCallId} classes={classes} formSchemaType={formSchemaType} formSchemaVersion={formSchemaVersion} {...rest} />}
      </Styled.PopoverWrapper>
    </Styled.SelectWrapper>
  )
}

export default InputSelectMultiple;