import React from "react";
import { makeStyles } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "20px",
    height: "20px",
    position: "relative",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
  },
  completed: {
    backgroundColor: "#fff",
  },
  error: {
    backgroundColor: "#fff",
  },
  text: {
    fontSize: "11px",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: theme.palette.secondary.main,
    margin: 0
  },
  textActive: {
    color: "#fff",
  },
}));

const GreenCheckIcon = () => {
  const useStyles = makeStyles((theme) => ({
    colorError: {
      color: theme.palette.success.main,
    },
  }));

  const classes = useStyles();

  return <CheckCircleIcon color="error" classes={classes} />;
};

const WarningIcon = () => {
  const useStyles = makeStyles((theme) => ({
    colorError: {
      color: theme.palette.warning.main,
    },
  }));

  const classes = useStyles();

  return <ErrorIcon color="error" classes={classes} />;
};

const CircleIcon = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "1.1em",
      height: "1.1em",
    },
  }));

  const classes = useStyles();

  return <RadioButtonUncheckedIcon classes={classes} color="secondary" />;
};

const StepIcon = React.memo((props) => {
  const { active, completed, error, icon } = props;
  const classes = useStyles();

  const isDefault = !completed && !error;
  const isCompleted = completed;

  const mainClass = `${classes.root} ${active && classes.active} ${
    completed && classes.completed
  } ${error && classes.error}`;
  const textClass = `${classes.text} ${active && classes.textActive}`;

  const iconSelected = () => {
    const icon = {
      default: (props) => <CircleIcon />,
      completed: (props) => <GreenCheckIcon />,
      error: (props) => <WarningIcon />,
    };

    return isDefault
      ? icon["default"]()
      : (isCompleted && icon["completed"]()) || icon["error"]();
  };

  return (
    <div className={mainClass}>
      {iconSelected()}
      <p className={textClass}>{isDefault && icon}</p>
    </div>
  );
});

export default StepIcon;
