import React, { createRef, useState } from "react";
import * as yup from "yup";
import Form from "./Form";

const validationSchema = yup.object().shape({
  email: yup.string().required("Usuário não preenchido"),
  password: yup.string().required("Senha não preenchida"),
  teamId: yup.number('Equipe não selecionada')
});

const FormLogin = (props) => {
  const { formError, setFormState, teamList } = props;
  const [inputsErrors, setInputsErrors] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(false);

  const emailRef = createRef();
  const passwordRef = createRef();

  const getValues = () => {
    const obj = {};
    obj.email = emailRef.current.value;
    obj.password = passwordRef.current.value;
    if(teamList) obj.teamId = selectedTeam;
    return obj;
  };

  const validate = (obj) => {
    validationSchema
      .validate(obj, { abortEarly: false })
      .then((values) => {
        setInputsErrors({});
        setFormState(values);
      })
      .catch((err) => {
        const errors = err.inner;
        const errorsObj = errors.reduce((acc, { message, path }) => {
          return { ...acc, [path]: message };
        }, {});
        setInputsErrors(errorsObj);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValues = getValues();
    validate(formValues);
  };

  const handleChange = event => setSelectedTeam(event.target.value);

  return (
    <Form
      handleSubmit={handleSubmit}
      inputsRefs={{ emailRef, passwordRef }}
      inputsErrors={inputsErrors}
      formError={formError}
      teamList={teamList}
      handleChange={handleChange}
      selectedTeam={selectedTeam}
    />
  );
};

export default FormLogin;
