import React from "react";
import { ResponsivePie } from '@nivo/pie';

const ChartPie = props => {
  const { 
    data, 
    margin, 
    sliceLabel, 
    fill, 
    legendItemWidth, 
    legendAnchor, 
    legendDirection,
    legendTranslateY,
    legendTranslateX,
    enableRadialLabels = true,
    legendItemsSpacing = 0,
    colors = { scheme: 'nivo' },
    ...rest
   } = props;

  return (
    <ResponsivePie
        data={data}
        margin={margin}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={colors}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        sliceLabel={sliceLabel}
        enableRadialLabels={enableRadialLabels}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={fill}
        legends={[
            {
                anchor: legendAnchor,
                direction: legendDirection,
                justify: false,
                translateX: legendTranslateX,
                translateY: legendTranslateY,
                itemsSpacing: legendItemsSpacing,
                itemWidth: legendItemWidth,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
        {...rest}
    />
  )

}

export default ChartPie;
