import React from "react";

import Switch from "../Switch";
import SwitchSelect from "../Switch/Select";
import SwitchRadio from "../Switch/Radio";
import InputTextControlled from "../Input/Text/Controlled";
import InputTextMaskedControlled from "../Input/Text/Masked/Controlled";
import { RadioControlled } from "../Radio/Controlled/index";
import SelectControlled from "../Select/Controlled/";
import ConditionalInputs from "../ConditionalInputs";
import SwitchMultInputs from "../Switch/MultInputs";
import InputTextSearchDevice from "../Input/Text/Search/Device";
import { FieldArray, FieldArrayDevices } from "../FieldArray";
import { InputSlider } from "../Input/Slider";
import { InputTextArea } from "../Input/Text/Area";
import { Tabs } from "../Tabs"
import { InputSelectMultiple } from "../Input/Select/Multiple";
import { InputAutocompleteCompany, InputAutocompleteDevice } from "../Input/Autocomplete";
import { Uploader } from "../Uploader";
import { InputClone } from "../Input/Clone";
import { Typography } from "../Typography";
import InputTextDate from "../Input/Text/Date";
import InputTextSearchCpf from '../Input/Text/Search/Cpf';

const dictionaryComponent = {
  switch: (props) => <Switch {...props}></Switch>,
  radio: (props) => <RadioControlled {...props} />,
  select: (props) => <SelectControlled {...props} />,
  conditionalInput: (props) => <ConditionalInputs {...props} />,
  switchSelect: (props) => <SwitchSelect {...props} />,
  switchRadio: (props) => <SwitchRadio {...props} />,
  textMasked: (props) => <InputTextMaskedControlled {...props} />,
  switchMultInputs: (props) => <SwitchMultInputs {...props} />,
  inputTextSearchDevice: (props) => <InputTextSearchDevice {...props} />,
  text: (props) => <InputTextControlled {...props} />,
  fieldArray: (props) => <FieldArray {...props} />,
  fieldArrayDevices: (props) => <FieldArrayDevices {...props} />,
  slider: props => <InputSlider {...props}/>,
  textarea: props => <InputTextArea {...props} />,
  tabs: props => <Tabs {...props} />,
  selectMultiple: props => <InputSelectMultiple {...props} />,
  autocompleteCompany: props => <InputAutocompleteCompany {...props} />,
  autocompleteDevice: props => <InputAutocompleteDevice {...props} />,
  uploader: props => <Uploader {...props} />,
  cloneInput: props => <InputClone {...props} />,
  typography: props => <Typography {...props} />,
  inputTextDate: props => <InputTextDate {...props} />,
  inputTextSearchCpf: props => <InputTextSearchCpf {...props} />,
};

const ComponentType = ({ type, ...props }) => {
  return dictionaryComponent[type](props);
};

export default ComponentType;
