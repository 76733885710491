import React, { useContext, useEffect, useRef } from "react";
import { Grid, CardContent, makeStyles, Button } from "@material-ui/core";

import FormHeader from "../formHeader/index";

import ComponentType from "../componentType";

import FormRegisterInfo from "../context/form-context";
import { useForm, FormContext, Controller } from "react-hook-form";

import { produce, enableMapSet } from "immer";

enableMapSet();

const useStyle = makeStyles((theme) => ({
  Card: {
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  switch: {
    display: "none",
  },
  divider: {
    margin: "13px 0 8px",
  },
  select: {
    margin: "0 0 6px",
  },
  radioGroup: {
    marginTop: "-7px",
  },
  radioGroup1: {
    marginTop: "7px",
    marginBottom: "-7px",
  },
  creatinina: {
    marginBottom: "16px",
  },
}));

const validateForm = obj => {
  const { isValid, name, prevStepName, setSubStepComplete } = obj;

  setSubStepComplete((prev) => {
    return produce(prev, (draft) => {
      const prevMap = prev.get(prevStepName);
      const errors = prevMap?.get("errors");
      const completed = prevMap?.get("completed");

      const newMap = new Map(prevMap);

      newMap.set("errors", new Map(errors));
      newMap.set("completed", new Map(completed));

      const newErrors = newMap.get("errors");
      const newCompleted = newMap.get("completed");

      if (isValid) {
        newErrors.delete(name);
        newCompleted.set(name, true);
        return draft.set(prevStepName, newMap);
      }

      newCompleted.delete(name);
      newErrors.set(name, true);

      return draft.set(prevStepName, newMap);
    });
  });
}

const Formulario = (props) => {
  const { json, validation, complete } = props;

  const {
    state,
    dispatch,
    setSubStepComplete,
    actualSubStep,
    setClickInterceptor,
    formSchemaVersion,
    formSchemaType,
    inputsData
  } = useContext(FormRegisterInfo);

  const methods = useForm({
    mode: "onBlur",
    validationSchema: validation,
  });

  const { handleSubmit, getValues, setValue } = methods;

  const prevStep = useRef(state.activeStep);
  const prevSubStep = useRef(actualSubStep);

  useEffect(() => {
    const formState = state[json.schemaName];
    
    dispatch({
      type: "setActiveForm",
      payload: {
        name: json.schemaName,
      },
    });

    if (formState) {
      const { data } = formState;
      const dataToSubmit = Object.entries(data).reduce(
        (prev, curr) => [...prev, { [curr[0]]: curr[1] !== undefined ? curr[1] : "" }],
        []
      );
      setValue(dataToSubmit, true);
    }

    prevStep.current = state.activeStep;
    // eslint-disable-next-line
  }, [json]);

  useEffect(() => {
    const isSameStep = prevSubStep.current === actualSubStep;
    if (isSameStep) return;

    const name = json.schemaName;
    const data = getValues({ nest: true });
    const prevStepName = prevStep.current;

    dispatch({
      type: "addFormData",
      payload: {
        name,
        data,
      },
    });

    validation
      .validate(getValues({ nest: true }))
      .then((obj) => {
        const objIsEmpty = Object.keys(obj).length === 0;
        if(objIsEmpty) throw Error('empty object');
        validateForm({isValid: true, name, prevStepName, setSubStepComplete});
      })
      .catch(err => {
        validateForm({isValid: false, name, prevStepName, setSubStepComplete});
      })
      .finally(() => {
        setClickInterceptor(actualSubStep);
        prevSubStep.current = actualSubStep;
        window.scrollTo({ top: 120, behavior: 'smooth' });
      });
      // eslint-disable-next-line
  }, [actualSubStep]);

  const onSubmit = (data) => console.log(data);

  const classes = useStyle();

  return (
    <FormContext {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          <Grid item xl={6} lg={8} xs={12}>
            <CardContent className={classes.cardContent}>
              <FormHeader text={json.title} />
              {json.children.map(({ type, props }) => (
                <ComponentType
                  key={props.name}
                  type={type}
                  {...props}
                  Controller={Controller}
                  classes={classes}
                  disabled={complete ? json.disableScreenWhenComplete ?? false : false}
                  formSchemaVersion={formSchemaVersion}
                  formSchemaType={formSchemaType}
                  inputData={inputsData[props.apiCallId]}
                ></ComponentType>
              ))}
            </CardContent>
          </Grid>
        </Grid>
        {process.env.NODE_ENV !== "production" && (
          <div>
            <Button
              type="button"
              onClick={() => console.log(getValues({ nest: true }))}
            >
              Button GetValues
            </Button>
            <Button
              onClick={() => {
                setValue([{"iliaca-comum[cpf]": "123.123.123-12"}]);
              }}
            >
              setValue
            </Button>
          </div>
        )}
      </form>
    </FormContext>
  );
};

export default Formulario;
