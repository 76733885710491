import React from 'react';

import {
  Box,
  TableContainer,
  Table as TableMui,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import cx from 'classnames';

import useStyle from './style';

const Table = ({ rowsData }) => {
  const classes = useStyle();

  return (
    <Box px={2} className={classes.tableWrapper}>
      <TableContainer>
        <TableMui size="small" aria-label="a dense table" padding="none">
          <TableHead>
            <TableRow>
              <TableCell align='center' className={classes.firstColumn} classes={{ head: classes.th }}>Categoria</TableCell>
              <TableCell align='center' className={classes.secondColumn} classes={{ head: classes.th }}>
                Desfecho/Complicação
              </TableCell>
              <TableCell align='center' classes={{ head: classes.th }}>
                Centro
              </TableCell>
              <TableCell align='center' classes={{ head: classes.th }}>
                Nacional
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map(({ highlight, cells }, index) =>(
              <TableRow key={index} className={classes[highlight ? 'tableRowHighlight' : '']}>
                {cells.map(({ text, bold, percentile }, i) => (
                  <TableCell
                    key={i}
                    align='center'
                    className={cx({
                      [classes.bold]: bold,
                      [classes.greenBackground]: percentile === '>75',
                      [classes.redBackground]: percentile === '<25',
                    })}
                    classes={{ root: classes.tableCell }}
                  >
                    {text}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableMui>
      </TableContainer>
    </Box>
  );
};

export default Table;