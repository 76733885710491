import api from "../services"

export default async function getInputData({name, formSchemaType, formSchemaVersion, inputData, callback, isCancelled = false, customUrl}) {
  try {
    const data = await getData({ name, type: formSchemaType, version: formSchemaVersion, inputData, customUrl})
    !isCancelled && callback(data);
  } catch (error) {
    console.error('error', error);
  }
}

async function getData({inputData, name, type, version, customUrl}) {
  try {
    if(inputData) return inputData;

    const { data: { data } } = await api.get(customUrl ?? `inputs-data/${type}/${version}/${name}`);
    return data;
  } catch (error) {
    throw Error(error);
  }
}