import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { FieldList } from './FieldList/'
import RoundedButton from './RoundedButton';

const useStyles = makeStyles((theme) => ({
  container: {
    diplay: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    height: '100%',
    padding: theme.spacing(1),
  },
  button: {
    float: 'right',
  }
}));

const SearchFields = ({ data = [], open, onClose, formState, onInputChange }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {"Selecione os campos que você quer na sua pesquisa"}
          <RoundedButton
            className={classes.button}
            onClick={handleClose}
          >
            <CloseIcon />
          </RoundedButton>
        </DialogTitle>
        <DialogContent dividers>
        <Grid container spacing={2}>
          {
            data.map(({ title, fields }, index) => {

              return (
                <Grid item lg={4} md={6} xs={12} key={title + index}>
                  <Paper classes={{ root: classes.paper }}>
                    <FieldList
                      title={title}
                      data={fields}
                      formState={formState}
                      onInputChange={onInputChange}
                    />
                  </Paper>
                </Grid>
              )
            })
          }
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default SearchFields;