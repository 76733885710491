import React from "react";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText
} from "@material-ui/core";

export default function SelectInput(props) {
  const { label, data, formHelperText, error, ...rest } = props;

  return (
    <FormControl fullWidth error={error} variant="outlined">
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select
        labelId={`select-${label.toLowerCase()}-label`}
        id={`select-${label.toLowerCase()}`}
        label={label}
        {...rest}
      >
        {data.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}
