import React, { useState, useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";
import Radio from "../index";
import Popover from "../../popover";

import * as Styled from "./style";

import { getObjectByStringPath, getInputData } from "../../../helpers";

const RadioControlled = (props) => {
  const { name, label, popover, classes, apiCallId, conditionalSetValue = () => {}, disabled, formSchemaVersion, formSchemaType, inputData } = props;
  const { errors, control } = useFormContext();

  const [data, setData] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    getInputData({ 
      name: apiCallId, 
      formSchemaType, 
      formSchemaVersion, 
      inputData, 
      isCancelled, 
      callback: data => setData(data) 
    });

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    conditionalSetValue();
    // eslint-disable-next-line
  }, []);

  const errorObject = getObjectByStringPath(errors, name);

  return (
    <Styled.RadioWrapper>
      <Controller
        as={<Radio renderPopover={ () => {
          return popover && (
            <Styled.PopoverWrapper>
              <Popover name={apiCallId} classes={classes} formSchemaVersion={formSchemaVersion} formSchemaType={formSchemaType} inputData={inputData} />
            </Styled.PopoverWrapper>
            )
          }}/>}
        name={name}
        control={control}
        label={label}
        data={data}
        formHelperText={errorObject?.message}
        error={!!errorObject}
        defaultValue=""
        style={{
          FormControl: {
            margin: "5px 0 0",
          },
          FormLabel: {
            margin: "0 0 8px",
          },
        }}
        data-testid={name}
        disabled={disabled}
      />
      {/* {popover && (
        <Popover name={name} classes={classes} />
      )} */}
    </Styled.RadioWrapper>
  );
};

export default RadioControlled;
