import React from 'react';
import { Chip, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
  },
  chip: {
    margin: '4px 2px',
  },
}));

const SelectedFieldsList = ({ fields = [], onDelete }) => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.container }}>
      {fields.map(({ name, label }, index) => (
        <Chip
          key={index + name}
          size="small"
          label={label}
          onDelete={() => onDelete(name, label)}
          classes={{ root: classes.chip }}
        />
      ))}
    </Grid>
  );

};

export default SelectedFieldsList;