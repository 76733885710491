import React, { useState, useEffect, useContext } from "react";

import { useFormContext } from "react-hook-form";

import { debounce, isAfterToday, minDate } from 'helpers';
import { useIsFirstRender } from 'hooks/useIsFirstRender';
import InputTextMaskedControlled from '../Masked/Controlled';
import FormRegisterInfo from "components/context/form-context";

const isAfter = debounce((firstDate, secondDate, cb) => {
  if (firstDate === null || secondDate === null) return;
  cb(minDate(firstDate, secondDate));
}, 200);

function InputTextDate(props) {
  const { name } = props;

  const [ procedureDate, setProcedureDate ] = useState("");
  const { state: globalFormState } = useContext(FormRegisterInfo);
  const { watch, setError, setValue, clearError } = useFormContext();
  const isFirstRender = useIsFirstRender();

  const inputWatched = watch(name);

  useEffect(() => {
    const formState = globalFormState?.General?.data;
    if (!formState) return;

    setProcedureDate(formState['data-procedimento']);
  }, [globalFormState]);

  useEffect(() => {
    if (procedureDate) {
      clearError(name);
      return;
    }

    setError(name, 'fillProcedureDate', 'Preencha primeiro a data de procedimento');
    // eslint-disable-next-line
  }, [procedureDate]);
  

  useEffect(() => {
    if (isFirstRender) return;

    const dateIsFullWritten = inputWatched?.length === 10;
    if (!dateIsFullWritten) return;

    if (!procedureDate) {
      setError(name, 'fillProcedureDate', 'Preencha primeiro a data de procedimento');
      setValue(name, '', false);
      return;
    }

    isAfter(inputWatched, procedureDate, (isAfter) => {
      if (!isAfter) {
        setError(name, 'minDateRefProcedureDate', `Data não pode ser menor que a data do procedimento: ${procedureDate}`)
        setValue(name, '');
      }

      if (isAfterToday(inputWatched)) {
        setError(name, 'maxCurrentDate', `Data não pode ser maior que a data de hoje`)
        setValue(name, '');
      }
    });
    // eslint-disable-next-line
  }, [inputWatched])

  return (
    <InputTextMaskedControlled
      {...props}
    />
  );
}

export default InputTextDate;
