import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  th: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,

      '&:not(:last-child)': {
        paddingRight: 6,
      }
    },
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  tableWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  firstColumn: {
    width: '40%',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));