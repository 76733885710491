import React from 'react';

import * as S from './styles';
import { Box, Divider, Typography } from '@material-ui/core';

export const SurgicalDescription = React.forwardRef((props, ref) => {
  if (!Object.keys(props.data).length) return null;

  return (
    <S.Container ref={ref}>
      <S.LogoContainer>
        <Typography variant='h3'>LOGO</Typography>
      </S.LogoContainer>
      <S.Section>
        <S.Line>
          <Typography>
            <Box fontWeight="fontWeightMedium">Paciente: </Box>
          </Typography>
          <Typography>{props.data.patientName}</Typography>
        </S.Line>
        <S.MultiColumn>
          <S.Line>
            <Typography>
              <Box fontWeight="fontWeightMedium">Sexo: </Box>
            </Typography>
            <S.CapitalizeTypography>{props.data.sex}</S.CapitalizeTypography>
          </S.Line>
          <S.Line>
            <Typography>
              <Box fontWeight="fontWeightMedium">Idade: </Box>
            </Typography>
            <Typography>{props.data.age} anos</Typography>
          </S.Line>
          <S.Line>
            <Typography>
              <Box fontWeight="fontWeightMedium">Data de Nasc.: </Box>
            </Typography>
            <Typography>
              {new Intl.DateTimeFormat('pt-BR', {timeZone: 'UTC'}).format(new Date(props.data.birthDate))}
            </Typography>
          </S.Line>
        </S.MultiColumn>
        <S.Line>
          <Typography>
            <Box fontWeight="fontWeightMedium">Prontuário: </Box>
          </Typography>
          <Typography>{props.data.patientRecords}</Typography>
        </S.Line>
        <S.Line>
          <Typography>
            <Box fontWeight="fontWeightMedium">Data do exame: </Box>
          </Typography>
          <Typography>{new Intl.DateTimeFormat('pt-BR', {timeZone: 'UTC'}).format(new Date(props.data.procedureDate))}</Typography>
        </S.Line>
        <S.Line>
          <Typography>
            <Box fontWeight="fontWeightMedium">Médico: </Box>
          </Typography>
          <Typography>{props.data.surgeon}</Typography>
        </S.Line>
      </S.Section>
      <Divider />
      <S.Section />
      <S.Section>
        <Typography>
          <Box fontWeight="fontWeightMedium">Procedimento:</Box>
        </Typography>
      </S.Section>
      <S.Section>

      {/* Frase 1a */}
        <Typography>
          {props.data.arteriesTreated.length > 0 && props.data.arteriesTreated.reduce((acc, cur, index) => {
            if (index > 0) {
              return acc + `, e ${cur.name} ${cur.laterality === 'nao-se-aplica' ? '' : cur.laterality}`
            }

            return acc + `${cur.name}${cur.laterality === 'nao-se-aplica' ? '' : ' '+cur.laterality}`;
          }, 'Angioplastia de ')}
        </Typography>
        {/* Frase 1b */}
        
        {props.data.arteriesCouldNotBeTreated.map((artery) => (
          <Typography>
            Tentativa sem sucesso de angioplastia em {artery.name} {artery.laterality === 'nao-se-aplica' ? '' : artery.laterality}
          </Typography>
        ))}

        {/* Frase 2a */}
        {props.data.concomitantInfraInguinalShunt && (
          <Typography>Derivação supra-inguinal concomitante</Typography>
        )}

        {/* Frase 2b */}
        {props.data.concomitantSupraInguinalShunt && (
          <Typography>Derivação infra-inguinal concomitante</Typography>
        )}

        {/* Frase 3 */}
        {props.data.pharmacologicalThrombolysis && (
          <Typography>Trombólise Farmacológica</Typography>
        )}

        {/* Frase 4a */}
        {props.data.arteries.length !== props.data.arteriesCouldNotBeTreated.length && (
          <Typography>Angiografias pré e pós operatórias</Typography>
        )}

        {/* Frase 4b */}
        {props.data.arteries.length === props.data.arteriesCouldNotBeTreated.length && (
          <Typography>Angiografias diagnósticas</Typography>
        )}

        {/* Frase 5 */}
        {props.data.arteries.length === props.data.arteriesCouldNotBeTreated.length && (
          <Typography>Angiografias de posicionamento</Typography>
        )}

        {/* Frase 6 */}
        {props.data.access.some(access => access.puncture === 'doppler') && (
          <Typography>USG doppler intra-operatório </Typography>
        )}

        {/* Frase 7a */}
        {props.data.access.reduce((acc, cur) => {
          if (cur.puncture === 'disseccao') {
            return <Typography>Dissecção de {cur.viaAccess} {cur.viaAccessSide}</Typography>
          }

          return acc; 
        }, '')}

        {/* Frase 7b */}
        {props.data.access.reduce((acc, cur) => {
          if (cur.puncture === 'endarterectomia-concomitante') {
            return <Typography>Endarterectomia de {cur.viaAccess} {cur.viaAccessSide} {cur.deepplasty && 'com profundoplastia' }</Typography>
          }

          return ''; 
        }, '')}
      </S.Section>

      <Divider />
      <S.Section />
      <S.Section>
        <Typography>
          <Box fontWeight="fontWeightMedium">Material:</Box>
        </Typography>
      </S.Section>

      {/* Frase 1 */}
      {props.data.access?.[0]?.shortIntroducerLength && (
        <Typography>Introdutor {props.data.access?.[0]?.shortIntroducerLength}Fr </Typography>
      )}

      {/* Frase 2 */}
      {props.data.access?.[0]?.longIntroducer && (
        <Typography>Introdutor longo {props.data.access?.[0]?.longIntroducerType} {props.data.access?.[0]?.longIntroducerSize}Fr x {props.data.access?.[0]?.longIntroducerLength}cm </Typography>
      )}

      {/* Frase 3 */}
      {props.data.access?.[0]?.arterialClosureType !== 'compressao-manual' && (
        <Typography>{props.data.access?.[0]?.accessNumberOfDevicesUsed} Dispositivo{props.data.access?.[0]?.accessNumberOfDevicesUsed > 1 ? 's' : ''}</Typography>
      )}

      {/* Frase 4 */}
      {props.data.co2 && (
        <Typography>Dispositivo CO2</Typography>
      )}

      {/* Frase 5 */}
      {props.data.devices['fio-guia'] && props.data.devices['fio-guia'].map((item) => (
        <Typography>Fio guia {item.device} { item.diameter? `${item?.diameter} x ${item?.length}cm`: ''}</Typography>
      ))}

      {/* Frase 6 */}
      {props.data.devices['cateter'] && props.data.devices['cateter'].map((item) => (
        <Typography>Cateter {item.device} { item.diameter? `${item?.diameter}Fr`: ''}</Typography>
      ))}

      {/* Frase 8 */}
      {props.data.devices['balao'] && props.data.devices['balao'].map((item) => (
        <Typography>Balão {item.device} { item.diameter? `${item?.diameter} x ${item?.length}mm`: ''}</Typography>
      ))}

      {/* Frase 9 */}
      {props.data.devices['balao'] && (
        <Typography>Manômetro</Typography>
      )}

      {/* Frase 10 */}
      {props.data.devices['stent'] && props.data.devices['stent'].map((item) => (
        <Typography>Stent {item.device} { item.diameter? `${item?.diameter} x ${item?.length}mm`: ''}</Typography>
      ))}

      {/* Frase 11 */}
      {props.data.devices['aterectomia'] && props.data.devices['aterectomia'].map((item) => (
        <Typography>Dispositivo de Aterectomia {item.device}</Typography>
      ))}

      {/* Frase 12 */}
      {props.data.devices['trombectomia'] && props.data.devices['trombectomia'].map((item) => (
        <Typography>Dispositivo de Trombectomía {item.device}</Typography>
      ))}

      {/* Frase 13 */}
      {props.data.devices['protecao-embolica'] && props.data.devices['protecao-embolica'].map((item) => (
        <Typography>Dispositivo de Proteção Embólica {item.device}</Typography>
      ))}

      {/* Frase 14 */}
      {props.data.ivus && props.data.ivusDevice && (
        <Typography>Kit IVUS {props.data.ivusDevice}</Typography>
      )}

      {/* Frase 15a */}
      {props.data.concomitantSupraInguinalShunt && props.data.concomitantSupraInguinalShuntMaterial && (
        <Typography>{props.data.concomitantSupraInguinalShuntMaterial}</Typography>
      )}

      {/* Frase 15b */}
      {!props.data.concomitantSupraInguinalShunt
        && props.data.concomitantInfraInguinalShunt
        && props.data.concomitantInfraInguinalShuntMaterial
        && (
        <Typography>{props.data.concomitantInfraInguinalShuntMaterial}</Typography>
      )}
      
    </S.Container>
  );
});
