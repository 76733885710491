import React, {
  useState,
  useEffect,
  useContext
} from "react";

import { FormControlLabel, Switch } from "@material-ui/core";
import Select from "../../Select";
import { useFormContext } from "react-hook-form";

import FormRegisterInfo from "../../context/form-context";
import { getInputData } from "../../../helpers";

import * as Styled from "./style";
import Popover from "../../popover";

const SwitchMultSelection = (props) => {
  const { 
    classes, 
    name, 
    label, 
    selectLabel, 
    Controller, 
    conditionalSetValue = () => {}, 
    disabled, 
    formSchemaVersion, 
    formSchemaType, 
    inputData, 
    popover,
    popoverSwitch
  } = props;
  const [state, setState] = useState(() => false);
  const [inputInformations, setInputInformations] = useState([]);

  const { control, errors, setValue } = useFormContext();

  const {
    state: globalFormState,
    helperState,
    setHelperState,
  } = useContext(FormRegisterInfo);

  const onChange = () => {
    setState((prevState) => !prevState);
  };

  useEffect(() => {
    setState(helperState[name] ? helperState[name] : false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    conditionalSetValue();
    return () => {
      setHelperState((prevState) => {
        return { ...prevState, [name]: state };
      });
    };
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];

    const inputState = formState ? formState.data[name] : false;

    setValue(name, inputState, true);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  useEffect(() => {
    
    getInputData({ 
      name: name, 
      formSchemaType, 
      formSchemaVersion, 
      inputData,
      callback: data => setInputInformations(data),
    });
  // eslint-disable-next-line
  }, [getInputData]);

  return (
    <>
      <Styled.LabelWrapper>
        <FormControlLabel
          control={<Switch checked={state} name={name} onChange={onChange} data-testid={`${name}-switch`} disabled/>}
          label={label}
          disabled={disabled}
        />
        {popoverSwitch && <Popover name={name + "Switch"} classes={classes} formSchemaVersion={formSchemaVersion} formSchemaType={formSchemaType} />}
      </Styled.LabelWrapper>

      {state && (
        <Styled.SelectWrapper>
          <Controller
            as={<Select />}
            data={inputInformations}
            label={selectLabel}
            name={name}
            control={control}
            className={classes.select}
            formHelperText={errors[name] && errors[name].message}
            error={!!errors[name]}
            defaultValue=""
            value=""
            data-testid={name}
            disabled={disabled}
          />

          <Styled.PopoverWrapper error={errors[name]}>
            {popover && <Popover name={name} classes={classes} formSchemaVersion={formSchemaVersion} formSchemaType={formSchemaType} />}
          </Styled.PopoverWrapper>
        </Styled.SelectWrapper>
        
      )}
    </>
  );
};

export default SwitchMultSelection;
