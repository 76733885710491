import React from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { AuthProvider } from "./context/auth";
import { UserProvider } from "./context/user";
import { NotificationProvider } from "./context/notification";
import Routes from "./routes";
import { LoadingOverlay } from "components/LoadingOverlay";
import { LoginModal } from "components/LoginModal";

import { createGlobalStyle } from "styled-components";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#455a64",
    },
    secondary: {
      main: "#f51757",
    },
  },
});

const GlobalStyle = createGlobalStyle`
  @media (min-width: 1200px) {
    body {
      overflow-x: hidden;
    }
  }
`;

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <NotificationProvider>
          <ThemeProvider theme={theme}>
            <LoadingOverlay />
            <LoginModal />
            <Routes />
            <GlobalStyle />
          </ThemeProvider>
        </NotificationProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;