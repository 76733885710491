import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
`;

export const LoadingWrapper = styled.div`
  visibility:  ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
  display: flex;
  align-items: center;
  padding: 0 10px 0 25px;
  margin-left: -65px;
`;
