import React, { useEffect } from "react";
import InputAutocomplete from "./InputAutocomplete";
import api from "../../../services";
import { useHide } from "./useHide";

const getData = async (name, isCancelled, setData) => {
  try {
    const { data: { data } } = await api.get(`devices/companies?typeName=${encodeURIComponent(name)}`);
    !isCancelled && setData(data);
  } catch (error) {}
};

const useWatch = (props, watch, setData) => {
  const inputWatch = watch(props.watchFor);

  useEffect(() => {
    let isCancelled = false;
    const check = props.noWatch ? !props.noWatch : inputWatch === undefined || inputWatch === "";
    if(check) return;
    getData(props.noWatch ? props.deviceTypeName : inputWatch, isCancelled, setData);
  
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [inputWatch]);
}

const InputAutocompleteCompany = (props) => {
  const { fieldArrayName, watchForHide, watchHideValues } = props;
  const hide = useHide({ fieldArrayName, watchForHide, watchHideValues });

  if(hide) return null;
  
  return <InputAutocomplete
    getData={getData}
    useWatch={useWatch}
    {...props}
  />
};

export default InputAutocompleteCompany;