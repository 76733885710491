import React from "react";
import { useFormContext } from "react-hook-form";
import * as Styled from "./style";
import { useTheme } from '@material-ui/core/styles';



const InputTextArea = props => {
  const { name, label, placeholder, disabled } = props;
  const { register } = useFormContext();
  const theme = useTheme();

  return (
    <Styled.TextareaWrapper>
      <Styled.Label htmlFor={name}>{label}</Styled.Label>
      <Styled.Textarea name={name} placeholder={placeholder} ref={register} primary={theme.palette.primary} disabled={disabled}/>
    </Styled.TextareaWrapper>
  )
}

export default InputTextArea;