import React, { useEffect, useState } from "react";

import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from "@material-ui/icons/Menu";
import BallotIcon from '@material-ui/icons/Ballot';
import SearchIcon from '@material-ui/icons/Search';

import { Link, useLocation } from "react-router-dom";

import { SubList } from "./SubList";
import api from "../../services";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      background: "#455A64",
    },
    background: "#455A64",
    top: '10px',
    left: '10px',
    right: '5px',
    width: 'calc(100% - 20px)',
    borderRadius: '5px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    borderRadius: '12px',
    margin: '12px',
    height: 'calc(100% - 24px)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    margin: "auto",
    display: "block"
  }
}));

const REPORTS = [
  {
    type: 'medical-center-profile',
    label: 'Perfil Centro'
  },
  {
    type: 'biannual-annual',
    label: 'Semestral/Anual'
  },
];

function SideBar({ container, title, renderButton }) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setMenuIsOpen(false);
  }, [location.pathname]);
  
  const handleDrawerToggle = () => {
    setMenuIsOpen(prev => !prev);
  };

  useEffect(() => {
    api.get('/register-type')
    .then(({data : {data}}) => {
      setTypes(data.filter(({ type }) => type === 1));
    });
  }, [])

  const sideBarDictonary = {
    Dashboard: {
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    "Novo Registro": {
      icon: <AssignmentIcon />,
      link: "/new-register",
      component: <SubList key={'register-types'} listItems={types} urlPrefix='/new-register' title="Novo Registro" />
    },
    Registros: {
      icon: <BallotIcon />,
      link: "/registers",
    },
    Rascunhos: {
      icon: <BallotIcon />,
      link: "/drafts", 
    },
    // "Busca Avançada": {
    //   icon: <SearchIcon />,
    //   link: '/advanced-search'
    // },
    // "Relatórios": {
    //   icon: <AssessmentIcon />,
    //   link: '/dashboard',
    //   component: <SubList key={'reports'} listItems={REPORTS} urlPrefix='/reports' title="Relatórios" />
    // },
    // Cadastros: {
    //   icon: <BallotIcon />,
    //   link: "/configs",
    // },
    // "Minha Conta": {
    //   icon: <AccountBoxIcon />,
    //   link: "myaccount",
    // },
    Sair: {
      icon: <ExitToAppIcon />,
      link: "/logout",
    },
  };
  

  const drawer = (
    <div>
      <div className={classes.toolbar} style={{ display: 'flex' }}>
        <img className={classes.logo} src={require('../../logo-rebrave.png')} alt="Logo" />
      </div>
      <Divider />
      <List>
        {Object.keys(sideBarDictonary).filter(key => !['Minha Conta','Sair'].includes(key)).map(
          (text, index) => (

            sideBarDictonary[text].component
            ? sideBarDictonary[text].component 
            : (
              <Link
                to={location => {
                  const { pathname } = location;
                  const url = sideBarDictonary[text].link;
                  return pathname.includes(url) ? location : url;
                }}
                key={sideBarDictonary[text].link}
                style={{color: 'inherit', textDecoration: 'none'}}
              >
                <ListItem button key={text}>
                  <ListItemIcon>{sideBarDictonary[text].icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Link>
            )
            
          )
        )}
      </List>
      <Divider />
      <List>
        {["Sair"].map((text, index) => (
          <Link
            to={sideBarDictonary[text].link}
            key={sideBarDictonary[text].link}
            style={{color: 'inherit', textDecoration: 'none'}}
          >
            <ListItem button key={text}>
              <ListItemIcon>{sideBarDictonary[text].icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          {renderButton && renderButton()}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={menuIsOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
            
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="temporary"
            open={menuIsOpen}
            onClose={handleDrawerToggle}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default SideBar;
