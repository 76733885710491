import { useUser } from "context/user";

const USERS_DEMO = [
  '2ec4f95f-2836-442f-b4e7-c252fe8fbbcc',
  '478ec650-def0-439e-8798-640019ce9270',
  '478ec650-def0-439e-8798-640019ce9270',
];

export const useIsDemoUser = () => {
  const { userInfos: { userId } } = useUser();
  
  return USERS_DEMO.includes(userId);
};
