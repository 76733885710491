import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PopoverWrapper =  styled.div`
  display: flex;
  width: 50px;
  align-self: center;
  justify-content: initial;
  padding-left: 5px;
  margin-bottom: 14px;
`