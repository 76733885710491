import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
`

export const PopoverWrapper =  styled.div`
  display: flex;
  width: 50px;
  justify-content: center;
  align-self: center;
  margin-bottom: ${({error}) => error && "-4px"};
  position: relative;
  top: 17px;
`
