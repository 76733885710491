import React, { useState } from "react";

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from "@material-ui/icons/Assignment";
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const SubList = React.memo(props => {
  const { listItems, urlPrefix, title } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const handleClick = () => setOpen(open => !open);


  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems && listItems.map(({ type, label }, index) => (
            <Link 
              to={location => {
                const { pathname } = location;
                const url = `${urlPrefix}/${type}`;
                return pathname.includes(url) ? location : url;
              }}
              key={`/new-register/${type}`}
              style={{color: 'inherit', textDecoration: 'none'}}
              >
                <ListItem button key={label} className={classes.nested}>
                  <ListItemIcon style={{ minWidth: '46px' }}><FiberManualRecordIcon style={{ fontSize: '1rem' }} /></ListItemIcon>
                  <ListItemText primary={label} style={{ margin: 0 }} />
                </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  )
});