import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  container: {
    maxHeight: 170,
  },
  quantity: {
    fontSize: 12,
  },
  resultTh: {
    minWidth: 116,
  }
}));