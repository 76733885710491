import React, { useState, useEffect, useContext } from 'react';

import { useFormContext } from "react-hook-form";
import { CircularProgress } from '@material-ui/core';

import api from 'services';
import { timeout, convertDateToDmy } from 'helpers';
import InputTextMaskedControlled from '../../Masked/Controlled';
import FormRegisterInfo from "components/context/form-context";

import * as Styled from './style';

function InputTextSearchCpf(props) {
  const [ status, setStatus ] = useState('idle');
  const { watch, setValue } = useFormContext();
  const { state: globalFormState } = useContext(FormRegisterInfo);

  const thisInput = watch(props.name);

  const isLoading = status === 'loading';
  const hasError = status === 'error';

  const fetcher = (cpf) => api.get(`/patients/${cpf}`);

  useEffect(() => {
    if (!thisInput || props.disabled) return;
    if (globalFormState.PatientInfos?.data?.cpf === thisInput) return;
    
    const isValidCpf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(thisInput);

    if (!isValidCpf) return;

    setStatus('loading');

    Promise.all([
      fetcher(thisInput),
      timeout(1000),
    ])
    .then(([{ data = {} }]) => {
      const { name, birthday, sex, race, height, weight } = data;

      setValue([
        { name: name ?? '' },
        { birthDate: birthday ? convertDateToDmy(birthday) : '' },
        { sexes: sex ?? '' },
        { races: race ?? '' },
        { height: height ?? '' },
        { weight: weight ?? '' },
      ]);

      setStatus('idle');
    })
    .catch(() => {
      setStatus('error');
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ thisInput, setValue ]);

  return (
    <Styled.Container>
      <Styled.InputWrapper>
        <InputTextMaskedControlled
          {...props}
          disabled={props.disabled || isLoading}
          helperText={hasError && 'Ops, algo deu errado'}
        />
      </Styled.InputWrapper>
      <Styled.LoadingWrapper isLoading={isLoading}>
        <CircularProgress size={30} />
      </Styled.LoadingWrapper>
    </Styled.Container>
  );
};

export default InputTextSearchCpf;
