import React, { createContext, useContext, useState, useEffect } from "react";

import useSWR from 'swr';

import { identifyUsersLogRocket } from "services/Logging";
import { swrFetcher } from "services"
import { parseJwt } from "helpers";

const UserContext = createContext();

const UserProvider = (props) => {
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  const [ userInfos, setUserInfos ] = useState({});
  const { data, error } = useSWR('/user/role', swrFetcher);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return;

    const { name, email, id: userId } = parseJwt(userToken);
    setUserInfos({
      name,
      email,
      userId,
    });
  }, []);

  useEffect(() => {
    if (data) {
      setUserInfos(prev => ({ ...prev, role: data.role }));
      setIsLoggedIn(true);
    }
    if (error) console.error(error);
  }, [ data ]);
  
  useEffect(() => {
    const isEmpty = Object.keys(userInfos).length < 1;
    if(isEmpty) return;

    const { userId, name, email } = userInfos;
    identifyUsersLogRocket(userId, name, email);
  }, [userInfos])

  const setLoggedIn = (param = false) => { 
    setIsLoggedIn(param);
  }

  return (
    <UserContext.Provider
      value={{ userIsLogged: isLoggedIn, setUserLogged: setLoggedIn, setUserInfos, userInfos }}
      {...props}
    />
  );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
