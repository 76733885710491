import styled from 'styled-components';

export const ListItem = styled.li`
  list-style: none;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`


