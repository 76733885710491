export default {
  "proceduresByHospitalType": [
    {
        "type": "privado",
        "count": "20"
    },
    {
        "type": "publico",
        "count": "10"
    },  
    {
        "type": "academico",
        "count": "5"
    },
  ],
  "proceduresByHospital": [
    {
        "hospital": 3,
        "count": "30"
    },
    {
        "hospital": "Centro",
        "count": "11"
    },
    {
        "hospital": 1,
        "count": "22"
    },
    {
        "hospital": 2,
        "count": "14"
    }, 
  ],
  "ischemicInfos": {
      "rows": [
          {
              "cells": [
                  {
                      "text": "Número de procedimentos com isquemia crítica"
                  },
                  {
                      "text": "10"
                  },
                  {
                      "text": "12"
                  },
                  {
                      "text": "--"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Média do tempo de internação pré procedimento em pacientes com isquemia crítica"
                  },
                  {
                      "text": "4"
                  },
                  {
                      "text": "5"
                  },
                  {
                      "text": "< 5 dias¹"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Porcentagem de procedimentos em isquemia crítica realizados em < 5 dias da internação"
                  },
                  {
                      "text": "78%"
                  },
                  {
                      "text": "88%"
                  },
                  {
                      "text": "> 80%"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Porcentagem de procedimentos em pacientes com dor ao repouso em até 5 dias da internação"
                  },
                  {
                      "text": "88%"
                  },
                  {
                      "text": "90%"
                  },
                  {
                      "text": "> 80%"
                  }
              ]
          }
      ]
  },
  "ischemicChart": [
    {
        "hospital": 3,
        "percentage": "83"
    },
    {
        "hospital": 1,
        "percentage": "92"
    },
    {
        "hospital": "Centro",
        "percentage": "78"
    },
    {
        "hospital": 2,
        "percentage": "74"
    },
  ],
  "femoralPunctureInfos": {
      "rows": [
          {
              "cells": [
                  {
                      "text": "Número de procedimentos"
                  },
                  {
                      "text": "20"
                  },
                  {
                      "text": "15"
                  },
                  {
                      "text": "--"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Porcentagem de punções femorais ecoguiadas"
                  },
                  {
                      "text": "100%"
                  },
                  {
                      "text": "95%"
                  },
                  {
                      "text": "100% ²"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Porcentagem de pseudoaneurisma em femorais"
                  },
                  {
                      "text": "1%"
                  },
                  {
                      "text": "2%"
                  },
                  {
                      "text": "< 1%"
                  }
              ]
          },
          {
              "cells": [
                  {
                      "text": "Porcentagem de procedimentos em pacientes com dor ao repouso em até 5 dias da internação"
                  },
                  {
                      "text": "2%"
                  },
                  {
                      "text": "3%"
                  },
                  {
                      "text": "< 3%³"
                  }
              ]
          }
      ]
  },
  "femoralPunctureChart": [
    {
        "hospital": 3,
        "percentage": "70.00"
    },
    {
        "hospital": 1,
        "percentage": "50.00"
    },
    {
        "hospital": 2,
        "percentage": "98.00"
    },
    {
        "hospital": "Centro",
        "percentage": "50.00"
    },
  ]
};
