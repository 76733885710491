import React, { useState, useEffect, useContext } from "react";

import { FormControlLabel, Switch } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import FormRegisterInfo from "../../context/form-context";
import ComponentType from "../../componentType";

const SwitchMultInputs = (props) => {
  const {
    inputType,
    name,
    label,
    conditionalSetValue = () => {},
    disabled,
  } = props;
  const [state, setState] = useState(() => false);

  const { setValue } = useFormContext();

  const { state: globalFormState, helperState, setHelperState } = useContext(
    FormRegisterInfo
  );

  const onChange = () => {
    setState((prevState) => !prevState);
  };

  useEffect(() => {
    setState(helperState[name] ? helperState[name] : false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    conditionalSetValue();
    setHelperState((prevState) => {
      return { ...prevState, [name]: state };
    });
    
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];

    const inputState = formState ? formState.data[name] : false;

    setValue(name, inputState, true);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  return (
    <>
      <FormControlLabel
        control={<Switch checked={state} name={name} onChange={onChange} disabled={disabled}/>}
        label={label}
      />

      {state && (
        <ComponentType
          type={inputType}
          {...props}
        />
      )}
    </>
  );
};

export default SwitchMultInputs;
