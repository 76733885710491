import React, { useMemo, useState } from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import MainContainer from '../../components/mainContainer';
import SideBar from '../../components/sideBar';
import { SearchFields, SearchFieldsTitle, SelectedFieldsList } from '../../domain/AdvancedSearch';
import { Filters } from '../../domain/AdvancedSearch/Filters';
import { Table } from '../../domain/AdvancedSearch/Table';
import {
  FIELDLIST_DATA,
  FILTER_TYPES,
  FILTERABLE_FIELDS,
  OPERATORS,
  CALCULATION_TYPES,
} from './MOCs';
import api from '../../services';

import useStyle from './style';

const generateFilterObject = () => [uuidv4(), {
  field: '',
  operator: '',
  values: '',
}];

const AdvancedSearch = () => {
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ searchableFields, setSearchableFields ] = useState({});
  const [ filterFields, setFilterFields ] = useState(new Map([generateFilterObject()]));
  const [ result, setResult  ] = useState([]);
  const classes = useStyle();

  const noSearchableFields = Object.keys(searchableFields).length < 1;

  const selectedFields = useMemo(() => {
    return Object.entries(searchableFields)
      .filter(([ _, { checked } ]) => checked)
      .map(([ name, { label }]) => ({
        name,
        label
      }));
  }, [ searchableFields ]);

  const handleInputChange = (name, label, checked) => {
    setSearchableFields((prev) => ({ ...prev, [name]: {
      label,
      checked,
    } }))
  };

  const handleDeleteAllFields = () => {
    setSearchableFields({});
  };

  const handleAddFilterFields = () => {
    setFilterFields(prev => new Map([ ...prev, generateFilterObject() ]));
  };

  const handleDeleteFilter = (uuid) => {
    setFilterFields(prev => {
      const newState = new Map(prev);
      newState.delete(uuid)
      return newState;
    })
  };

  const handleFilterChange = (uuid, state) => {
    setFilterFields(prev => new Map(prev).set(uuid, state))
  };

  const handleSubmit = () => {
    const removeTimeFromISODate = ({ field, operator, values }) => {
      const isDateField = field[0] === 'data-procedimento';
      return {
        field,
        operator,
        values: isDateField
          ? values.map(date => date.toISOString()?.match(/^\d{4}-\d{2}-\d{2}/)[0])
          : values
        }
    }

    const fields = selectedFields.map(({name}) => name);
    const filters = [...filterFields.entries()]
      .map(([ key, values ]) => values)
      .filter((object) => Object.values(object).every(Array.isArray))
      .map(removeTimeFromISODate);

    api.post('/register/search', {
      fields,
      filters,
    })
    .then(({ data: { data } }) => data)
    .then(data => {
      const serialize = ({ results, calculationType }) => results.reduce((acc, obj) => ({
        total: acc.total + Number(obj.count),
        rows: [
          ...acc.rows,
          {
            value: obj.item,
            result: calculationType === 'percentage' ? `${obj.percentage} %` : `${obj.average} x̅`,
            count: obj.count
          }]
      }), { rows: [], total: 0 });

      const incluseAllOptionsAndTranslate = ({ base, compareTo }) => base.map(({ label, value }) => {
        const hasValue = compareTo.find(obj => value === obj.value);

        return {
          value: label,
          result: hasValue ? hasValue.result : '0.00 %',
          count: hasValue ? hasValue.count : 0,
        }

      });
      
      const newTables = data.map(({ field, result }) => {
        const { calculationType, label, multipleValues } = CALCULATION_TYPES[field];
        const { rows, total } = serialize({ calculationType, results: result });

        const transformedRows = multipleValues 
          ? (
            incluseAllOptionsAndTranslate({ 
              base: FILTERABLE_FIELDS[field].values, 
              compareTo: rows,
            })
          ) 
          : rows

        return {
          label,
          rows: transformedRows,
          total,
        }
      });

      setResult(newTables);
    });
  }

  return (
    <MainContainer overflow='auto'>
      <SideBar title="Busca Avançada" />
      <Grid container classes={{ root: classes.inputsContainer }}>
        <Grid item xs={12} classes={{ root: classes.wrapper }}>
          <Card classes={{ root: classes.card }}>
            <SearchFieldsTitle 
              onAdd={() => setModalIsOpen(true)}
              onRemove={handleDeleteAllFields}
            />
            <SelectedFieldsList 
              fields={selectedFields}
              onDelete={(name, label) => handleInputChange(name, label, false)}
            />
            <SearchFields 
              data={FIELDLIST_DATA}
              open={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              formState={searchableFields}
              onInputChange={handleInputChange}
            />
          </Card>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.wrapper }}>
          <Card classes={{ root: classes.card }}>
            <Filters 
              fields={FILTERABLE_FIELDS}
              fieldsTypes={FILTER_TYPES}
              operators={OPERATORS}
              onAdd={handleAddFilterFields}
              filterFields={filterFields}
              onDelete={handleDeleteFilter}
              onChange={handleFilterChange}
            />
          </Card>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.wrapper }}>
          <Button
            color="secondary"
            variant="contained"
            size="medium"
            classes={{ root: classes.button }}
            disabled={noSearchableFields}
            onClick={handleSubmit}>
            Pesquisar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {result.map(({ label, rows, total }) => (
          <Grid item xs={12} md={6} lg={4} key={label}>
            <Table 
              label={label}
              rows={rows}
              total={total}
            />
          </Grid>
        ))}
      </Grid>
    </MainContainer>
  );
};

export default AdvancedSearch;
