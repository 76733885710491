import React, { useEffect } from "react";
import InputTextMasked from "../index";
import { useFormContext } from "react-hook-form";
import * as Styled from "./style";
import Popover from "../../../../popover";

import { getObjectByStringPath } from "../../../../../helpers"

export default function InputTextMaskedControlled(props) {
  const {
    label,
    name,
    Controller,
    classes,
    options,
    defaultValue,
    popover,
    disabled,
    invisible,
    conditionalSetValue = () => {},
    fieldArrayChildName,
    helperText = '',
    ...rest
  } = props;
  const { control, errors } = useFormContext();

  useEffect(() => {
    conditionalSetValue();
    // eslint-disable-next-line
  }, []);

  const errorObject = getObjectByStringPath(errors, name);

  return (
    <Styled.InputTextMaskedWrapper invisible={invisible}>
      <Controller
        as={
          <InputTextMasked
            label={label}
            id={name}
            fullWidth
            options={options}
            className={classes.creatinina}
            helperText={errorObject?.message || helperText}
            error={!!errorObject}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <Styled.PopoverWrapper error={errorObject}>
        {popover && <Popover name={fieldArrayChildName ?? name} classes={classes} {...rest} />}
      </Styled.PopoverWrapper>
    </Styled.InputTextMaskedWrapper>
  );
}
