import React, { useState, useEffect, useContext } from "react";

import { FormControlLabel, Switch } from "@material-ui/core";

import Radio from "../../Radio";
import Popover from "../../popover";

import { useFormContext } from "react-hook-form";

import * as Styled from "./style";

import FormRegisterInfo from "../../context/form-context";

import { getInputData } from "../../../helpers"

export default function SwitchMultRadio(props) {
  const { classes, name, label, Controller, popover, conditionalSetValue = () => {}, disabled, formSchemaVersion, formSchemaType, inputData } = props;
  const { control, errors, setValue } = useFormContext();

  const { state: globalFormState, helperState, setHelperState } = useContext(
    FormRegisterInfo
  );

  const [state, setState] = useState(false);
  const [inputInformations, setInputInformations] = useState([]);

  const onChange = () => {
    setState(!state);
  };

  useEffect(() => {
    setState(helperState[name] ? helperState[name] : false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    conditionalSetValue();
    return () => {
      setHelperState((prevState) => {
        return { ...prevState, [name]: state };
      });
    };
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];

    const inputState = formState ? formState.data[name] : false;

    setValue(name, inputState, true);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  useEffect(() => {
    
    getInputData({ 
      name: name, 
      formSchemaType, 
      formSchemaVersion, 
      inputData,
      callback: data => setInputInformations(data),
    });
  // eslint-disable-next-line
  }, [getInputData]);

  return (
    <Styled.RadioWrapper>
      <FormControlLabel
        control={<Switch checked={state} name={name} onChange={onChange} data-testid={`${name}-switch`} disabled={disabled}/>}
        label={label}
      />

      {popover && (
        <Popover name={name} classes={classes} formSchemaType={formSchemaType} formSchemaVersion={formSchemaVersion} />
      )}

      {state && (
        <Styled.RadioInputWrapper>
          <Controller
            as={<Radio />}
            data={inputInformations}
            style={{
              FormControl: {
                margin: "2px 0 5px",
              },
            }}
            name={name}
            control={control}
            className={classes.radioGroup}
            formHelperText={errors[name] && errors[name].message}
            error={!!errors[name]}
            defaultValue=""
            value=""
            data-testid={name}
            disabled={disabled}
          />
        </Styled.RadioInputWrapper>
      )}
    </Styled.RadioWrapper>
  );
}
