import React from "react";
import Buttons from "./Buttons";

const RegisterButtons = (props) => {
  const { setAction, triggerValidation } = props;

 
  const handleUpdate = () => {
    setAction("registerUpdate");
    triggerValidation();
  };


  return (
    <Buttons
      firstButtonText="Atualizar Seguimento"
      firstButtonOnClick={handleUpdate}
    />
  );
};

export default RegisterButtons;