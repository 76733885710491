import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useHide = ({ fieldArrayName, watchForHide, watchHideValues = [] }) => {
  const [hide, setHide] = useState(false);
  const { watch } = useFormContext();

  const watchedForHideInput = watch(`${fieldArrayName}[${watchForHide}]`);

  useEffect(() => {
    if(watchHideValues.includes(watchedForHideInput)) {
      setHide(true)
      return;
    }

    setHide(false)
  }, [watchedForHideInput, watchHideValues])

  return hide;
};
