import React, { useEffect } from "react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";

export const Logout = () => {
  const { setAuthTokens, setTokenIsValid } = useAuth();

  useEffect(() => {
    setAuthTokens();
    setTokenIsValid(false);
    // eslint-disable-next-line
  }, []);

  return <Redirect to="/login" />;
};
