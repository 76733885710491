import React from "react";
import Buttons from "./Buttons";

const DraftButtons = (props) => {
  const { setAction, triggerValidation, draftId } = props;

 
  const handleDraft = () => {
    setAction(draftId ? "draftUpdate" : "draftCreate");
    triggerValidation();
  };

  const handleSave = () => {
    setAction("draftFinish");
    triggerValidation();
  };

  return (
    <Buttons
      firstButtonText="Salvar Rascunho"
      firstButtonOnClick={handleDraft}
      lastButtonText="Finalizar"
      lastButtonOnClick={handleSave}
    />
  );
};

export default DraftButtons;