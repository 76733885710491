import { useState, useEffect, useContext } from "react";

import { getInputData } from "helpers";
import FormRegisterInfo from "components/context/form-context";

export const useGetPopoverData = ({ name, hasText, formSchemaType, formSchemaVersion }) => {
  const [popoverData, setPopoverData] = useState([]);
  const { inputsData } = useContext(FormRegisterInfo);

  useEffect(() => {
    if (!hasText) return;

    const localPopoverData = inputsData?.[`${name}Help`];

    if (localPopoverData) {
      setPopoverData(localPopoverData);
      return;
    }
    
    getInputData({ 
      name: `${name}Help`, 
      formSchemaType, 
      formSchemaVersion,
      callback: data => setPopoverData(data)
    });

    // eslint-disable-next-line
  }, [inputsData, getInputData]);

  return popoverData;
};