import React, { useContext, useEffect, useState } from "react";
import FormRegisterContext from "../context/form-context";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core";

import StepIcon from "./StepIcon";

const useStyles = makeStyles({
  root: {
    width: '100%',

    '& .MuiStepConnector-vertical': {
      marginLeft: '9px',
      padding: '4px 0 8px',
    },
  },
});

const useStyles2 = makeStyles((theme) => ({
  label: {
    display: "flex",
    
    "&$error": {
        color: "rgba(0, 0, 0, 0.54)",

        "&$active": {
          color: theme.palette.warning.main,
          borderBottom: "2px solid",
          borderColor: theme.palette.warning.main,
        }

      },
      
    "&$completed": {
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.54)",

      "&$active": {
        fontWeight: 500,
        color: theme.palette.success.main,
      }
      
    },

    "&$active": {
      borderBottom: "2px solid",
      color: "#F51757",
    }
  },
  completed: {},
  error: {},
  active: {}
}));

const SubStepper = (props) => {
  const {
    state,
    setActualSubStep,
    actualSubStep,
    stepKey,
    subStepLabel,
    setSubStepLabel,
    subStepComplete,
    schema
  } = useContext(FormRegisterContext);
  
  useEffect(() => {
    if(!stepKey) return;

    const subStepLabels = schema[stepKey].children.reduce((array, {schemaName, title}) => {
      return [...array, { parent: stepKey, schemaName, title }]
    }, []);

    setSubStepLabel((prev) => {
      return { ...prev, [stepKey]: subStepLabels };
    });
  // eslint-disable-next-line
  }, [stepKey, setSubStepLabel]);

  const handleSubStepClick = (key, schemaName) => {
    const isDoubleClick = schemaName === state.activeForm;
    if(isDoubleClick) return;
    setActualSubStep({ index: key });
  };

  const classes = useStyles();
  const classes2 = useStyles2();

  const [localState, setLocalState] = useState([]);

  useEffect(() => {
    subStepLabel[stepKey] && setLocalState(subStepLabel[stepKey]);
  // eslint-disable-next-line
  }, [subStepLabel]);

  return (
    <Stepper
      classes={classes}
      activeStep={actualSubStep.index}
      orientation="vertical"
    >
      {localState.map(({ schemaName, title, parent }, index) => {
        const isCompleted = subStepComplete
          .get(parent)
          ?.get("completed")
          .get(schemaName);
        const hasErrors = subStepComplete
          .get(parent)
          ?.get("errors")
          .get(schemaName);

        return (
          <Step
            onClick={() => handleSubStepClick(index,schemaName)}
            key={schemaName}
            completed={isCompleted}
            data-testid={schemaName}
          >
            <StepLabel error={hasErrors} StepIconComponent={StepIcon} classes={classes2}>
              {title}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default SubStepper;
