import React from 'react';

import { Box } from '@material-ui/core';
import { add, getMonth } from "date-fns";

import { DatePicker } from 'components/DatePicker';

function RangeDatePicker({ startDate, endDate, onChangeStartDate, onChangeEndDate }) {
  const isBeginOrHalfOfYear = (date) => {
    const startDateMonth = getMonth(date);
    return startDateMonth === 0 || startDateMonth === 6;
  };

  const allowedEndDates = getMonth(startDate) === 0 ? [
    add(startDate, { months: 5 }), add(startDate, { months: 11 })
  ] : [
    add(startDate, { months: 5 })
  ];

  const handleStartDateChange = (date) => {
    onChangeStartDate(date);
    onChangeEndDate(null);
  };

  return (
    <Box>
      <Box mb={1}>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          filterDate={isBeginOrHalfOfYear}
          showMonthYearPicker
          dateFormat="MMMM, yyyy"
        />
      </Box>
      <DatePicker
        selected={endDate}
        onChange={onChangeEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        showMonthYearPicker
        dateFormat="MMMM, yyyy"
        includeDates={allowedEndDates}
      />
    </Box>
  );
}

export default RangeDatePicker;