import React, { createContext, useState, useContext, useEffect } from 'react';
import { Notifications } from 'components/Notifications';

export const NotificationContext = createContext();

function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([])
  
  const notifyError = message => setNotifications((notifications) => [{ message, type: 'error' }, ...notifications]);
  const notifyInfo = message => setNotifications((notifications) => [{ message, type: 'info' }, ...notifications]);
  const notifySuccess = message => setNotifications((notifications) => [{ message, type: 'success' }, ...notifications]);
  const notifyWarning = message => setNotifications((notifications) => [{ message, type: 'warning' }, ...notifications]);

  const value = { notifyError, notifyInfo, notifySuccess, notifyWarning };
    
  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Notifications
        data={notifications}
        setNotifications={setNotifications}
      />
    </NotificationContext.Provider>
  )
}

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
