import React from 'react';
import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Chip } from '@material-ui/core';

import useStyles from './style';

const Table = ({ label, rows, total }) => {
  const classes = useStyles();

  return (
    <Paper>
    <Box p={2} height={250}>
      <Chip
        label={label}
        style={{
          margin: '0 0 8px',
        }}
      />
      <TableContainer classes={{ root: classes.container }}>
        <TableMui size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Valor</TableCell>
              <TableCell align="right" classes={{ root: classes.resultTh }}>
                Resultado <small className={classes.quantity}>({total})</small>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.value}>
                <TableCell component="th" scope="row">
                  {row.value}
                  
                </TableCell>
                <TableCell align="right">
                  {row.result} <small className={classes.quantity}>({row.count})</small>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableMui>
      </TableContainer>
    </Box>
    </Paper>
  );
}

export default Table;