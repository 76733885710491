import React from "react";
import TextField from "@material-ui/core/TextField";
import Cleave from "cleave.js/react";

const CleaveInput = props => {
  const { inputRef, ...other } = props;

  return <Cleave {...other} htmlRef={ref => inputRef(ref)} />;
}

const InputTextMasked = props => {
  const { options, ...rest } = props;
  return (
    <TextField
      InputProps = {{
        inputComponent: CleaveInput,
        inputProps: {
          options,
          "data-testid": props.name
        }
      }}
      variant="outlined"
      {...rest}
    />
  );
}

export default InputTextMasked;
