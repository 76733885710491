import React from 'react';
import { Grid, Divider, Typography, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import RoundedButton from './RoundedButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexBasis: 'auto',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    display: 'inline-block',
    marginRight: 6,
    paddingLeft: 6,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  removeButton: {
    marginLeft: 'auto',
  },
  addButtonDesktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  addButtonMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  }
}));

const SearchFieldsTitle = ({ onAdd, onRemove }) => {

  const classes = useStyles();

  return (
    <Grid item xs={12} classes={{ root: classes.wrapper }}>
      <Typography classes={{ root: classes.title }}>
        Quais campos você quer ?
      </Typography>
      <RoundedButton onClick={onAdd} classes={{ root: classes.addButtonDesktop }}>
        <AddIcon />
      </RoundedButton>
      <Button
        onClick={onAdd}
        color="default"
        variant="contained"
        size="medium"
        classes={{ root: classes.addButtonMobile }}
      >
        + campo
      </Button>
      <Button
        color="default"
        variant="contained"
        classes={{ root: classes.removeButton }}
        size="medium"
        onClick={onRemove}
      >
        Remover todos
      </Button>
      <Divider classes={{ root: classes.divider }}/>
    </Grid>
  );
}

export default SearchFieldsTitle;