import React from 'react';
import {
  Typography,
  Box,
} from '@material-ui/core';

import useStyles from './style';

const Description = props => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant='h5'
        classes={{ root: classes.title }}
      >
        Intervenção Vascular Periférica no Período: {props.startDate} a {props.endDate}
      </Typography>

      <Typography
        variant='caption'
        classes={{ root: classes.italic }}
        display='block'
      >
        Apenas para casos eletivos
      </Typography>

      <Box marginY={2}>
        <Typography variant='body2' classes={{ root: classes.text }} gutterBottom>
          Na tabela abaixo, as porcentagens representam as taxas observadas dos desfechos
          ou complicações. O resultado estará em destaque em vermelho se estiver abaixo do percentil
          25 (Quartil inferior) de todas as variações dos outros centros ou médicos no Registro,
          e em verde se estiver acima do percentil 75 (Quartil superior).
        </Typography>
        <Typography variant='body2' classes={{ root: classes.text }} gutterBottom>
          Se o resultado estiver entre os percentis 25 e 75, os resultados não terão destaque.
          Note que os percentis são baseados nas taxas individuais dos centros ou médicos,
          assim é possível que uma taxa individual esteja abaixo da taxa média total do registro mas
          ainda assim, acima do percentil 75.
        </Typography>
        <Typography variant='body2' classes={{ root: classes.text }}>
              Note, também, que os percentuais são computados apenas para os casos com dados presentes
          para cada desfecho, sendo assim possível ter taxa para um desfecho, mas “sem casos” para
          outros. Dados regionais ou nacionais são suprimidos, se houver menos de 3 centros
          participando do registro.
        </Typography>
      </Box>

      <Typography
        variant='caption'
        display='block'
        classes={{ root: classes.legend }}
      >
        <span>Legenda:  </span>
        <span className={classes.greenBackground}>
          Verde = Quarto Quartil (acima do percentil 75)
        </span>
        <span> e </span>
        <span className={classes.redBackground}>
          Vermelho = Primeiro Quartil (abaixo do percentil 25)
        </span>
        <span> (em relação ao Nacional)</span>
      </Typography>
    </>
  );
};

export default Description;