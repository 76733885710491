import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete } from "./Autocomplete";
import { getObjectByStringPath } from "../../../helpers";
import * as Styled from "./style";
import FormRegisterInfo from "../../context/form-context";
import Popover from "../../popover";


const InputAutoComplete = props => {
  const { name, label, notFoundText, useWatch, disabled, popover, fieldArrayChildName, ...rest } = props;

  const [state, setState] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const { register, unregister, watch, setValue, errors } = useFormContext();
  const { state: globalFormState } = useContext(FormRegisterInfo);

  const self = watch(name);

  useEffect(() => {
    register({ name: [name] });

    return () => {
      unregister(name);
    };
    // eslint-disable-next-line
  }, [register]);

  useWatch(props, watch, setData);

  useEffect(() => {
    const triggerValidation = data.length ? true : false;
    setValue(name, state, triggerValidation);
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    if(!self) return;
    if(!data.length) return;
    const isValidData = data.some(({id}) => id === self.id);
    setState(isValidData ? self : notFoundText);
    setInputValue(isValidData ? self.name : notFoundText);
    // eslint-disable-next-line
  }, [self, setState, data]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];
    const inputState = formState?.data && getObjectByStringPath(formState?.data, name);
    inputState && setState(inputState);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  const handleChange = (e, value) => {
    setState(value ? value : "");
    value === null && setInputValue("");
  }

  const handleInputChange = (event, newInputValue) => {
    newInputValue && setInputValue(newInputValue);
  }

  const getOptionLabel = (option) => {
    return option.name ? option.name : "";
  }

  const getOptionSelected = (option, value) => value;

  const errorObject = getObjectByStringPath(errors, name);

  return(
    <Styled.AutoCompleteWrapper>
    <Autocomplete 
      name={name}
      label={label}
      data={data}
      value={state}
      error={errorObject}
      inputValue={inputValue}
      handleChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      register={register}
      handleInputChange={handleInputChange}
      disabled={disabled}
    />

    <Styled.PopoverWrapper error={!!errorObject}>
      {popover && <Popover name={fieldArrayChildName ?? name} {...rest} />}
    </Styled.PopoverWrapper>
    
    </Styled.AutoCompleteWrapper>
  );
}

export default InputAutoComplete;