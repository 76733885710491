export const COLORS = {
  proceduresByTerritory: {
    'Ao-il': 'hsl(74, 70%, 50%)',
    'Fe-Po': 'hsl(342, 70%, 50%)',
    'Po-Dis': 'hsl(323, 70%, 50%)'
  },
  treatmentByTerritoriesAoil: {
    'Aterectomia': "hsl(323, 70%, 50%)",
    'Balão': "hsl(74, 70%, 50%)",
    'Stent': "hsl(342, 70%, 50%)",
    'Stent Recoberto': "hsl(323, 70%, 50%)"
  },
  treatmentByTerritoriesFePo: {
    'Aterectomia': "hsl(323, 70%, 50%)",
    'Balão': "hsl(74, 70%, 50%)",
    'Stent': "hsl(342, 70%, 50%)",
    'Stent Recoberto': "hsl(323, 70%, 50%)"
  },
  treatmentByTerritoriesPoDis: {
    'Aterectomia': "hsl(323, 70%, 50%)",
    'Balão': "hsl(74, 70%, 50%)",
    'Stent': "hsl(342, 70%, 50%)",
    'Stent Recoberto': "hsl(323, 70%, 50%)"
  },
}
