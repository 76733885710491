import React from 'react';

import { Box, Typography } from '@material-ui/core';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Table from './Table';

import useStyles from './style';

const Container = ({ title, tableRows }) => {
  const classes = useStyles();

  return (
    <Box>
       <Box mt={3} display='flex' alignItems='center'>
        <RadioButtonUncheckedIcon classes={{ root: classes.icon }} />
        <Typography variant='h6' classes={{ root: classes.title }}>
          {title}
        </Typography>
      </Box>
      <Table rowsData={tableRows}/>
    </Box>
  )
};

export default Container;