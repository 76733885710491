import React from 'react'

import { Typography, Divider, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  divider: {
    margin: "13px 0 8px"
  }
}));

function FormHeader({text}) {

  const classes = useStyle();

  return (
    <div>
      <Typography variant='button'>{text}</Typography>
      <Divider className={classes.divider}/>
    </div>
  )
}

export default FormHeader
