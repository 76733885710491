import React, { memo } from 'react';
import { FormControl, InputLabel, Select as SelectMui, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  menuPaper: {
    maxHeight: 300,
  },
});

const Select = memo(({ label, name, state, onChange, children, ...rest }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth size='small'>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <SelectMui
        name={name}
        labelId={`${name}-label`}
        id={label}
        value={state}
        onChange={onChange}
        label={label}
        {...rest}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {children}
      </SelectMui>
    </FormControl>
  )
})

export default Select;

export const DefaultSelect = ({ data = [], ...rest }) => (
  <Select 
    {...rest}
  >
  { data.map(({ label, value }) => (
    <MenuItem key={value} value={value}>{label}</MenuItem>
  )) }
  </Select>
);
