import { makeStyles } from "@material-ui/core/styles";
import { green, red } from '@material-ui/core/colors';


export default makeStyles(theme => ({
  title: {
    color: theme.palette.secondary.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  italic: {
    fontStyle: 'italic',
  },
  greenBackground: {
    background: green['A100'],
  },
  redBackground: {
    background: red['A100']
  },
  legend: {
    fontSize: 11,
  },
  text: {
    fontSize: 13,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
  },
}));