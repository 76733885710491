export const FIELDLIST_DATA = [
  {
    title: 'Infomações do paciente',
    fields: [
      {
        name: 'age',
        label: 'Idade',
      },
      {
        name: 'sexes',
        label: 'Sexo',
      },
      {
        name: 'races',
        label: 'Raça',
      },
      {
        name: 'patientName',
        label: 'Nome',
      },
    ],
  },
  {
    title: 'Comorbidades',
    fields: [
      {
        name: 'doencaCoronariana',
        label: 'Doença Coronariana'
      },
      {
        name: 'insuficienciaCardiaca',
        label: 'Insuficiência Cardíaca'
      },
      {
        name: 'arritmia',
        label: 'Arritmia'
      },
      {
        name: 'dpoc',
        label: 'DPOC'
      },
      {
        name: 'diabetes',
        label: 'Diabetes'
      },
      {
        name: 'dialise',
        label: 'Diálise'
      },
      {
        name: 'hipertensao',
        label: 'Hipertensão'
      },
      {
        name: 'tabagismo',
        label: 'Tabagismo'
      },
      {
        name: 'avc',
        label: 'AVC'
      },
    ],
  },
  {
    title: 'Antecedentes Cirúrgicos',
    fields: [
      {
        name: 'revasc-miocardio',
        label: 'Revasc do miocárdio'
      },
      {
        name: 'angioplastia-coronariana',
        label: 'Angioplastia coronariana'
      },
      {
        name: 'cea-cas-anterior',
        label: 'CEA / CAS anterior'
      },
      {
        name: 'revasc-previa-membro-inferior',
        label: 'Revasc prévia do Membro Inferior'
      },
    ],
  },
  {
    title: 'Status Atual',
    fields: [
      {
        name: 'sintomas',
        label: 'Sintomas'
      },
      {
        name: 'infeccao',
        label: 'Infecção'
      },
      {
        name: 'perda-tecidual',
        label: 'Perda Tecidual'
      },
      {
        name: 'itb-status-atual',
        label: 'ITB Status Atual'
      },
    ],
  },
  {
    title: 'Detalhes do Procedimento',
    fields: [
      {
        name: 'extensao-total-oclusao',
        label: 'Extensão total da oclusão'
      },
      {
        name: 'extensao-total-lesao',
        label: 'Extensão total da lesão'
      },
    ],
  },
  {
    title: 'Dispositivos',
    fields: [
      {
        name: 'dispositivo-tipo',
        label: 'Tipo'
      },
    ],
  },
  {
    title: 'Procedimentos Adicionais',
    fields: [
      {
        name: 'dispositivo-cto',
        label: 'Dispositivo CTO'
      },
      {
        name: 'ivus',
        label: 'IVUS'
      },
    ],
  },
  {
    title: 'Avaliação Final',
    fields: [
      {
        name: 'resultado-tecnico-final',
        label: 'Resultado técnico final'
      },
      {
        name: 'embolizacao',
        label: 'Embolização'
      },
      {
        name: 'disseccao-lesao-alvo',
        label: 'Dissecção'
      },
    ],
  },
  {
    title: 'Informações pós operatório',
    fields: [
      {
        name: 'itb-pos-op',
        label: 'ITB Pós Op'
      },
      {
        name: 'amputacao-durante-internacao',
        label: 'Amputação durante internação'
      },
      {
        name: 'amput-planejada',
        label: 'Amputação planejada'
      },
      {
        name: 'tempo-internacao',
        label: 'Tempo de Internação'
      },
    ],
  },
];

export const OPERATORS = {
  eq: 'é igual (=)',
  ne: 'é diferente (≠)',
  gt: 'é maior que (>)',
  gte: 'é maior igual que (≥)',
  lt: 'é menor que (<)',
  lte: 'é menor igual que (≤)',
  between: 'está entre (∈)',
  notBetween: 'não está entre (∉)',
}

export const FILTER_TYPES = {
  date: {
    operators: ['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'between', 'notBetween'],
    canBeComposed: true,
  },
  multiple: {
    operators: ['eq', 'ne'],
    canBeComposed: true,
  },
  number: {
    operators: ['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'between', 'notBetween'],
    canBeComposed: true,
  },
}

export const FILTERABLE_FIELDS = {
  age : {
    type: 'number',
    label: 'Idade',
  },
  sexes: {
    type: 'multiple',
    label: 'Sexo',
    values: [
      { "label": "Masculino", "value": "masculino" },
      { "label": "Feminino", "value": "feminino" }
    ],
  },
  races: {
    type: 'multiple',
    label: 'Raça',
    values: [
      { "label": "Branca", "value": "branca" },
      { "label": "Preto", "value": "preto" },
      { "label": "Pardo", "value": "pardo" },
      { "label": "Amarela", "value": "amarela" },
      { "label": "Indígena", "value": "indigena" }
    ],
  },
  diabetes: {
    type: 'multiple',
    label: 'Diabetes',
    values: [
      { "label": "Dieta", "value": "dieta" },
      { "label": "Med. Oral", "value": "medicamento oral" },
      { "label": "Insulina", "value": "insulina" },
      { "label": "Não ou ausente", "value": 'null' }
    ],
  },
  hipertensao: {
    type: 'multiple',
    label: 'Hipertensão',
    values: [
      { "label": "Sim", "value": "true" },
      { "label": "Não", "value": "false" },
    ],
  },
  tabagismo: {
    type: 'multiple',
    label: 'Tabagismo',
    values: [
      { "label": "Prévio (> 6 meses)", "value": "previo" },
      { "label": "Atual (< 6 meses)", "value": "atual" },
      { "label": "Não ou ausente", "value": 'null' }
    ],
  },
  sintomas: {
    type: 'multiple',
    label: 'Sintomas',
    values: [
      { "label": "Assintomático", "value": "assintomatico" },
      { "label": "Leve", "value": "leve" },
      { "label": "Moderado", "value": "moderado" },
      { "label": "Grave", "value": "grave" },
      { "label": "Dor ao repouso", "value": "dor-repouso" },
      { "label": "Úlcera", "value": "ulcera" },
      { "label": "Não cicatrização do coto", "value": "nao-cicatrizacao-coto" },
      {
        "label": "Úlcera + não cicatrização do coto",
        "value": "ulcera-nao-cicatrizacao-coto"
      },
      { "label": "Isquemia aguda", "value": "isquemia-aguda" }
    ],
  },
  infeccao: {
    type: 'multiple',
    label: 'Infecção',
    values: [
      { "label": "Ausente", "value": "ausente" },
      { "label": "Leve", "value": "leve" },
      { "label": "Moderada", "value": "moderada" },
      { "label": "Grave", "value": "grave" }
    ],
  },
  'perda-tecidual': {
    type: 'multiple',
    label: 'Perda Tecidual',
    values: [
      { "label": "Rasa", "value": "rasa" },
      { "label": "Profunda", "value": "profunda" },
      { "label": "Extensa", "value": "extensa" },
      { "label": "Ausente", "value": "ausente" }
    ],
  },
  'itb-direita': {
    type: 'number',
    label: 'ITB Direita (Status Atual)',
  },
  'itb-esquerda': {
    type: 'number',
    label: 'ITB Esquerda (Status Atual)',
  },
  'data-procedimento': {
    type: 'date',
    label: 'Data do Procedimento',
  },
  'arteria-tratada': {
    type: 'multiple',
    label: 'Arteria Tratada',
    values: [
      { "label": "Aorta", "value": "aorta" },
      { "label": "Iliaca comum", "value": "iliaca-comum" },
      { "label": "Iliaca externa", "value": "iliaca-ext" },
      { "label": "Femoral comum", "value": "fem-com" },
      { "label": "Femoral profunda", "value": "fem-prof" },
      { "label": "Femoral superficial", "value": "fem-sup" },
      { "label": "Poplitea P1", "value": "poplitea-p1" },
      { "label": "Poplitea P2", "value": "poplitea-p2" },
      { "label": "Poplitea P3", "value": "poplitea-p3" },
      { "label": "Tibial anterior", "value": "tibial-anterior" },
      { "label": "Tronco tibial", "value": "tronco-tibial" },
      { "label": "Tibial posterior", "value": "tibial-posterior" },
      { "label": "Fib", "value": "fib" },
      { "label": "Pediosa", "value": "pediosa" },
      { "label": "Plantar", "value": "plantar" },
      { "label": "Iliaca interna", "value": "iliaca-interna" }
    ],
  },
  'extensao-total-oclusao': {
    type: 'number',
    label: 'Extensão total da oclusão',
  },
  'extensao-total-lesao': {
    type: 'number',
    label: 'Extensão total da lesão',
  },
  'dispositivo': {
    type: 'multiple',
    label: 'Dispositivo',
    values: [
      { "label": "Não foi possível tratar", "value": "não-foi-possivel-tratar" },
      { "label": "PTA", "value": "pta" },
      { "label": "Balão Especial", "value": "balao-especial" },
      { "label": "Stent", "value": "stent" },
      { "label": "Aterectomia", "value": "aterectomia" },
      { "label": "Trombectomia", "value": "trombectomia" },
      { "label": "Stent Recoberto", "value": "stent-recoberto" }
    ]
  },
  'resultado-tecnico-final': {
    type: 'multiple',
    label: 'Resultado técnico final',
    values: [
    { "label": "<30%", "value": "menor-30" },
    { "label": ">30%", "value": "maior-30" },
    { "label": "Recoil", "value": "recoil" },
    { "label": "Falha Técnica", "value": "falha-técnica" }
    ],
  },
  embolizacao: {
    type: 'multiple',
    label: 'Embolização',
    values: [
      { "label": "Não", "value": "nao" },
      { "label": "Nenhum tratamento", "value": "nenhum-tratamento" },
      { "label": "Medicamentoso", "value": "medicamentoso" },
      { "label": "endovascular", "value": "endovascular" },
      { "label": "Aberto", "value": "aberto" }
    ],
  },
  'disseccao-lesao-alvo': {
    type: 'multiple',
    label: 'Dissecção',
    values: [
      { "label": "Não", "value": "nao" },
      { "label": "Nenhum tratamento", "value": "nenhum-tratamento" },
      { "label": "Medicamentoso", "value": "medicamentoso" },
      { "label": "endovascular", "value": "endovascular" },
      { "label": "Aberto", "value": "aberto" }
    ],
  },
  'amputacao-durante-internacao': {
    type: 'multiple',
    label: 'Amputação durante internação',
    values: [
      { "label": "Não", "value": "nao" },
      { "label": "Coxa", "value": "coxa" },
      { "label": "Perna", "value": "perna" },
      { "label": "Ante-pé / metatarso", "value": "ante-pe-metatarso" },
      { "label": "Dedos", "value": "dedos" }
    ],
  },
  'amput-planejada': {
    type: 'multiple',
    label: 'Amputação planejada',
    values: [
      { "label": "Sim", "value": "true" },
      { "label": "Não", "value": "false" },
    ],
  },
  'tempo-internacao': {
    type: 'number',
    label: 'Tempo de Internação',
  },
}

export const CALCULATION_TYPES = {
  "age": {
    label: 'Idade',
    calculationType: 'average',
  },
  "patientName": {
    label: 'Nome',
    calculationType: 'percentage',
  },
  "sexes": {
    label: 'Sexo',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "races": {
    label: 'Raça',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "doencaCoronariana": {
    label: 'Doença Coronariana',
    calculationType: 'percentage',
  },
  "insuficienciaCardiaca": {
    label: 'Insuficiência Cardíaca',
    calculationType: 'percentage',
  },
  "arritmia": {
    label: 'Arritmia',
    calculationType: 'percentage',
  },
  "dpoc": {
    label: 'DPOC',
    calculationType: 'percentage',
  },
  "diabetes": {
    label: 'Diabetes',
    calculationType: 'percentage',
  },
  "dialise": {
    label: 'Diálise',
    calculationType: 'percentage',
  },
  "hipertensao": {
    label: 'Hipertensão',
    calculationType: 'percentage',
  },
  "tabagismo": {
    label: 'Tabagismo',
    calculationType: 'percentage',
  },
  "avc": {
    label: 'AVC',
    calculationType: 'percentage',
  },
  "revasc-miocardio": {
    label: 'Revasc do miocárdio',
    calculationType: 'percentage',
  },
  "angioplastia-coronariana": {
    label: 'Angioplastia coronariana',
    calculationType: 'percentage',
  },
  "cea-cas-anterior": {
    label: 'CEA / CAS anterior',
    calculationType: 'percentage',
  },
  "revasc-previa-membro-inferior": {
    label: 'Revasc prévia do Membro Inferior',
    calculationType: 'percentage',
  },
  "sintomas": {
    label: 'Sintomas',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "infeccao": {
    label: 'Infecção',
    calculationType: 'percentage',
  },
  "perda-tecidual": {
    label: 'Perda Tecidual',
    calculationType: 'percentage',
  },
  "itb-status-atual": {
    label: 'ITB Status Atual',
    calculationType: 'average',
  },
  "extensao-total-oclusao": {
    label: 'Extensão total da oclusão',
    calculationType: 'average',
  },
  "extensao-total-lesao": {
    label: 'Extensão total da lesão',
    calculationType: 'average',
  },
  "dispositivo-tipo": {
    label: 'Tipo',
    calculationType: 'percentage',
  },
  "dispositivo-cto": {
    label: 'Dispositivo CTO',
    calculationType: 'percentage',
  },
  "ivus": {
    label: 'IVUS',
    calculationType: 'percentage',
  },
  "resultado-tecnico-final": {
    label: 'Resultado técnico final',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "embolizacao": {
    label: 'Embolização',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "disseccao-lesao-alvo": {
    label: 'Dissecção',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "itb-pos-op": {
    label: 'ITB Pós Op',
    calculationType: 'average',
  },
  "amputacao-durante-internacao": {
    label: 'Amputação durante internação',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "amput-planejada": {
    label: 'Amputação planejada',
    calculationType: 'percentage',
    multipleValues: true,
  },
  "tempo-internacao": {
    label: 'Tempo de Internação',
    calculationType: 'average',
  },
}