import React, { useState } from 'react';

import api from 'services';
import { timeout } from 'helpers';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { formatISO, format, lastDayOfMonth, add } from "date-fns";

import MainContainer from 'components/mainContainer';
import SideBar from 'components/sideBar';

import { Description, RangeDatePicker} from 'domain/BiannualAnnual';
import { HospitalTypeChart } from 'domain/BiannualAnnual/HospitalTypeChart';
import { ProceduresByHospital } from 'domain/BiannualAnnual/ProceduresByHospital';
import { IschemicSection } from 'domain/BiannualAnnual/IschemicSection';
import { FemoralPunctureSection } from 'domain/BiannualAnnual/FemoralPunctureSection';
import { useIsDemoUser } from 'hooks/useIsDemoUser';
import { ANNUAL_REPORT_MOCK_DATA } from 'mocks';

const formatDateISO = (date) => formatISO(date, { representation: 'date' })

const BiannualAnnual = () => {
  const [reportData, setReportData] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const isDemoUser = useIsDemoUser();

  const handleButtonClick = () => {
    setReportData({});
    if (!startDate || !endDate) return;

    const startDateFormatted = formatDateISO(startDate);
    const endDateFormatted = formatDateISO(endDate);

    setIsLoading(true);
    setReportData({});

    if (isDemoUser) {
      setIsLoading(false);
      setReportData(ANNUAL_REPORT_MOCK_DATA);
      return;
    }

    Promise.all([
      api.get(`/reports/biannual-annual?start=${startDateFormatted}&end=${endDateFormatted}`),
      timeout(1500),
    ]).then(([{ data }]) => setReportData(data))
    .then(() => setIsLoading(false));
  };

  return (
    <MainContainer overflow='auto'>
      <SideBar title="Relátorio Semestral / Anual" />

      <Box mb={2}>
        <Paper>
          <Box padding={2}>
            <Typography variant='h6' gutterBottom>Relátorio Semestral / Anual</Typography>
            <Typography variant='caption'>Selecione o período</Typography>
              <RangeDatePicker
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={date => {
                  setReportData({});
                  setStartDate(date);
                }}
                onChangeEndDate={date => {
                  setReportData({});
                  setEndDate(date);
                }}
              />
            <Box marginY={2}>
              <Button variant="contained" onClick={handleButtonClick} disabled={isLoading}>
                {isLoading? 'Gerando...' : 'Gerar Relatório'}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>

      {Object.keys(reportData).length > 0 && (
        <Paper>
          <Box padding={2}>
            <Description
              startDate={format(startDate, 'dd/MM/yyyy')}
              endDate={format(lastDayOfMonth(endDate), 'dd/MM/yyyy')}
            />
            <HospitalTypeChart data={reportData.proceduresByHospitalType} />
            <Box mt={8}>
              <ProceduresByHospital
                data={reportData.proceduresByHospital}
                limitDate={format(lastDayOfMonth(add(endDate, { months: 1 })), 'dd/MM/yyyy')}
              />
            </Box>
            <Box marginY={3}>
              <IschemicSection
                startDate={format(startDate, 'dd/MM/yyyy')}
                endDate={format(lastDayOfMonth(endDate), 'dd/MM/yyyy')}
                tableData={reportData.ischemicInfos}
                chartData={reportData.ischemicChart}
                limitDate={format(lastDayOfMonth(add(endDate, { months: 1 })), 'dd/MM/yyyy')}
              />
              <FemoralPunctureSection
                startDate={format(startDate, 'dd/MM/yyyy')}
                endDate={format(lastDayOfMonth(endDate), 'dd/MM/yyyy')}
                tableData={reportData.femoralPunctureInfos}
                chartData={reportData.femoralPunctureChart}
                limitDate={format(lastDayOfMonth(add(endDate, { months: 1 })), 'dd/MM/yyyy')}
              />
            </Box>
          </Box>
        </Paper>
      )}

    </MainContainer>
  )
}

export default BiannualAnnual;