import React, { useState, useEffect } from "react";
import * as Styled from "./style";
import InputText from "../../index";
import { Button, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useFormContext } from "react-hook-form";
import api from "../../../../../services";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from '../../../../popover';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "56px",
    margin: "0 0 16px 12px",
    padding: "6px 24px",
  },
}));

const InputTextSearchDevice = (props) => {
  const { 
    name,
    isFieldArrayChild,
    fieldArrayName, 
    conditionalSetValue = () => {}, 
    watchFor = null,
    watchValues,
    watchOperation,
    popover,
    formSchemaType,
    formSchemaVersion,
    fieldArrayChildName,
    inputData,
    ...rest
  } = props;

  const classes = useStyles();
  const {
    setValue,
    getValues,
    setError: setFormError,
    clearError,
    watch,
  } = useFormContext();
  const [isLoading, setIsloading] = useState(false);
  const [errorObj, setError] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    conditionalSetValue();
    // eslint-disable-next-line
  }, []);

  const watchedInputs = watchFor ? watch(watchFor) : null;

  useEffect(() => {
    if(!watchedInputs) return;
    

    const shouldBeDisabled = Object.entries(watchValues)
      .some(([ key, values ]) => (
        watchOperation === 'not-equal' 
        ?!values.includes(watchedInputs)
        : values.includes(watchedInputs)
      ));
    
    setIsDisabled(!shouldBeDisabled);
  // eslint-disable-next-line
  }, [watchedInputs]);

  const fetcher = (deviceId) => api.get(`/searchDevices/${deviceId}`);

  const handleClick = () => {
    
    const di = getValues(name);

    setIsloading(!!di ? true : false);

    if (!di) return;
    clearError(name);

    fetcher(di)
      .then((response) => {
        const data = response?.data?.data?.gudid?.device;
        const companyName = data?.companyName ?? '';
        const type = data?.brandName ?? '';
        const diameter = data?.deviceSizes?.deviceSize[1]?.sizeText ?? '';
        const length = data?.deviceSizes?.deviceSize[0]?.sizeText ?? '';

        const getCompaniesByType =  api.get('/devices/companies?type=pta');
        const getDevicesByTypeAndCompany = api.get(`/devices?companyName=${companyName}&typeName=pta`);

        Promise.all([getCompaniesByType, getDevicesByTypeAndCompany])
          .then(([{data:{data: companies}}, {data:{data: devices}}]) => {
            const [company] = companies.filter(({name}) => name === companyName);
            const [device] = devices.filter(({name}) => name === type.toUpperCase());
            
            if(isFieldArrayChild) {
              setValue([
                {[`${fieldArrayName}[dispositivo-empresa]`]: company},
                {[`${fieldArrayName}[dispositivo-tipo]`]: device },
                {[`${fieldArrayName}[dispositivo-diametro]`]: diameter.match(/\d/g).join('')},
                {[`${fieldArrayName}[dispositivo-comprimento]`]: length.match(/\d/g).join('')},
              ])
              return;
            }

          })
          .catch(e => {
            console.error(e);
            setFormError(name, "serverError", "Ocorreu um problema, tente novamente!");
            setError({
              [name]: {
                type: "serverError",
                message: "Ocorreu um problema, tente novamente!"
              }
            });
          })

        setError({});
      })
      .catch((e) => {
        if(e.response) {
          setFormError(name, "notFound", "Dispositivo não encontrado");
          setError({
            [name]: {
              type: "notFound",
              message: "Dispositivo não encontrado"
            }
          });
          return;
        }

        if(e.request) {
          setFormError(name, "serverError", "Ocorreu um problema, tente novamente!");
          setError({
            [name]: {
              type: "serverError",
              message: "Ocorreu um problema, tente novamente!"
            }
          });
          return;
        }

      })
      .finally(() => setIsloading(false));
  };

  return (
    <Styled.Wrapper>
      <InputText 
        name={name}
        {...rest} 
        manualError={errorObj} 
        disabled={isDisabled}
        label={isDisabled ? 'Selecione um dispositivo' :  props.label}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={
          isLoading ? (
            <CircularProgress color="inherit" size="30px" />
          ) : (
            <SearchIcon />
          )
        }
        classes={{ root: classes.root }}
        onClick={handleClick}
        disabled={isLoading || isDisabled}
        data-testid={`${name}-button`}
      >
        Buscar
      </Button>
      <Styled.PopoverWrapper>
        {popover && (
          <Popover 
            name={fieldArrayChildName}
            classes={classes} 
            formSchemaType={formSchemaType} 
            formSchemaVersion={formSchemaVersion}  
          />
        )}
      </Styled.PopoverWrapper>
    </Styled.Wrapper>
  );
};

export default InputTextSearchDevice;
