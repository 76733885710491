import React, { useState } from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';

import { Alert } from './Alert';

export const Snackbar = props => {
  const { alertType = 'warning', ...rest } = props;

  return (
    <MuiSnackbar
      style={{ transform: 'none', position: 'initial', marginTop: 8 }}
      open={true}
      {...rest}
    >
      <Alert severity={alertType}>
        {props.children}
      </Alert>
    </MuiSnackbar>
  );
}