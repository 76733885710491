import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { FormLogin } from "components/Form/Login";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import api from "services";
import { useAuth } from "context/auth";
import { useUser } from "context/user";
import { parseJwt } from "helpers";

const Login = ({ location, isModal }) => {
  const [formState, setFormState] = useState({});
  const [formError, setFormError] = useState("");
  const [teamList, setTeamList] = useState(false);
  const isFirstRender = useIsFirstRender();
  const fetcher = (loginInfo) => api.post("/auth/login", loginInfo);
  const { setAuthTokens, tokenIsValid, setTokenIsValid, setIsLoginModalOpen } = useAuth();
  const { setUserInfos } = useUser();
  const referer = location?.state?.referer || "/dashboard";

  useEffect(() => {
    if (isFirstRender) return;

    fetcher(formState)
      .then(async (res) => {
        if(res.status !== 200) return res;
        const { token, preferences } = res.data;
        const { name, email, id: userId } = parseJwt(token);

        setFormError("");
        setAuthTokens(token);
        setTokenIsValid(true);
        setUserInfos({
          name,
          email,
          preferences,
          userId,
        })
        setIsLoginModalOpen(false);
      })
      .then(res => {
        if(res.status !== 202) return;
        
        const { teams } = res.data;
        setTeamList(teams);
      })
      .catch((error) => {
        if (error.response) {
          setFormError("O usuário e/ou a senha estão incorretos");
          return;
        }
        if (error.request)
          setFormError("Parece que ocorreu um erro, tente novamente!");
      });
      // eslint-disable-next-line
  }, [formState]);
  
  if (!isModal) {
    if (tokenIsValid) return <Redirect to={referer} />;
  }

  return <FormLogin formError={formError} setFormState={setFormState} teamList={teamList} />;
};

export default Login;
