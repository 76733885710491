import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const init = () => {
  if(process.env.NODE_ENV === "production") {
    initLogRocket();
    initSentry();
    integrateLogRocketWithSentry();
  }
}



const initLogRocket = () => {
  LogRocket.init('fjiz3b/vascular');
  setupLogRocketReact(LogRocket);
}

const initSentry = () => {
  Sentry.init({
    dsn: "https://d08f169bf8bb475290fb462fd6a4207f@o441787.ingest.sentry.io/5412528",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

const integrateLogRocketWithSentry = () => {
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

export const identifyUsersLogRocket = (userId, name, email) => {
  LogRocket.identify(userId, {
    name,
    email
  });
}

export default {
  init
}