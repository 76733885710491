export default [
  {
    title: 'Métrica de Utilização',
    rows: [
      {
        highlight: true,
        cells: [
          {
            text: 'Dados dos casos',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Números de casos',
          },
          {
            text: '31',
          },
          {
            text: '76',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Média do TI total',
          },
          {
            text: '1',
            percentile: '>75',
          },
          {
            text: '1',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Média do TI após procedimento',
          },
          {
            text: '1',
            percentile: '>75',
          },
          {
            text: '16',
          },
        ],
      },
    ]
  },
  {
    title: 'Métrica de Performance',
    rows: [
      {
        highlight: true,
        cells: [
          {
            text: 'Tabagista com claudicação',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Nunca',
          },
          {
            text: '6,9%',
          },
          {
            text: '12,1%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Prévio',
          },
          {
            text: '62,1%',
            percentile: '<25',
          },
          {
            text: '50,1%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Atual',
          },
          {
            text: '31%',
          },
          {
            text: '37,8%',
          },
        ],
      },
      {
        highlight: true,
        cells: [
          {
            text: 'Estatina na alta',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '(exclui morte hospitalar)',
          },
          {
            text: 'Estatina',
          },
          {
            text: '100%',
            percentile: '>75',
          },
          {
            text: '82,3%',
          },
        ],
      },
      {
        highlight: true,
        cells: [
          {
            text: 'Anti-agregante na alta',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '(exclui morte hospitalar)',
          },
          {
            text: 'Antiagregante',
          },
          {
            text: '100%',
            percentile: '>75',
          },
          {
            text: '94,1%',
          },
        ],
      },
    ]
  },
  {
    title: 'Métrica de Processo',
    rows: [
      {
        highlight: true,
        cells: [
          {
            text: 'ITB pré mensurado',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'ITB pré-op relatado',
          },
          {
            text: '71%',
          },
          {
            text: '77%',
          },
        ],
      },
    ]
  },
  {
    title: 'Métrica de Desfecho',
    rows: [
      {
        highlight: true,
        cells: [
          {
            text: 'Sucesso Técnico',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Sucesso técnico no procedimento',
          },
          {
            text: '92%',
          },
          {
            text: '88%',
          },
        ],
      },
      {
        highlight: true,
        cells: [
          {
            text: 'Eventos pós-op',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'IAM',
          },
          {
            text: '0,6%',
            percentile: '<25',
          },
          {
            text: '0,4%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Complicações Renais',
          },
          {
            text: '2,9%',
            percentile: '<25',
          },
          {
            text: '0,7%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Trombose',
          },
          {
            text: '0,3%',
          },
          {
            text: '0,5%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Embolização',
          },
          {
            text: '0,6%',
          },
          {
            text: '0,6%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Dissecção da lesão alvo',
          },
          {
            text: '1%',
          },
          {
            text: '2,8%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Perfuração arterial',
          },
          {
            text: '0,3%',
          },
          {
            text: '0,6%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Hematoma no sítio de acesso',
          },
          {
            text: '6,7%',
            percentile: '<25',
          },
          {
            text: '2%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Infecção no sítio de acesso',
          },
          {
            text: '0,3%',
            percentile: '<25',
          },
          {
            text: '0%',
          },
        ],
      },
      {
        cells: [
          {
            text: '',
          },
          {
            text: 'Amputação não planejada',
          },
          {
            text: '1,6%',
            percentile: '<25',
          },
          {
            text: '1,4%',
          },
        ],
      },
    ]
  },
];
