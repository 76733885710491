import React from 'react';

import DatePicker from './DatePicker';
import NumberInput from './NumberInput';
import DoubleInput from './DoubleInput';
import { DefaultSelect } from './Select';

const InputByType = ({
  formState,
  fields,
  special = [ 'between', 'notBetween' ],
  ...rest
}) => {
  const componentsDictionary = {
    date: {
      default: props => <DatePicker {...props} />,
      special: props => <DoubleInput  Input={DatePicker}  {...props}/>
    },
    multiple: {
      default: props => <DefaultSelect {...props} />
    },
    number: {
      default: props => <NumberInput {...props} />,
      special: props => <DoubleInput  Input={NumberInput}  {...props}/>
    }
  }

  const field = fields[formState['field']];
  const inputType = componentsDictionary[field['type']];
  const [ operator ] = formState.operator;
  const componentToRender = inputType[special.includes(operator) ? 'special' : 'default'];
  const options = field?.['values'] ?? [];

  return componentToRender({ ...rest, data: options});
};

export default InputByType;