import React, { useContext, useEffect } from "react";
import FormRegisterContext from "../context/form-context";

import StepperMui from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&$active": {
      color: "#F51757",
    },
    "&$completed": {
      color: theme.palette.success.main,
    },
  },
  label: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    width: "fit-content",
    margin: "16px auto 0",

    "&$active": {
      color: "#F51757",
      borderBottom: "2px solid",
      borderColor: theme.palette.warning.main,

      "&$completed": {
        color: theme.palette.success.main,
        fontWeight: 500
      }
    },

    "&$completed": {
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  active: {},
  completed: {},
}));

const Stepper = (props) => {
  const {
    setStepKey,
    actualStep,
    setActualStep,
    stepLabel,
    setStepLabel,
    stepComplete,
    setActualSubStep,
    schema
  } = useContext(FormRegisterContext);

  useEffect(() => {
    const stepsLabel = Object.entries(schema)
      .reduce((array, [key, {schemaName, title, order}]) => {
        return [...array, {schemaName, title, order}]
      }, []).sort((a,b) => (a.order > b.order) ? 1 : -1);
    setStepLabel(stepsLabel)
  // eslint-disable-next-line
  }, [schema]);

  const handleStepClick = (key, index) => {
    setStepKey(key);
    setActualStep(index);
    setActualSubStep({ trigger: true, index: 0 });
  };

  const { root, active, completed, label } = useStyles();

  return (
    <>
      <StepperMui activeStep={actualStep} alternativeLabel>
        {stepLabel.map(({ schemaName, title }, index) => (
          <Step
            onClick={() => handleStepClick(schemaName, index)}
            key={schemaName}
            completed={stepComplete[schemaName]}
            data-testid={schemaName}
          >
            <StepLabel 
              classes={{root, active, completed, label}}
              StepIconProps={{ classes: { root, active, completed } }}
            >
              {title}
            </StepLabel>
          </Step>
        ))}
      </StepperMui>
    </>
  );
};

export default Stepper;
