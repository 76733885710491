import React, { useEffect } from "react";
import InputAutocomplete from "./InputAutocomplete";
import api from "../../../services";
import { useHide } from "./useHide";

const getData = async (companyName, typeName, isCancelled, setData) => {
  try {
    const {
      data: { data },
    } = await api.get(
      `devices?companyName=${encodeURIComponent(companyName.name)}&typeName=${encodeURIComponent(typeName)}`
    );
    !isCancelled && setData(data);
  } catch (error) {}
};


const useWatch = (props, watch, setData) => {
  const inputWatch = watch(props.watchFor);
  const inputWatchTwo = watch(`${props.fieldArrayName}[dispositivo]`);

  useEffect(() => {
    let isCancelled = false;

    if(inputWatch === undefined || inputWatch === "") return;

    const deviceType = props.deviceTypeName ? props.deviceTypeName : watch(`${props.fieldArrayName}[${props.watchForSecondary}]`);
    getData(inputWatch, deviceType, isCancelled, setData);

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [inputWatch, inputWatchTwo]);
}

const InputAutocompleteDevice = (props) => {
  const { fieldArrayName, watchForHide, watchHideValues } = props;
  const hide = useHide({ fieldArrayName, watchForHide, watchHideValues });

  if(hide) return null;

  return (
    <InputAutocomplete
      getData={getData}
      useWatch={useWatch}
      {...props}
    />
  )
};

export default InputAutocompleteDevice;