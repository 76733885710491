import React from "react"; 

import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("pt-BR", ptBR);

const DatePicker = ({ date, onChange, ...rest }) => {
  return (
    <ReactDatePicker
      locale="pt-BR"
      selected={date}
      onChange={onChange}
      {...rest}
    />
  );
}

export default DatePicker;