import React, { useState, useEffect, useReducer, useRef } from "react";

import MainContainer from "../../components/mainContainer";
import Sidebar from "components/sideBar";
import Form from "../../components/Form";
import { Stepper, SubStepper } from "components/Stepper";
import { FormRegisterProvider } from "../../components/context/form-context";
import api from "../../services";
import { FormButtonSubmit } from "../../components/Form/ButtonSubmit";

import merge from "deepmerge";
import rfdc from "rfdc";
import { Card } from "@material-ui/core";

import { fromMapToObject, fromObjectToMap } from "../../helpers";
import { useUrlInfos } from "./useUrlInfos";
import { FormButtons } from "../../components/Form/Buttons";

const clone = rfdc();

const reducer = (state, action) => {
  const { type, payload } = action;

  const clonedState = clone(state);

  switch (type) {
    case "setActiveForm":
      return merge(clonedState, { activeForm: payload.name });

    case "setActiveStep":
      return merge(clonedState, { activeStep: payload.name });

    case "addFormData":
      const { name, data } = payload;

      const obj = {
        [name]: {
          data,
        },
      };

      const stateHasForm = Boolean(clonedState[name]);

      if (stateHasForm) {
        clonedState[name].data = {};
      }

      return merge(clonedState, obj);

    case "retrieveState":
      return merge(clonedState, payload);

    default:
      break;
  }
};

const initialState = {
  activeStep: "generalData",
};


export const Register = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoading, setIsLoading] = useState(true);

  const [actualStep, setActualStep] = useState(0);
  const [stepKey, setStepKey] = useState(false);
  const [stepLabel, setStepLabel] = useState([]);
  // const [stepComplete, setStepComplete] = useState({});
  const [stepComplete, setStepComplete] = useState({});

  const [actualSubStep, setActualSubStep] = useState({
    trigger: false,
    index: 0,
  });
  const [subStepLabel, setSubStepLabel] = useState([]);
  const [subStepComplete, setSubStepComplete] = useState(new Map());

  const [clickInterceptor, setClickInterceptor] = useState({
    trigger: false,
    index: 0,
  });

  const [formName, setFormName] = useState("");
  const [formSchema, setFormSchema] = useState(false);
  const [schema, setSchema] = useState({});

  const [helperState, setHelperState] = useState({});

  const [files, setFiles] = useState([]);

  const [inputsData, setInputsData] = useState({});

  const { id, type, draft, version } = useUrlInfos();
   
  const getData = async (url) => await api.get(url);
  const getId = async (url, data) => await api.post(url, data)

  useEffect(() => {
    if(!stepKey) return;
    dispatch({
      type: "setActiveStep",
      payload: {
        name: stepKey,
      },
    });
    setFormSchema(schema[stepKey].children);
  // eslint-disable-next-line
  }, [stepKey]);

  const prevStep = useRef("generalData");

  useEffect(() => {
    prevStep.current = state.activeStep;
    // eslint-disable-next-line
  }, [state.activeForm]);

  useEffect(() => {
    const previousStep = prevStep.current;
    const currentSubSteps = subStepLabel[previousStep];
    const subStepsLength = currentSubSteps?.length;

    if (!currentSubSteps) return;

    const validForms = subStepComplete.get(previousStep)?.get("completed").size;

    setStepComplete((prev) => {
      return { ...prev, [previousStep]: subStepsLength === validForms };
    });
    // eslint-disable-next-line
  }, [subStepComplete]);

  useEffect(() => {
    const allSubStepLabels = Object.entries(schema).reduce((acc, [key, { children }]) => {
      const value = children.reduce((acc, { schemaName, title }) => ([...acc, { parent: key, schemaName, title } ]), []);
      return { ...acc, [key]: value }
    }, {});

    setSubStepLabel(allSubStepLabels);
  }, [schema]);

  if (window.Cypress) {
    window.formState = state;
  }

  useEffect(() => {
    api.get(`formSchema/${type}/${version ?? ''}`)
      .then(({data: { schema, version }}) => {
        setSchema(schema);
        return version
      })
      .then(version => {
        
        api.get(`inputs-data?register-type=${type}&version=${version}`)
        .then(({data}) => setInputsData(data));

        return version;
      })
      .then(version => {
        if(!id) {
          setStepKey("generalData");
          const subStepCompleteObj = fromMapToObject(subStepComplete);
    
          getId('/register/draft', {
            registerType: type,
            schemaVersion: version,
            state: {
              actualStep,
              stepKey: "generalData",
              stepComplete,
              actualSubStep,
              subStepCompleteObj,
              clickInterceptor,
              helperState
            },
            data: {
              state
            }
          }).then(response => response.data.registerId)
          .then(id => {
            const { pathname, search } = props.history.location;
            props.history.push({
              pathname: `${pathname}/${id}`,
              search: search + '&version=' + version
            });
          })
          .then(() => setIsLoading(false))
          .catch(err => console.error(err));
    
          return;
        }

        getData(`/register${draft ? '/draft' : '/register'}/${id}`)
          .then((apiResponse) => {
            const {
              data: { state },
              state: otherStates,
            } = apiResponse.data[0];
            dispatch({
              type: "retrieveState",
              payload: state,
            });
            return otherStates;
          })
          .then((otherStates) => {
            const {
              actualStep,
              actualSubStep,
              clickInterceptor,
              helperState,
              stepComplete,
              stepKey,
              subStepCompleteObj,
            } = otherStates;

            setActualStep(actualStep);
            setActualSubStep(actualSubStep);
            setClickInterceptor(clickInterceptor);
            setHelperState(helperState);
            setStepComplete(stepComplete);
            setStepKey(stepKey);
            const newMap = fromObjectToMap(subStepCompleteObj);
            setSubStepComplete(newMap);
          })
          .then(() => {
            setIsLoading(false);
          })
          .catch((err) => console.error(err));
      });

      id && getData(`/register/files?registerId=${id}`)
      .then(({data}) => setFiles(data))
      .catch(err => console.error(err));
  // eslint-disable-next-line
  }, []);

  return (
    <FormRegisterProvider
      value={{
        schema,
        state,
        dispatch,
        actualSubStep,
        setActualSubStep,
        stepKey,
        setStepKey,
        actualStep,
        setActualStep,
        stepLabel,
        setStepLabel,
        subStepLabel,
        setSubStepLabel,
        formName,
        setFormName,
        helperState,
        setHelperState,
        stepComplete,
        setStepComplete,
        subStepComplete,
        setSubStepComplete,
        clickInterceptor,
        setClickInterceptor,
        files,
        setFiles,
        id, 
        draft,
        inputsData,
        formSchemaVersion: version,
        formSchemaType: type
      }}
    >
      <MainContainer>
        <Sidebar
          title="Novo Registro"
          renderButton={() => <FormButtonSubmit />}
        />
        <Card
          style={{
            margin: "0 0 10px",
            minHeight: '100px',
          }}
        >
          <Stepper />
        </Card>

        <div style={{ display: 'flex', overflow: 'hidden', paddingBottom: '3px', height: 'calc(100% - 122px)' }}>
          <Card
            style={{
              top: "70px",
              zIndex: "1",
              width: '300px',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'auto',
            }}
          >
            <SubStepper />
          </Card>
          {formSchema && !isLoading && (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
              <Form formSchema={formSchema} complete={id && !draft} />
              {process.env.NODE_ENV !== "production" && (
                <>
                  <pre>actualStep: {actualStep}</pre>
                  <pre>stepKey: {stepKey}</pre>
                  {/* <pre>stepLabel: {JSON.stringify(stepLabel, undefined, 4)}</pre> */}
                  <pre>
                    stepComplete: {JSON.stringify(stepComplete, undefined, 4)}
                  </pre>
                  <pre>
                    actualSubStep: {JSON.stringify(actualSubStep, undefined, 4)}
                  </pre>
                  {/* <pre>subStepLabel: {JSON.stringify(subStepLabel, undefined, 4)}</pre> */}
                  <pre>
                    subStepComplete:{" "}
                    {JSON.stringify(fromMapToObject(subStepComplete), undefined, 4)}
                  </pre>
                  <pre>
                    clickInterceptor: {JSON.stringify(clickInterceptor, undefined, 4)}
                  </pre>
                  <pre>formName: {formName}</pre>
                  {/* <pre>formSchema: {JSON.stringify(formSchema, undefined, 4)}</pre> */}
                  <pre>helperState: {JSON.stringify(helperState, undefined, 4)}</pre>
                  <pre>{JSON.stringify(state, undefined, 4)}</pre>
                </>
              )}
            </div>
            <FormButtons 
              stepLabel={stepLabel}
              subStepLabel={subStepLabel}
              actualStep={actualStep}
              actualSubStep={actualSubStep}
              setActualSubStep={setActualSubStep}
            />
            </div>
          )}
        </div>


      </MainContainer>
    </FormRegisterProvider>
  );
};