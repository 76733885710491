import React from "react";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useUser } from "../../context/user"
import useWindowDimensions from 'hooks/useWindowDimensions';

const ROWS_PER_PAGE = 25;
const HEIGHT_OTHER_THAN_TABLE_BODY = 218;

const Table = props => {

  const { onRowDelete, onRowEdit, title, data, tableColumns, rowsPerPage } = props;
  const { userInfos: { role: userRole } } = useUser();
  const { height } = useWindowDimensions();

  const restrictColumns = {
    "Super Admin": {
      userColumn: true,
      teamColumn: true,
    },
    "Team Leader": {
      userColumn: true,
      teamColumn: 'excluded',
    },
    "User": {
      userColumn: 'excluded',
      teamColumn: 'excluded',
    }
  }

  const columns = [
    {
      name: "Delete",
      label: "Deletar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton onClick={() => {
              onRowDelete(dataIndex);
            }}>
              <DeleteIcon />
              </IconButton>
          );
        }
      }
    },
    {
      name: "Edit",
      label: "Editar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton onClick={() => {
              onRowEdit(dataIndex);
            }}>
              <EditIcon />
              </IconButton>
          );
        }
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        viewColumns: false,
        display: 'excluded',
      }
    },
    {
      name: "user",
      label: "Usuário",
      options: {
        display: restrictColumns[userRole]?.userColumn ?? 'excluded',
        filterType: 'multiselect'
      }
    },
    {
      name: "team",
      label: "Equipe",
      options: {
        display: restrictColumns[userRole]?.teamColumn ?? 'excluded',
        filterType: 'multiselect'
      }
    },
    {
      name: "type",
      label: "Tipo"
    }, 
    {
      name: "admissionDate",
      label: "Data Admissão",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'Data vazia'
        }
      }
    }, 
    {
      name: "createdAt",
      label: "Criado em",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleDateString('pt-BR');
        }
      }
    }, 
    {
      name: "patientName",
      label: "Nome do Paciente",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'Nome vazio'
        }
      }
    }
  ];

  const rowsPerPageQtd = rowsPerPage || ROWS_PER_PAGE;

  const options = {
    fixedHeader: true,
    filterType: 'checkbox',
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: height - HEIGHT_OTHER_THAN_TABLE_BODY,
    rowsPerPage: rowsPerPageQtd,
    rowsPerPageOptions: [rowsPerPageQtd, rowsPerPageQtd * 2, rowsPerPageQtd * 3],
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro encontrado.",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Visualizar Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Deletar",
        deleteAria: "Deletar Linhas Selecionadas",
      }
    }
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={tableColumns ?? columns}
      options={options}
      stickyHeader
    />
  )

}

export default Table;