import styled from 'styled-components';

export const SliderWrapper = styled.div`
  max-width: 300px;
  margin: 10px 0 -20px 10px;
`

export const TypographyWrapper = styled.div`
  display: flex;
  position: relative;
  left: -10px;
`