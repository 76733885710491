import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  formControlLabel: {
    marginLeft: 0,
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
  },
  checkbox: {
    padding: '4px 2px 4px 0',
  },
  title: {
    fontSize: 15,
    marginBottom: 6,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 6px',
  }
}));