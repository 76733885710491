import React from "react";
import { Button } from "@material-ui/core";

const Buttons = props => {
  const { firstButtonText, lastButtonText, firstButtonOnClick, lastButtonOnClick } = props;

  return (
    <>
      <Button variant="contained" onClick={firstButtonOnClick}>
        {firstButtonText}
      </Button>
      {lastButtonText && (
        <Button
          variant="contained"
          style={{ marginLeft: "9px" }}
          onClick={lastButtonOnClick}
        >
          {lastButtonText}
        </Button>
      )}
    </>
  )
}

export default Buttons;