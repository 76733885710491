import React, { useState, useEffect, useContext } from "react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { useFormContext } from "react-hook-form";
import * as Styled from "./style";
import FormRegisterInfo from "../../context/form-context";

import { getObjectByStringPath } from "../../../helpers";

import Popover from "../../popover";
import { FormControl, FormHelperText } from "@material-ui/core";

const InputSlider = (props) => {
  const {
    name,
    label,
    step,
    max,
    marks,
    initial,
    disabled,
    popover,
    fieldArrayChildName,
    ...rest
  } = props;
  
  const { register, setValue, unregister, errors } = useFormContext();
  const [state, setState] = useState(initial);
  const { state: globalFormState } = useContext(FormRegisterInfo);
  const errorObject = getObjectByStringPath(errors, name);

  useEffect(() => {
    register({ name: [name] });
    setValue(name, state);

    return () => {
      unregister(name);
    };
    // eslint-disable-next-line
  }, [register]);

  useEffect(() => {
    setValue(name, state);
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];
    const inputState = getObjectByStringPath(formState?.data, name);
    inputState && setState(inputState);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  return (
    <Styled.SliderWrapper>
      <Styled.TypographyWrapper>
        <Typography gutterBottom style={{ marginRight: '10px' }}>{label}</Typography>
        {popover && <Popover name={fieldArrayChildName ?? name} {...rest} />}
      </Styled.TypographyWrapper>
      <Slider
        value={state}
        step={step}
        max={max}
        marks={marks}
        min={initial}
        valueLabelDisplay="auto"
        // onChangeCommitted={(event, value) => setValue(name, value)}
        onChange={(event, value) => setState(value)}
        data-testid={name}
        disabled={disabled}
      />
      <FormControl fullWidth error={!!errorObject}>
        {errorObject?.message && <FormHelperText>{errorObject?.message}</FormHelperText>}
      </FormControl>
    </Styled.SliderWrapper>
  );
};

export default InputSlider;
