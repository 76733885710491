import React from 'react';

import Login from 'pages/Login/Login';
import { useAuth } from "context/auth";

import { Container } from './style';

function LoginModal() {
  const { isLoginModalOpen } =  useAuth();

  if (!isLoginModalOpen) {
    return null;
  }
 
  return (
    <Container>
      <Login isModal={true} />
    </Container>
  );
};

export default LoginModal;