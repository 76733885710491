import React, { useState, useEffect, useContext } from "react";
import { Dropzone } from "./Dropzone";
import { uploadFile } from "../../services/FileUpload";
import LinearProgress from '@material-ui/core/LinearProgress';
import { FilesManager } from "./FilesManager";
import FormRegisterInfo from "../../components/context/form-context";
import api from "../../services";

const LinearProgressStyled = (props) => {
  const { error, variant, value } = props;
  return <LinearProgress variant={variant} value={value} color={error ? 'secondary' : 'primary'}/>
}

const Uploader = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [disabled] = useState(false);
  const [error, setError] = useState(false);

  const { id, draft, files, setFiles } = useContext(FormRegisterInfo);

  const handleDelete = (fileId, url) => {
    api.delete(`/register/files/${fileId}?url=${url}`)
    .then(() => setFiles(files => files.filter(({id}) => id !== fileId)))
    .catch(error => console.error(error));
  }

  useEffect(() => {
    if(!selectedFiles.length) return;
    
    setError(false);
    uploadFile(selectedFiles, id, draft, event => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
    .then((response) => {
      const { data: { files: uploadedFiles } } = response;
      setFiles(files => [...files, ...uploadedFiles]);
      setTimeout(() => setProgress(0), 3000);
    })
    .catch(() => {
      setError(true);
      setTimeout(() => setProgress(0), 3000);
    });
  // eslint-disable-next-line
  }, [selectedFiles])

  return (
    <>
      <FilesManager files={files} handleDelete={handleDelete}/>
      <Dropzone setSelectedFiles={setSelectedFiles} disabled={disabled}/>
      <LinearProgressStyled variant="determinate" value={progress} error={error} />
    </>
  )
 
};

export default Uploader;
