export const hospitalTypeDictionary = {
  academico: {
    label: 'Acadêmico',
    color: 'hsl(209, 59%, 60%)',
  },
  privado: {
    label: 'Privado',
    color: 'hsl(0, 0%, 65%)',
  },
  publico: {
    label: 'Público',
    color: 'hsl(218, 52%, 52%)',
  },
};
