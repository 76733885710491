import * as yup from "yup";
import { mapRules, minDate, isAfterToday } from "../../../helpers";

yup.addMethod(yup.string, 'cpf', function(message) {
  return this.matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, message)
});

yup.addMethod(yup.number, 'isValidNumber', function() {
  return this.transform((value) => (isNaN(value) ? -1 : value))
});

yup.addMethod(yup.mixed, 'maxCurrentDate', function(message) {
  
  return this.test('maxDate', message, function(value) {
    return !isAfterToday(value);
  });
  
});

yup.addMethod(yup.mixed, 'minDate', function(minimumDate, message) {
  
  return this.test('minDate', message, value =>  minDate(value, minimumDate));
  
});

yup.addMethod(yup.mixed, 'minDateRefSibling', function(sibling, message) {

  return this.test('minDateRefSibling', message, function(value) {
    return minDate(value, this.parent[sibling])
  });
  
});

const nullishValues = [ '', NaN ];
yup.addMethod(yup.mixed, 'nullishValuesToUndefined', function() {
  return this.transform((value) => {
    return nullishValues.includes(value) ? undefined : value
  });
});

function createYupSchema(schema, config) {
  const { props: { name: id }, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[id] = validator;
  return schema;
}

export function dynamicValidation(formValidationType, inputs, formName) {

  formName = formName.charAt(0).toLowerCase() + formName.slice(1)

  const types = {
    "default": obj => yup.object().shape(obj),
    "array": (obj, formName) => yup.object().shape({
      data: yup.array().of(
        yup.object().shape({
          [formName] : yup.object().shape(obj)
        })
      )
    }),
    "arrayOfTabs": (object, formName) => yup.object().shape({
      data: yup.array().of(
        yup.lazy(obj => yup.object(
          mapRules(obj, yup.object({
            [formName] : yup.object().shape(object)
          }))
        ))
      )
    }),
    "tabs": object => yup.lazy(obj => yup.object(
      mapRules(obj, yup.object(object))
    ))
  }

  return types[formValidationType]?.(inputs.reduce(createYupSchema, {}), formName) ?? yup.object().shape({})
}

