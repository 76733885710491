import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import ComponentType from "../componentType";
import FormRegisterInfo from "../context/form-context";
import * as Styled from "./style";
import { getObjectByStringPath } from "../../helpers";

const SelectControlledConditional = (props) => {
  let {
    name,
    inputType,
    watchType = "input",
    watchFor,
    watchValues = "",
    indentationLevel = 0,
    formName,
    childProps,
    alwaysOnDom = false,
  } = props;

  const { watch, setValue } = useFormContext();
  const { state: globalFormState } = useContext(FormRegisterInfo);

  const [show, setShow] = useState(false);
  const [state, setState] = useState(false);

  const inputWatched = watch(watchType === "input" ? watchFor : "");

  const setInputValue = () => {
    if (state !== false) {
      setValue(name, state, true);
    }
  };

  useEffect(() => {
    if (watchType !== "input") return;

    const formState = globalFormState[globalFormState.activeForm];
    const inputState = formState ? getObjectByStringPath(formState?.data, name) : false;
    if (inputState || inputState === "") setState(inputState);
      // eslint-disable-next-line
  }, [globalFormState]);

  useEffect(() => {
    if (watchType !== "state") return;

    const watchFormState = globalFormState[formName];
    const watchInputState = watchFormState
      ? watchFormState?.data?.[watchFor]
      : false;
    setShow(watchValues.includes(watchInputState));

    const formState = globalFormState[globalFormState.activeForm];
    const inputState = formState ? formState.data[name] : false;
    const inputStateIsValid = inputState || inputState === "";
    inputStateIsValid && setState(inputState);
    // eslint-disable-next-line
  }, [globalFormState]);

  useEffect(() => {
    if(typeof watchFor === 'string') {
      if (watchValues !== "") {
        setShow(watchValues.includes(inputWatched));
      } else {
        setShow(inputWatched);
      }
    }

    if(Array.isArray(watchFor)) {
      const shouldShow = Object.entries(watchValues)
        .some(([ key, values ]) => (
          values.includes(inputWatched[key])
        ));

      setShow(shouldShow)
    }
    // eslint-disable-next-line
  }, [inputWatched]);

  if (alwaysOnDom) {
    return (
      <Styled.InputWrapper indentationLevel={indentationLevel} isVisible={show}>
        <ComponentType
          type={inputType}
          {...props}
          indentationLevel={0}
          conditionalSetValue={setInputValue}
          {...childProps}
        />
      </Styled.InputWrapper>
    );
  }

  if (show) {
    return (
      <Styled.InputWrapper indentationLevel={indentationLevel} isVisible>
        <ComponentType
          type={inputType}
          {...props}
          indentationLevel={0}
          conditionalSetValue={setInputValue}
          {...childProps}
        />
      </Styled.InputWrapper>
    );
  }

  return null;
};

export default SelectControlledConditional;
