import React, { useEffect, useContext, useState } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import * as Styled from "./style";

import FormRegisterInfo from "../context/form-context";
import { getObjectByStringPath } from "../../helpers";

import Popover from "../popover";

export default function SwitchControlled(props) {
  const {
    label,
    name,
    disabled,
    indentationLevel = 0,
    popover,
    apiCallId,
    ...rest
  } = props;
  const { register, setValue, unregister } = useFormContext();
  const { state: globalFormState } = useContext(FormRegisterInfo);

  const [state, setState] = useState(false);

  useEffect(() => {
    register({ name: [name] });

    return () => {
      unregister(name);
    };
    // eslint-disable-next-line
  }, [register]);

  useEffect(() => {
    const formState = globalFormState[globalFormState.activeForm];
    const inputState = formState?.data && getObjectByStringPath(formState?.data, name);
    inputState && setState(inputState);
    // eslint-disable-next-line
  }, [globalFormState.activeForm]);

  useEffect(() => {
    setValue(name, state);
  }, [state, name, setValue]);

  useEffect(() => {
    return () => {
      setValue(name, !state);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setState(e.target.checked);
  };

  return (
    <Styled.SwitchWrapper indentationLevel={indentationLevel}>
      <FormControlLabel
        control={<Switch data-testid={name} />}
        label={label}
        name={name}
        onChange={handleChange}
        value={state}
        checked={state}
        disabled={disabled}
      />
      
      {popover && <Popover name={apiCallId || name} {...rest} />}
    </Styled.SwitchWrapper>
    
  );
}
