import styled from 'styled-components';

export const InputTextMaskedWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  display: ${props => props.invisible ? 'none' :  'flex'}
`

export const PopoverWrapper =  styled.div`
  display: flex;
  width: 50px;
  justify-content: center;
  align-self: center;
  margin-bottom: ${({error}) => error ? '38px' : '16px'};
`
