import React, { useContext, useEffect, useState } from "react";

import { Redirect } from "react-router-dom"

import FormRegisterContext from "components/context/form-context";
import { useNotification } from 'context/notification';
import api from "services";
import { fromMapToObject, timeout } from "helpers"
import { useUrlInfos } from "pages/Register/useUrlInfos";
import DraftButtons from "./DraftButtons";
import RegisterButtons from "./RegisterButtons";

const FormButtonSubmit = (props) => {
  const {
    setActualSubStep,
    state,
    actualStep,
    stepKey,
    stepComplete,
    actualSubStep,
    subStepComplete,
    helperState,
    clickInterceptor,
  } = useContext(FormRegisterContext);
  const [action, setAction] = useState("");
  const [requestError, setRequestError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isFormInvalid, setFormInvalid] = useState(false);
  const [message, setMessage] = useState("");

  const { id, type, draft: isDraft, version } = useUrlInfos();
  const { notifyError, notifyWarning, notifySuccess } = useNotification();

  const triggerValidation = () => {
    setActualSubStep((prev) => {
      return { ...prev };
    });
  };

  const getFormState = (registerType) => {
    const subStepCompleteObj = fromMapToObject(subStepComplete);
    if (registerType === "draft") {
      return {
        registerType: type,
        state: {
          actualStep,
          stepKey,
          stepComplete,
          actualSubStep,
          subStepCompleteObj,
          clickInterceptor,
          helperState
        },
        data: {
          state
        }
      }
    }
    return {
      registerType: type,
      schemaVersion: Number(version),
      state: {
        actualStep,
        stepKey,
        stepComplete,
        actualSubStep,
        subStepCompleteObj,
        clickInterceptor,
        helperState
      },
      data: {
        state
      },
      [isDraft ? 'draftId' : 'registerId']: id,
      hospitalId: state.General.data.hospital, 
      surgeonId: state.General.data.cirurgiao, 
      patient: state.PatientInfos.data
    };
  };

  const sendData = async (action, url, data, message) => {
    try {
      setRequestError(false);
      action === 'create' 
      ? await api.post(url, data)
      : await api.put(url, data)

      setMessage(message);
      setRedirect(true);
    } catch (error) {
      setRequestError(true);
    }
  };

  const checkForm = (steps) => {
    let isValid = true;

    for (const step of steps) {
      const stepIsValid = !!stepComplete[step];
      
      if(!stepIsValid) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  const executeAction = action => {
    const actions = {
      "draftCreate": async () => sendData('create', '/register/draft', getFormState('draft')),
      "draftUpdate": async () => sendData('update', `/register/draft/${id}`, getFormState('draft'), 'Rascunho salvo'),
      "draftFinish": async () => {
        setFormInvalid(false);

        const formIsValid = checkForm(["generalData", "history", "procedure"]);
        if(formIsValid) {
          sendData('create', '/register', getFormState('register'), 'Registro salvo');
          return;
        }

        await timeout(500);
        setMessage('As etapas <strong>Dados Gerais</strong>, <strong>História</strong>e  <strong>Procedimento</strong> são obrigatórias.')
        setFormInvalid(true);
        setAction("")
      },
      "registerUpdate": async () => {
        setFormInvalid(false);

        const formIsValid = checkForm(["generalData", "history", "procedure"]);
        if(formIsValid) {
          sendData('update', `/register/register/${id}`, getFormState('register'), 'Segmento atualizado');
          return;
        }

        await timeout(500);
        setFormInvalid(true);
        setAction("")
      },
      "registerFinish": async () => {
        setFormInvalid(false);

        const formIsValid = checkForm(["generalData", "history", "procedure", "postoperative"]);
        if(formIsValid) {
          sendData('update', `/register/register/${id}`, {...getFormState('register'), complete: true}, 'Registro finalizado');
          return;
        }

        await timeout(500);
        setMessage('As etapas <strong>Dados Gerais</strong>, <strong>História</strong>, <strong>Procedimento</strong> e <strong>Pós-Operatório</strong> são obrigatórias.')
        setFormInvalid(true);
        setAction("")
      }
    }
    return actions[action]
  }

  useEffect(() => {
    if(!action) return;

    executeAction(action)();

// eslint-disable-next-line
  }, [clickInterceptor]);

  const hasNotId = !id;

  useEffect(() => {
    if(!requestError) return;

    notifyError('Ocorreu um erro! Tente novamente');
  }, [ requestError ]);

  useEffect(() => {
    if(!isFormInvalid) return;

    notifyWarning(<div dangerouslySetInnerHTML={{__html: message}}/>);
  }, [ isFormInvalid ]);

  useEffect(() => {
    if(!redirect) return;

    notifySuccess(message)
  }, [ redirect ]);

  return (
    <div style={{ marginLeft: "auto" }}>

      {isDraft || hasNotId ? (
        <DraftButtons 
          setAction={setAction}
          triggerValidation={triggerValidation}
          draftId={id}
        />
      ) : (
        <RegisterButtons
          setAction={setAction}
          triggerValidation={triggerValidation}
        />
      )}
      
      {redirect && (
        <Redirect push to="/dashboard"/>
      )}
    </div>
  );
};

export default FormButtonSubmit;
