import styled from 'styled-components';

export const InputTextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const PopoverWrapper =  styled.div`
  display: flex;
  width: 50px;
  justify-content: center;
  align-self: center;
  margin-bottom: ${({error}) => error ? '38px' : '16px'};
`
