import React from 'react';
import { Box, Grid, IconButton, makeStyles, MenuItem } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Select, { DefaultSelect } from './Select';
import InputByType from './InputByType';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
  },
  buttom: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

const Fields = ({
  fields = {},
  fieldsTypes = {},
  operators = {},
  onDelete,
  onChange,
  formState,
}) => {
  const classes = useStyles();
  const fieldIsEmpty = formState['field'] === '';
  const operatorIsEmpty = formState['operator'] === '';

  const handleChange = ({ target: { name, value } }) => {
    const isField = name === 'field';
    const isValueArray = Array.isArray(value);
    onChange(({
      ...formState,
      [name]: isValueArray ? [...value] : [value],
      ...(isField ? { operator: '' } : {})
    }));
  }

  return (
    <Grid container spacing={1} classes={{ root: classes.container }}>
      <Grid item lg={3} xs={6}>
        <DefaultSelect 
          label='Campo'
          name='field'
          state={formState['field']}
          onChange={handleChange}
          data={Object.entries(fields).map(([ name, { label } ]) => ({ value: name, label }))}
        />
      </Grid>
      <Grid item lg={3} xs={6}>
        <Select 
          label='Condição'
          name='operator'
          state={formState['operator']}
          onChange={handleChange}
          disabled={fieldIsEmpty}
        >
        {!fieldIsEmpty && fieldsTypes[fields[formState['field']]['type']]['operators'].map(operator => (
          <MenuItem key={operator} value={operator}>{operators[operator]}</MenuItem>
        )) }
        </Select>
      </Grid>
      <Grid item lg={4} xs={10}>
        {!operatorIsEmpty ? (
          <InputByType
            formState={formState} 
            fields={fields}
            label='Valor'
            name='values'
            state={formState['values']}
            onChange={handleChange}
          />
        ) : (
          <Select 
            label='Valor'
            name='value'
            disabled={true}
            value={''}
          />
        )}
      </Grid>
      <Grid item lg={1} xs={2}>
        <Box display='flex' alignItems='center' height='100%' className={classes.buttom}>
          <IconButton size='small' onClick={onDelete}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Fields;