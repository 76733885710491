import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
        padding: '84px 10px 10px 10px',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            margin: '64px 0 0',
            padding: '0 10px',
        },
    }
}));

export default function MainContainer(props) {
    const { children, overflow = 'hidden' } = props;
    const classes = useStyles();

    return (
        <div className={classes.mainContainer} style={{ overflow }}>
            {children}
        </div>
    )
}
