import React, { useMemo } from "react";
import Avatar from '@material-ui/core/Avatar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import { makeStyles } from "@material-ui/core/styles";
import * as Styled from "./style";

const useStyles = makeStyles((theme) => ({
  primary: {
    whiteSpace: 'nowrap',
    width: 'calc(100% - 50px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
  
}));


export const FilesManager = props => {
  const { files, handleDelete } = props;
  const classes = useStyles();

  const lists = useMemo(() => {
    return files.reduce((lists, cur, index) => {
      const isEven = index % 2 === 0;
      isEven ? lists[0].push(cur) : lists[1].push(cur);
      return lists;
    }, [[], []]);
  }, [files]);

  return (
    <div>
      <p>Arquivos</p>
      <Styled.Div>
      
        {lists.map((list, index) => (
          <List key={list + index} dense={true} style={{width: 'calc(50% - 15px)'}}>
            {list.map(({ originalName, url, size, id }) => (
              <Styled.ListItem key={id} id={id}>
                <Styled.Anchor href={url} target="_blank" rel="noopener noreferrer" download>
                  <Avatar style={{marginRight: '15px'}}>
                    <InsertDriveFileIcon />
                  </Avatar>
                  <ListItemText primary={originalName} secondary={`${size} KB`} classes={classes} />
                  </Styled.Anchor>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(id, url)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                
              </Styled.ListItem>
            ))}
          </List>
        ))}

      </Styled.Div>
    </div>
  )
}

