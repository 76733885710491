import React from "react";
import * as Styled from "./style";
import { Button } from "@material-ui/core";

const FormButtons = React.memo(props => {
  const { 
    stepLabel, 
    subStepLabel, 
    actualStep, 
    actualSubStep: { index: actualSubStep },
    setActualSubStep
  } = props;

  const subStepLabels = subStepLabel[stepLabel[actualStep].schemaName];
  const isLastStep = stepLabel.length -1 === actualStep;
  const isLastSubStep = subStepLabels?.length -1 === actualSubStep;
  const isFirstStep = actualStep === 0;
  const isFirstSubStep = actualSubStep === 0;

  const move = (direction, isLastOrFirstSubStep, cb) => {

    const selector = isLastOrFirstSubStep 
    ? stepLabel[actualStep + direction]?.schemaName
    : subStepLabels[actualSubStep + direction]?.schemaName;

    if(!selector) return;
    document.querySelector(`[data-testid="${selector}"]`).click();
    if(cb) cb();
  };

  const stepFoward = () => {
    move(1, isLastSubStep, () => {
      isLastSubStep && setActualSubStep(prev => ({
        trigger: !prev.trigger ?? true,
        index: 0
      })); 
    });
  }

  const stepBack = () => {
    move(-1, isFirstSubStep, () => {
      const prevSubStepLabels = subStepLabel[stepLabel[actualStep - 1]?.schemaName]

      isFirstSubStep && setActualSubStep(prev => ({
        trigger: prev.trigger ?? true,
        index: prevSubStepLabels.length -1
      }));

    });
  }

  return(
    <Styled.ButtonsContainer>
      <Button
        variant="contained"
        onClick={stepBack}
        disabled={isFirstStep && isFirstSubStep}
      >
      Anterior
      </Button>
      <Button
        variant="contained"
        onClick={stepFoward}
        disabled={isLastStep && isLastSubStep}
      >
      Próximo
      </Button>
    </Styled.ButtonsContainer>
  )
})

export default FormButtons;