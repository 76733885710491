import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  row: {
    height: '55%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      margin: 'auto'
    }
  },
  rowBottom: {
    height: '45%',
    padding: '12px 0 0',
    
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      margin: 'auto'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%'
  },
  paddingPaper: {
    [theme.breakpoints.down('md')]: {
      padding: '10px 10px 0'
    }
  },
  chield: {
    height: '100%',
    maxHeight: '100%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '300px'
    }
  },
  container: {
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      overflow: 'hidden',
    }
  }
}));