import React, { useState } from "react";

import { Popover, Typography, makeStyles, Divider, Box } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { useGetPopoverData } from 'hooks/useGetPopoverData';

const useStyle = makeStyles((theme) => ({
  popoverTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    fontSize: '14px',
  },
  popoverText: {
    fontSize: '14px',
    padding: theme.spacing(2),
  },
  popoverCard: {
    maxWidth: '500px',
    maxHeight: '250px',
  },
  popoverButton: {
    cursor: `pointer`,
  },
}));

function PopoverWrapper(props) {
  const classes = useStyle();

  const {
    name,
    imageName,
    imageWidth,
    imageHeight,
    hasText = true,
    hasImage = false,
    formSchemaVersion, 
    formSchemaType
  } = props;

  const popoverData = useGetPopoverData({ name, hasText, formSchemaType, formSchemaVersion });

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverHandleClick = (event) => setAnchorEl(event.currentTarget);
  const popoverHandleClose = () => setAnchorEl(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? name : undefined;

  return (
    <>
      <HelpIcon
        fontSize="small"
        onClick={popoverHandleClick}
        className={classes.popoverButton}
      />
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={popoverHandleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box className={classes.popoverCard}>
          {popoverData.title && (
            <>
              <Typography
                variant="button"
                display="block"
                gutterBottom={true}
                className={classes.popoverTitle}
              >
                {popoverData.title}
              </Typography>
              <Divider variant="middle" />
            </>
          )}
          <Typography component={"div"} className={classes.popoverText}>
            {hasText &&
              popoverData?.data?.map((item, index) => <div key={index}><strong>{item.subtitle}</strong>{item.text}</div>)}
            {hasImage && (
              <img
                width={imageWidth}
                height={imageHeight}
                src={`https://storage.googleapis.com/rebrave-assets/${imageName}`}
                alt={imageName}
              ></img>
            )}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default PopoverWrapper;
