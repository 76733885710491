import React, { useContext } from 'react';
import ComponentType from "../../componentType";
import FormRegisterInfo from "../../context/form-context";
import { useFormContext } from "react-hook-form";


const InputClone = props => {
  const { name, form, inputSource, inputCloneType  } = props;
  const { state: globalFormState } = useContext(FormRegisterInfo);
  const { setValue } = useFormContext();

  const setInputValue = () => {
    const watchState = globalFormState[form]?.data?.[inputSource];
    const inputState = globalFormState[globalFormState.activeForm]?.data?.[name];
    
    inputState 
     ? setValue(name, inputState, true) 
     : watchState && setValue(name, watchState, true);
  };

  return (
    <ComponentType
      type={inputCloneType}
      {...props}
      conditionalSetValue={setInputValue}
    />
  )
}

export default InputClone;