import React, { useState, useEffect } from 'react';

import { Box, Typography, Grid } from '@material-ui/core';

import ChartPie from "components/Chart/Pie/ChartPie";
import { hospitalTypeDictionary } from './consts';

function HospitalTypeChart({ data }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(() => data.map(({ type, count }) => ({
      id: type,
      label: hospitalTypeDictionary[type].label,
      value: count,
      color: hospitalTypeDictionary[type].color,
    })));
  }, [ data ]);

  return (
    <Grid container direction='column' alignItems='center'>
      <Typography align='center' style={{ color: '#424242' }} variant='h6' gutterBottom>Tipos de Hospitais</Typography>
      <Box width='300px' height='300px'>
        <ChartPie 
          data={chartData}
          margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
          legendItemWidth={100}
          legendAnchor='bottom'
          legendDirection='row'
          legendTranslateY={56}
          legendTranslateX={15}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          colors={{ datum: 'data.color' }}
          innerRadius={0.015}
          cornerRadius={4}
          padAngle={2}
        />
      </Box>
    </Grid>
  );
};

export default HospitalTypeChart;