import React from 'react';

import { Divider, Typography, Box } from '@material-ui/core';

import { Table } from 'domain/BiannualAnnual/Table';
import { FemoralPunctureChart } from 'domain/BiannualAnnual/FemoralPunctureChart';

function FemoralPunctureSection({ startDate, endDate, tableData, chartData, limitDate }) {
  return (
    <>
      <Box textAlign='center' mb={4}>
        <Divider />
        <Typography variant='subtitle1' style={{ marginTop: 16 }}>
          Periférico: Percentual de punções femorais ecoguiadas
        </Typography>
        <Typography variant='subtitle2' style={{ lineHeight: 'initial', marginTop: -6, fontSize: 13 }}>
          Procedimentos realizados entre {startDate} e {endDate}
        </Typography>
      </Box>
      <Box maxWidth={715} width='100%' margin='auto'>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
          <p style={{ textAlign: 'center', lineHeight: 1.2, fontSize: '11px' }}>
            Pacientes com isquemia crítica com procedimentos realizados em até 5 dias da internação.
          </p>
        </Typography>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
          <p style={{ textAlign: 'justify', lineHeight: 1.2, fontSize: '11px', fontStyle: 'italic' }}>
            Dados nesse relatório incluem todos os casos de cirurgia entre {startDate} e {endDate}, 
            que tiveram os dados incluídos no “registro” até {limitDate}. A tabela abaixo mostra os dados
            de procedimentos periféricos em pacientes com isquemia crítica no “Registro”.
          </p>
        </Typography>
      </Box>
      <Box>
        <Table rowsData={tableData}/>
      </Box>
      <Box>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
        <small>
          <p style={{ lineHeight: 1.2, textAlign: 'justify' }}>
           ² Inagaki E, Farber A, Siracuse JJ, Mell MW, Rybin DV, Doros G, Kalish J; Vascular Quality Initiative. Routine Use of Ultrasound Guidance in Femoral Arterial Access for Peripheral Vascular Intervention Decreases Groin Hematoma Rates in High-Volume Surgeons. Ann Vasc Surg. 2018 Aug;51:1-7. doi:10.1016/j.avsg.2018.02.008. Epub 2018 Apr 13. PMID: 29655812.
          </p>
          <p style={{ lineHeight: 1.2, textAlign: 'justify' }}>
           ³  Khalil MS, Hassan HA, I. Aboloyoun HE, Nageeb AM. Access-site complications after peripheral vascular interventions in patients with peripheral arterial disease at Assiut University Hospital. J Curr Med Res Pract 2020;5:433-8.
          </p>
        </small>
        </Typography>
      </Box>
      <Box mt={8}>
        <FemoralPunctureChart data={chartData} />
      </Box>
    </>
  );
}

export default FemoralPunctureSection;
