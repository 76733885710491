import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[200]
  }
}));

const RoundedButton = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="add"
      size="small"
      color="default"
      classes={classes}
      {...rest}
    >
      {children}
    </IconButton>
  );
}

export default RoundedButton;