import React from 'react';
import {
  Typography,
  Box,
} from '@material-ui/core';

import useStyles from './style';

const Description = props => {
  const classes = useStyles();

  return (
    <Box mb={3}>
      <Typography
        variant='h5'
        classes={{ root: classes.title }}
      >
        Procedimentos: {props.startDate} a {props.endDate}
      </Typography>
    </Box>
  );
};

export default Description;