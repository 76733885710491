import React, { useContext, useMemo } from "react";
import FormRegisterInfo from "../context/form-context";

import Formulario from "./Formulario";

import { dynamicValidation } from "./validation/DynamicValidation";
import { memoize } from "../../helpers";


const dynamicValidationMemo = memoize(dynamicValidation);

const Form = (props) => {
  const { formSchema, complete } = props;
  const { clickInterceptor } = useContext(
    FormRegisterInfo
  );

  // eslint-disable-next-line
  const jsonSchema = useMemo(() => formSchema[clickInterceptor.index], [clickInterceptor]);
  
  const schemaName = jsonSchema.schemaName;

  const specialValidationTypes = {
    array: jsonSchema.children[0]?.props?.children,
    arrayOfTabs: jsonSchema.children?.[0]?.props?.children?.[0]?.props?.children,
    tabs: jsonSchema.children?.[0]?.props?.children
  }
  const schemaChildren = specialValidationTypes[jsonSchema.formValidationType] ?? jsonSchema.children;
  const validation = dynamicValidationMemo(jsonSchema.formValidationType, schemaChildren, schemaName);

  return (
    <Formulario type={schemaName} json={jsonSchema} validation={validation} complete={complete} />
  );
};

export default Form;
