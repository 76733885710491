import React from 'react';
import { MuiPickersUtilsProvider, DatePicker as DatePickerMui } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DatePicker = ({ state, onChange, name, ...props }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePickerMui
      fullWidth
      format='dd/MM/yyyy'
      variant='inline'
      autoOk={true}
      disableToolbar
      onChange={date => onChange({ target: { name, value: date } })}
      value={state[0] ?? null}
      inputVariant='outlined'
      disableFuture={true}
      size='small'
      {...props}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;