import React from 'react';

import { InputTextMasked } from '../../../../components';

const NumberInput = (props) => (
  <InputTextMasked
    {...props}
    options={{
      "numeral": true,
      "numeralDecimalMark": ".",
      "numeralDecimalScale": 2,
      "numeralIntegerScale": 3,
      "numeralPositiveOnly": true
    }}
    variant='outlined'
    size='small'
    fullWidth
  />
);

export default NumberInput;