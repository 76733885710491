import React, { useEffect } from 'react';

import { Snackbar } from 'components/SnackBar';

import { Container } from './style';

const AUTO_DISMISS = 6000;

function Notifications({ data, setNotifications }) {
  useEffect(() => {
    if (data.length > 0) {
      const timer = setTimeout(() => setNotifications((notifications) => notifications.slice(0, notifications.length - 1)), AUTO_DISMISS)
      return () => clearTimeout(timer)
    }
  }, [ data ])

  return (
    <Container>
      {data.map(({ message, type }, index) =>(
        <Snackbar
          key={`${message}-${index}`}
          alertType={type}
        >
          {message}
        </Snackbar>
      ))}
    </Container>
  );
};

export default Notifications;