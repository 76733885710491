import { makeStyles } from "@material-ui/core/styles";
import { green, red } from '@material-ui/core/colors';

export default makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  th: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,

      '&:not(:last-child)': {
        paddingRight: 6,
      }
    },
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  tableWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  icon: {
    margin: '0 6px 0 0',
    width: 12,
    height: 12,
  },
  tableRowHighlight: {
    background: theme.palette.grey['400'],
  },
  bold: {
    fontWeight: 600,
  },
  greenBackground: {
    background: green['A100'],
  },
  redBackground: {
    background: red['A100']
  },
  firstColumn: {
    width: '30%',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  secondColumn: {
    width: '40%',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  }
}));