import React, { createContext, useContext, useState, useEffect } from "react";

import createAuthRefreshInterceptor from 'axios-auth-refresh';

import api from "../services/index";
import { timeout } from 'helpers';

const AuthContext = createContext();

const AuthProvider = props => {
  const existingTokens = localStorage.getItem("token");
  const [authToken, setAuthToken] = useState(() => existingTokens);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const setToken = (token = "") => {
    localStorage.setItem("token", token);
    setAuthToken(token);
    !token && setTokenIsValid(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const [ response ] = await Promise.all([
        api.get('/auth/verify'),
        timeout(3000),
      ]);
      
      const tokenIsValid = response.data.isValid
  
      setTokenIsValid(tokenIsValid ? true : false);
      if(!tokenIsValid) {
        setToken();
      }
      setIsValidatingToken(false);
    }
    verifyToken();
    // eslint-disable-next-line
  }, []);

  const failureRequest = () => {
    setTokenIsValid(false);
    setIsLoginModalOpen(true);
  };
  
  createAuthRefreshInterceptor(api, failureRequest, {
    pauseInstanceWhileRefreshing: true,
  });

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthTokens: setToken,
        setTokenIsValid,
        tokenIsValid,
        isValidatingToken,
        isLoginModalOpen,
        setIsLoginModalOpen,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
