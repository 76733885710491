import React from 'react';

import {
  Box,
  TableContainer,
  Table as TableMui,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import useStyle from './style';

function Table ({ rowsData }) {
  const classes = useStyle();

  return (
    <Box px={2} className={classes.tableWrapper}>
      <TableContainer>
        <TableMui size="small" aria-label="a dense table" padding="none">
          <TableHead>
            <TableRow>
              <TableCell align='center' className={classes.firstColumn} classes={{ head: classes.th }}></TableCell>
              <TableCell align='center' className={classes.secondColumn} classes={{ head: classes.th }}>
                Centro
              </TableCell>
              <TableCell align='center' classes={{ head: classes.th }}>
                Nacional
              </TableCell>
              <TableCell align='center' classes={{ head: classes.th }}>
                Objetivo
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.rows.map(({ highlight, cells }, index) =>(
              <TableRow key={index}>
                {cells.map(({ text, bold, percentile }, i) => (
                  <TableCell
                    key={i}
                    align={i !== 0 ? 'center' : 'left'}
                    classes={{ root: classes.tableCell }}
                  >
                    {text}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableMui>
      </TableContainer>
    </Box>
  );
};

export default Table;