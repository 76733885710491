import React, { useState, useEffect, useRef } from "react";

import { IconButton, Box } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import useSWR from 'swr';
import { useHistory } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';

import api, { swrFetcher } from 'services';
import timeout from 'helpers/timeout';
import SideBar from "components/sideBar"
import MainContainer from "components/mainContainer";
import { Table } from "components/Table"
import { useUser } from "context/user";
import { useNotification } from 'context/notification';
import { SurgicalDescription } from './SurgicalDescription';

import * as Styled from "./style";

const restrictColumns = {
  "Super Admin": {
    userColumn: true,
    teamColumn: true,
  },
  "Team Leader": {
    userColumn: true,
    teamColumn: 'excluded',
  },
  "User": {
    userColumn: 'excluded',
    teamColumn: 'excluded',
  }
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const Registers = props => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [dataToPrint, setDataToPrint] = useState({});

  const { userInfos: { role: userRole } } = useUser();
  const { notifyError, notifySuccess } = useNotification();
  const history = useHistory();
  const { data: requestData, error } = useSWR('/register', swrFetcher);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (requestData) setData(requestData);
    if (error) console.error(error);
  }, [ requestData, error]);

  async function onRowDelete (dataIndex) {
    const { id } = data[dataIndex];
    
    try {
      await api.delete(`/register/${id}`);
      setData((prev) => [...prev.filter((item, index) => index !== dataIndex)]);
      notifySuccess('Registro deletado com sucesso');
    } catch (error) {
      console.error('error', error);
      notifyError('Ops, ocorreu algum erro...');
    }

  }

  function onRowEdit (dataIndex) {
    const { id, typeId, schemaVersion } = data[dataIndex];
    history.push(`/register/${id}?type=${typeId}&version=${schemaVersion}`);
  }

  async function onRowPrint (dataIndex) {
    try {
      setDataToPrint({});
      setIsBackdropOpen(true);

      await timeout(1000);

      const apiResponse = await api.get(`/reports/surgical-description/${data[dataIndex]?.id}`);
      setDataToPrint(apiResponse.data);
    } catch (error) {
      console.error('error', error);
      notifyError('Ops, ocorreu algum erro...');
    } finally {
      setIsBackdropOpen(false);
    }
  }

  useEffect(() => {
    if(!Object.keys(dataToPrint).length) return;

    handlePrint();

  }, [handlePrint, dataToPrint]);

  const columns = [
    {
      name: "Delete",
      label: "Deletar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton onClick={() => {
              onRowDelete(dataIndex);
            }}>
              <DeleteIcon />
              </IconButton>
          );
        }
      }
    },
    {
      name: "Show",
      label: "Visualizar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
          <Styled.IconWrapper>
            <IconButton edge='start' onClick={() => {
              onRowEdit(dataIndex);
            }}>
              <VisibilityIcon />
            </IconButton>
          </Styled.IconWrapper>
          );
        }
      }
    },
    {
      name: "surgicalDescription",
      label: "Descrição Cirurgica",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton onClick={() => {
              onRowPrint(dataIndex);
            }}>
              <PrintIcon />
              </IconButton>
          );
        }
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        viewColumns: false,
        display: 'excluded',
      }
    },
    {
      name: "user",
      label: "Usuário",
      options: {
        display: restrictColumns[userRole]?.userColumn ?? 'excluded',
        filterType: 'multiselect'
      }
    },
    {
      name: "team",
      label: "Equipe",
      options: {
        display: restrictColumns[userRole]?.teamColumn ?? 'excluded',
        filterType: 'multiselect'
      }
    },
    {
      name: "type",
      label: "Tipo"
    }, 
    {
      name: "admissionDate",
      label: "Data Admissão",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'Data vazia'
        }
      }
    }, 
    {
      name: "createdAt",
      label: "Criado em",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleDateString('pt-BR');
        }
      }
    }, 
    {
      name: "patientName",
      label: "Nome do Paciente",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'Nome vazio'
        }
      }
    }
  ];

  return (
    <MainContainer>
      <SideBar title="Registros" />
      <Box pb='10px'>
        <Table 
          title="Registros"
          data={data}
          onRowEdit={onRowEdit}
          onRowDelete={onRowDelete}
          tableColumns={columns}
        />
      </Box>
      <Backdrop className={classes.backdrop} open={isBackdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box style={{ display: 'none' }}>
        <SurgicalDescription ref={componentRef} data={dataToPrint} />
      </Box>
    </MainContainer>
  )
}