import React, { useState, useEffect, useRef, useContext } from "react";
import ComponentType from "../componentType";
import FormRegisterInfo from "../context/form-context";
import { useFormContext } from "react-hook-form";
import * as Styled from "./FieldArray.style";
import { v4 as uuidv4 } from 'uuid';
import { getObjectByStringPath } from "../../helpers"

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  
}));

const useStyles2 = makeStyles({
  root: {
    padding: "0 16px 0 8px"
  },
  content: {
    margin: "0",
    alignItems: "center",

    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {}
})

const FieldArray = (props) => {
  const { name, title, children, parentName, buttonTitle, ...rest } = props;

  const { state: globalFormState } = useContext(FormRegisterInfo);
  const { setValue } = useFormContext();

  const [data, setData] = useState([]);
  const [state, setState] = useState([]);

  const isFirstRun = useRef(true);
  const counter = useRef(0);
  
  const classes = useStyles();
  const classesPanelSummary = useStyles2();


  useEffect(() => {
    const formState = globalFormState[parentName];

    if (formState) {
      const { data: {data} } = formState;
      if(!data) return;
      // eslint-disable-next-line
      data.map( (obj, index) => {
        const sourceObj = getObjectByStringPath(obj, name);
        // eslint-disable-next-line
        if(!obj) return;
        // eslint-disable-next-line
        if(!sourceObj) return;
        
        const newObj = Object.entries(sourceObj).reduce((acc, [key, value]) => {
          return [...acc, { [`data[${index}][${name}][${key}]`]: value }];
        }, []);

        duplicate(undefined, index);
        setState((prev) => [...prev, newObj]);
        counter.current = index + 1;
      });
      return;
    }
    // duplicate();
    // eslint-disable-next-line
  }, []);

  const duplicate = (id = uuidv4(), key = counter.current) => {
    setData((prev) => [...prev, {id: id, children, key: key}])
    counter.current++;
  };

  const remove = (event, index) => {
    event.stopPropagation();
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
  }

  useEffect(() => {
    state.map((obj) => setValue(obj, true));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setExpanded(data.length < 2 ? "panel0" : false);
  }, [data]);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Styled.Container>
      <Button
        variant="contained"
        color="primary"
        data-testid={"btn-duplicate"}
        type="button"
        onClick={() => duplicate()}
        style={{
          alignSelf: "flex-end",
          margin: "0 0 12px",
        }}
        disabled={props.disabled}
      >
        { buttonTitle ?? `Adicionar ${title}`}
      </Button>
      
      {data.map(({id, children: group, key}, index) => {
        const groupName = `data[${key}][${name}]`;
        

        return (
          <ExpansionPanel
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={`panel-${id}-key`}
            square={false}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              classes={classesPanelSummary}
            >
              <IconButton
                variant="contained"
                color="secondary"
                onClick={(event) => remove(event, index)}
                onFocus={(event) => event.stopPropagation()}
                aria-label="remove"
              >
                {<DeleteIcon fontSize="small"/>}
              </IconButton>
              <Typography>{`${title} - ${index + 1}`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={classes}>
              {group.map(({ type, props }) => {
                return (
                  <Styled.ListItem key={`${props.name}-${id}`}>
                    <ComponentType
                      type={type}
                      {...rest}
                      {...props}
                      name={`${groupName}[${props.name}]`}
                      watchFor={
                        props.watchFor
                          ? `${groupName}[${props.watchFor}]`
                          : null
                      }
                      isFieldArrayChild={true}
                      fieldArrayName={groupName}
                      fieldArrayChildName={props.name}
                    />
                  </Styled.ListItem>
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Styled.Container>
  );
};

export default FieldArray;
