import React from "react";
import InputTextMasked from "../../Form/Inputs/InputTextMasked";
import { useFormContext } from "react-hook-form";

export default function InputText({
  label,
  name,
  Controller,
  classes,
  options, 
  defaultValue,
  manualError,
  ...rest
}) {
  const { control, errors } = useFormContext();
  const internalErrors = manualError ? manualError : errors;
  
  return (
    <Controller
      as={
        <InputTextMasked
          label={label}
          id={name}
          fullWidth
          options={options}
          className={classes.creatinina}
          helperText={internalErrors[name] && internalErrors[name].message}
          error={!!internalErrors[name]}
          data-testid={name}
          {...rest}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}
