import React from 'react';

import { Divider, Typography, Box } from '@material-ui/core';

import { Table } from 'domain/BiannualAnnual/Table';
import { CriticalIschemicChart } from 'domain/BiannualAnnual/CriticalIschemicChart';

function IschemicSection({ startDate, endDate, tableData, chartData, limitDate }) {
  return (
    <>
      <Box textAlign='center' mb={2}>
        <Divider />
        <Typography variant='subtitle1' style={{ marginTop: 16 }}>
          Periférico: Revascularização de isquemia crítica em até 5 dias
        </Typography>
        <Typography variant='subtitle2' style={{ lineHeight: 'initial', marginTop: -6, fontSize: 13 }}>
          Procedimentos realizados entre {startDate} e {endDate}
        </Typography>
      </Box>
      <Box maxWidth={715} width='100%' margin='auto'>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
          <p style={{ textAlign: 'center', lineHeight: 1.2, fontSize: '11px' }}>
            Pacientes com isquemia crítica com procedimentos realizados em até 5 dias da internação.
          </p>
        </Typography>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
          <p style={{ textAlign: 'justify', lineHeight: 1.2, fontSize: '11px', fontStyle: 'italic' }}>
            Dados nesse relatório incluem todos os casos de cirurgia entre {startDate} e {endDate}, 
            que tiveram os dados incluídos no “registro” até {limitDate}. A tabela abaixo mostra os dados
            de procedimentos periféricos em pacientes com isquemia crítica no “Registro”.
          </p>
        </Typography>
      </Box>
      <Box>
        <Table rowsData={tableData}/>
      </Box>
      <Box>
        <Typography align='center' style={{ color: '#424242' }} variant='caption' gutterBottom>
        <small>
          <p style={{ lineHeight: 1.2, textAlign: 'justify' }}>
          ¹ Vascular Society of Great Britain and Ireland. A Best Practice Clinical Care Pathway for Peripheral Arterial Disease. 2019.
          <a href="https://www.vsqip.org.uk/resources/quality-improvement/quality-improvement-lower-limb-ischaemia/">https://www.vsqip.org.uk/resources/quality-improvement/quality-improvement-lower-limb-ischaemia/</a>
          </p>
        </small>
        </Typography>
      </Box>
      <Box mt={8}>
        <CriticalIschemicChart data={chartData} />
      </Box>
    </>
  );
};

export default IschemicSection;
