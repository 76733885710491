import { useParams, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useUrlInfos = () => {
  const { id } = useParams();
  const query = useQuery();
  const type = query.get("type");
  const draft = query.get("draft");
  const version = query.get("version");
  return { id, type, draft, version }
};
