import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Styled from './style';

export const Dropzone = props => {
  const { setSelectedFiles, disabled } = props;
  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles(acceptedFiles);
  }, [setSelectedFiles])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop, disabled: disabled, maxSize: 5 * 1024 * 1024 });
  
  return (
    <div className="container">
      <Styled.Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>Arraste alguns arquivos ou click aqui</p>
      </Styled.Container>
    </div>
  );
}