import styled, { keyframes } from 'styled-components';

const getBoxShadow = (r, g, b, alpha, spread = 0) => `0 0 0 ${spread}px rgba(${r}, ${g}, ${b}, ${alpha})`;

const generateCustomPulse = (r, g, b) => keyframes`
0% {
  transform: scale(0.8);
  box-shadow: ${getBoxShadow(r, g, b, 0.7)};
}

70% {
  transform: scale(1);
  box-shadow: ${getBoxShadow(r, g, b, 0, 10)};
}

100% {
  transform: scale(0.8);
  box-shadow: ${getBoxShadow(r, g, b, 0)};
}
`;

const simplePulse = keyframes`
  0% {
    transform: scale(0.8);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #fff;
`;

export const Image = styled.img`
	transform: scale(1);
	animation: ${simplePulse} 2s infinite;
`;

export const Blob = styled.div`
  background: ${props => props.color};
	border-radius: 50%;
	margin: 10px;
	height: 12px;
	width: 12px;

  box-shadow: ${({ r, g, b }) => getBoxShadow(r, g, b, 1)};

  transform: scale(1);
  animation: ${props => generateCustomPulse(props.r, props.g, props.b)} 2s infinite;
`;

export const BlobWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;