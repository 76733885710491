import React from "react";

import { useFormContext } from "react-hook-form";
import { TextField } from "@material-ui/core";
import * as Styled from "./style";
import Popover from "../../../popover";

import { getObjectByStringPath } from "../../../../helpers"

export default function InputTextControlled({
  label,
  name,
  apiCallId,
  Controller,
  classes,
  popover,
  defaultValue,
  disabled,
  ...rest
}) {
  const { control, errors } = useFormContext();

  const errorObject = getObjectByStringPath(errors, name);

  return (
    <Styled.InputTextWrapper>
      <Controller
        as={
          <TextField
            label={label}
            id={name}
            fullWidth
            className={classes.creatinina}
            helperText={errorObject?.message}
            error={!!errorObject}
            inputProps={{
              "data-testid": name
            }}
            variant="outlined"
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <Styled.PopoverWrapper error={errors[name]}>
        {popover && <Popover name={apiCallId || name} classes={classes} {...rest} />}
      </Styled.PopoverWrapper>
    </Styled.InputTextWrapper>
  );
}
