import http from "./index";

export const uploadFile = (files, id, isDraft,  onUploadProgress) => {
  let formData = new FormData();

  for (const file of files) formData.append("file", file);

  formData.append(isDraft ? 'registerDraftId' : 'registerId', id);
  
  return http.post("/register/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress
  });
};