import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
`;
