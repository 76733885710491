import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import useSWR from 'swr';

import SideBar from "components/sideBar"
import { Table } from "components/Table"
import MainContainer from "components/mainContainer";
import api, { swrFetcher } from 'services';
import { useNotification } from 'context/notification';

export const Drafts = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { notifyError, notifySuccess } = useNotification();
  const { data: requestData, error } = useSWR('/register/drafts', swrFetcher);

  useEffect(() => {
    if (requestData) setData(requestData);
    if (error) console.error(error);
  }, [ requestData, error]);

  async function onRowDelete (dataIndex) {
    const { id } = data[dataIndex];
    
    try {
      await api.delete(`/register/draft/${id}`);
      setData((prev) => [...prev.filter((item, index) => index !== dataIndex)]);
      notifySuccess('Rascunho deletado com sucesso');
    } catch (error) {
      console.error('error', error);
      notifyError('Ops, ocorreu algum erro...');
    }

  }

  function onRowEdit (dataIndex) {
    const { id, typeId, schemaVersion } = data[dataIndex];
    history.push(`/register/${id}?draft=true&type=${typeId}&version=${schemaVersion}`);
  }

  return (
    <MainContainer>
      <SideBar title="Rascunhos" />
      <Box pb='10px'>
        <Table
          title="Rascunhos"
          data={data}
          onRowEdit={onRowEdit}
          onRowDelete={onRowDelete}
        />
      </Box>
    </MainContainer>
  )
};
