import React from 'react';

import { ResponsiveBar } from '@nivo/bar'

const ChartBar = ({ data, ...rest }) => (
    <ResponsiveBar
        data={data}
        margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
        padding={0.3}
        labelSkipWidth={12}
        labelSkipHeight={12}
        {...rest}
    />
)

export default ChartBar;
