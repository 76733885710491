import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
`

export const PopoverWrapper =  styled.div`
  display: flex;
  width: 50px;
  justify-content: center;
  align-self: ${({error}) => error && "center"};
  margin-bottom: ${({error}) => error ? '22px' : '16px'};
`
