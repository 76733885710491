import styled from 'styled-components';


export const TextareaWrapper =  styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`
export const Textarea = styled.textarea`
  min-height: 100px;
  max-width: 100%;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;

  :focus {
    border: 2px solid ${props => props.primary.main};
  }
`

export const Label = styled.label`
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin: 0 0 6px;
`