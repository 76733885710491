export default {
  "proceduresByMonth": [
      {
          "id": "ATP P...",
          "data": [
              {
                  "x": "Dez",
                  "y": "1"
              },
              {
                  "x": "Jan",
                  "y": "5"
              },
              {
                  "x": "Fev",
                  "y": "4"
              },
              {
                  "x": "Mar",
                  "y": "5"
              },
              {
                  "x": "Abr",
                  "y": "1"
              },
              {
                  "x": "Mai",
                  "y": "1"
              },
              {
                  "x": "Jun",
                  "y": "0"
              },
              {
                  "x": "Jul",
                  "y": "0"
              },
              {
                  "x": "Ago",
                  "y": "2"
              },
              {
                  "x": "Set",
                  "y": "0"
              },
              {
                  "x": "Out",
                  "y": "0"
              },
              {
                  "x": "Nov",
                  "y": "3"
              }
          ]
      }
  ],
  "proceduresByTerritory": [
      {
          "id": "Ao-il",
          "label": "Ao-il",
          "value": "26",
          "count": "7"
      },
      {
          "id": "Fe-Po",
          "label": "Fe-Po",
          "value": "59",
          "count": "16"
      },
      {
          "id": "Po-Dis",
          "label": "Po-Dis",
          "value": "15",
          "count": "4"
      }
  ],
  "treatmentByTerritoriesAoil": [
      {
          "id": "Aterectomia",
          "label": "Aterectomia",
          "value": 20,
          "count": 1
      },
      {
          "id": "Balão",
          "label": "Balão",
          "value": 40,
          "count": 2
      },
      {
          "id": "Stent",
          "label": "Stent",
          "value": 20,
          "count": 1
      },
      {
          "id": "Stent Recoberto",
          "label": "Stent Recoberto",
          "value": 20,
          "count": 1
      }
  ],
  "treatmentByTerritoriesFePo": [
      {
          "id": "Aterectomia",
          "label": "Aterectomia",
          "value": 9,
          "count": 2
      },
      {
          "id": "Balão",
          "label": "Balão",
          "value": 68,
          "count": 15
      },
      {
          "id": "Stent",
          "label": "Stent",
          "value": 23,
          "count": 5
      },
      {
          "id": "Stent Recoberto",
          "label": "Stent Recoberto",
          "value": 4,
          "count": 2
      }
  ],
  "treatmentByTerritoriesPoDis": [
      {
          "id": "Aterectomia",
          "label": "Aterectomia",
          "value": 14,
          "count": 2
      },
      {
          "id": "Balão",
          "label": "Balão",
          "value": 71,
          "count": 10
      },
      {
          "id": "Stent",
          "label": "Stent",
          "value": 14,
          "count": 2
      },
      {
          "id": "Stent Recoberto",
          "label": "Stent Recoberto",
          "value": 10,
          "count": 5
      }
  ]
};
