import React from 'react';

import { Fade } from '@material-ui/core';

import { useAuth } from "context/auth";

import * as Styled from './style';

function LoadingOverlay() {
  const { isValidatingToken } = useAuth();

  return (
    <Fade in={isValidatingToken} timeout={{ enter: 0, exit: 750 }}>
      <Styled.Overlay>
        <Styled.Image
          src={require('logo-rebrave.png')}
          alt="Rebrave Logo"
        />
        <Styled.BlobWrapper>
          <Styled.Blob color='rgba(207, 0, 0, 1)' r={207} g={0} b={0} />
          <Styled.Blob color='rgba(236, 231, 13, 1)' r={236} g={231} b={13} />
          <Styled.Blob color='rgba(16, 97, 161, 1)' r={16} g={97} b={161} />
        </Styled.BlobWrapper>
      </Styled.Overlay>
    </Fade>
  );
};

export default LoadingOverlay;
