import { formatDateUs } from './index';

const isAfterToday = (value) => {
  const americanDate = formatDateUs(value);
  const date = new Date(americanDate);

  let maxDate = new Date();
  
  return date > maxDate
};

export default isAfterToday;
