import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Register } from "../pages/Register";
import { Login } from "../pages/Login";
import { Logout } from "../pages/Logout/Logout";
import { PrivateRoute } from "../components/PrivateRoute/PrivateRoute";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Drafts } from "../pages/Drafts/Drafts";
import { Registers } from "../pages/Registers/Registers";
import { NewRegister } from "../pages/NewRegister";
import { AdvancedSearch } from '../pages/AdvancedSearch';
import { MedicalCenterProfile } from '../pages/Reports/MedicalCenterProfile';
import { BiannualAnnual } from '../pages/Reports/BiannualAnnual';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={() => (
          <Redirect to="/dashboard"/>
        )}/>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/new-register/:type" component={NewRegister} />
        <PrivateRoute path="/register/:id?" component={Register} />
        <PrivateRoute path="/drafts" component={Drafts} />
        <PrivateRoute path="/registers" component={Registers} />
        <PrivateRoute path="/advanced-search" component={AdvancedSearch} />
        <PrivateRoute path="/reports/medical-center-profile" component={MedicalCenterProfile} />
        <PrivateRoute path="/reports/biannual-annual" component={BiannualAnnual} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
